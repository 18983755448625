import React from 'react';
import { tokenName } from '../../config';
import InterviewPublic from '../InterviewPublic';
import WatchlistReminders from './components/WatchlistReminders';
import Interview from './Interview';

import {
  MainWhite as MainWhiteLayout,
  MainSidebar as MainSidebarLayout,
} from '../../layouts';

const InterviewRoute = () => {
  const token = localStorage.getItem(tokenName) || '';

  if (token) {
    return (
      <MainSidebarLayout>
        <WatchlistReminders />
        <Interview />
      </MainSidebarLayout>
    );
  }

  return (
    <MainWhiteLayout>
      <InterviewPublic />
    </MainWhiteLayout>
  );
};
export default InterviewRoute;
