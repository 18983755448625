/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useMutation } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import useMe from '../../hooks/useMe';
import freeDomains from '../CreateAccount/freeDomains';
import useDebounce from '../../hooks/useDebounce';
import { ALPHASENSE_SIGNUP_MUTATION } from '../../queries/account';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 71px)',
    padding: '20px',
    [theme.breakpoints.up('md')]: {
      minHeight: '100vh',
    },
  },
}));

const Subscribe = ({ formId }) => {
  const classes = useStyles();
  const formEl = useRef(null);
  const [formLoaded, setFormLoaded] = useState(false);
  const { me } = useMe();
  const { hashid } = useParams();

  const location = window.location.pathname;
  const start = location.indexOf('U');
  const stop = location.indexOf('-');

  const userId = (start > -1) ? location.substring(start + 1, stop) : '';
  const interviewId = location.substring(location.indexOf('-') + 1);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = `https://mosaicrm.activehosted.com/f/embed.php?id=${formId}`;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      script.remove();
      // TODO handle correct remove AC form from DOM
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    formEl.current.addEventListener('DOMSubtreeModified', () => setFormLoaded(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const referredBy = document.getElementsByName('field[8]')[0];
    const referredByUserId = document.getElementsByName('field[12]')[0];
    const cleanInterviewId = document.getElementsByName('field[13]')[0];

    if (referredBy && hashid) {
      referredBy.value = hashid;
    }
    if (referredByUserId && userId) {
      referredByUserId.value = userId;
      if (cleanInterviewId && interviewId) {
        cleanInterviewId.value = interviewId;
      }
    }
    if (!isEmpty(me) && formLoaded) {
      // document.getElementsByName('fullname')[0].value = me.realFullName || '';
      document.getElementsByName('email')[0].value = me.email || '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, formLoaded, hashid]);

  const [formRendered, setFormRendered] = useState();
  const [firstRender, setFirstRender] = useState(true);
  const [isDomainInvalid, setIsDomainInvalid] = useState(false);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const debounceEmail = useDebounce(email, 300);

  const emailRef = useRef();
  const domainRef = useRef();
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const [signup] = useMutation(ALPHASENSE_SIGNUP_MUTATION);

  /**
   *
   * @param {object} options can include id, innerHTML, display and
   * color attributes for the created element.
   * @returns {HTMLDivElement} html div element
   */
  const createElement = (options) => {
    const element = document.createElement('div');
    const {
      id, innerHTML, display, color,
    } = options;

    element.id = id || '';
    element.innerHTML = innerHTML || '';
    element.style.display = display || '';
    element.style.color = color || '';

    return element;
  };

  /**
   *
   * @param {string} emailAddress email address
   * @returns {boolean} true if the emailAddress is a valid email address, false otherwise.
   */
  const isEmailValid = (emailAddress) => /.+@.+\..{2,}/i.test(emailAddress);

  /**
   *
   * @param {string} emailAddress email address
   * @returns {boolean} true if the email address belongs to a free domain, false otherwise.
   */
  const isFreeEmailOrEdu = (emailAddress) => {
    const domain = emailAddress.split('@')[1].toLowerCase();
    return (freeDomains.includes(domain) || (domain.endsWith('.edu') && domain !== '.edu'));
  };

  const handleSubmit = (e) => {
    const form = document.querySelector('form');
    // check if the form inputs all pass the validation set in place by ActiveCampaign
    if (!form.checkValidity()) {
      return false;
    }

    // check if the inserted email address is valid
    // this is needed because if a user inserts a valid email address
    // and then edited their input to an invalid email, the form.checkValidty
    // will still evaluate to true, and won't catch this edge case.
    if (!isEmailValid(emailRef.current)) {
      return false;
    }

    // domainRef is used to keep track of whether the email belongs to
    // a free domain or not, if domainRef is set to true, that means
    // that the domain is free, and hence we prevent the form submission.
    if (domainRef.current) {
      e.preventDefault();
      return;
    }

    e.preventDefault();
    signup({
      variables: {
        email: emailRef.current,
        firstName: firstNameRef.current,
        lastName: lastNameRef.current,
        title: '',
        phone: '',
      },
    });

    const id = form.id.substring('6', form.id.length - 1);
    window._show_thank_you(id, 'Check your email. We just sent you a Stream trial login. You now have full access to all transcripts. Enjoy!', null, emailRef.current);
  };

  const handleEnter = (e) => {
    if (e.key !== 'Enter') return;
    return handleSubmit(e);
  };

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://mosaicrm.activehosted.com/f/embed.php?id=1';
    script.async = true;

    document.body.appendChild(script);

    /**
     * listenes for the ActiveCampaing to render and then setup state sync
     * @returns {null}
     */
    const watchForm = () => {
      const formWrapper = document.querySelector('#activeCampaingForm');
      const observer = new MutationObserver(() => {
        if (formRendered) {
          return;
        }
        setFormRendered(!!formWrapper.querySelector('form'));
      });

      // if ActiveCampaing form is not rendered yet, the function will be recursively called
      if (!formWrapper) {
        setTimeout(watchForm, 500);
        return;
      }

      observer.observe(formWrapper, {
        childList: true,
      });
    };
    watchForm();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // wait until the form is rendered
    if (!formRendered || firstRender) {
      setFirstRender(false);
      return;
    }

    // add event listeners to inputs

    const formWrapper = document.querySelector('#activeCampaingForm form');

    const btn = formWrapper.querySelector('button');
    btn.addEventListener('click', handleSubmit);
    document.addEventListener('keydown', handleEnter);

    const emailElement = formWrapper.querySelector('#email');
    emailElement.addEventListener('input', (e) => setEmail(e.target.value));

    const firstNameElement = formWrapper.querySelector('#firstname');
    firstNameElement.addEventListener('input', (e) => setFirstName(e.target.value));

    const lastNameElement = formWrapper.querySelector('#lastname');
    lastNameElement.addEventListener('input', (e) => setLastName(e.target.value));

    const errElement = createElement({
      id: '_email-error',
      display: 'none',
    });

    const errInner = createElement({
      innerHTML: 'Please use your work email address to gain instant access to the transcript library. If you only have a personal email address, please contact <b>success@streamrg.com</b> for access.',
      color: '#B60000',
    });
    errElement.appendChild(errInner);

    emailElement.parentElement.appendChild(errElement);
    // eslint-disable-next-line
  }, [formRendered]);

  useEffect(() => {
    // each time the email/isAnalyst state change, we'll check if the email is free
    if (debounceEmail !== emailRef.current) {
      emailRef.current = debounceEmail;
    }

    if (!debounceEmail || !isEmailValid(debounceEmail)) {
      setIsDomainInvalid(false);
      return;
    }

    setIsDomainInvalid(isFreeEmailOrEdu(debounceEmail));
  }, [debounceEmail]);

  useEffect(() => {
    firstNameRef.current = firstName;
  }, [firstName]);

  useEffect(() => {
    lastNameRef.current = lastName;
  }, [lastName]);

  useEffect(() => {
    // this hook is used to determine whether to render the free domain error or not.
    const errElement = document.querySelector('#_email-error');
    const submitBtn = document.querySelector('._submit');

    if (!errElement) return;

    if (!isDomainInvalid) {
      domainRef.current = false;
      errElement.style.display = 'none';
      submitBtn.disabled = false;
      submitBtn.style.pointerEvents = 'all';
      submitBtn.style.opacity = 1;
      return;
    }
    domainRef.current = true;
    errElement.style.display = '';
    submitBtn.disabled = true;
    submitBtn.style.pointerEvents = 'none';
    submitBtn.style.opacity = 0.3;
  }, [isDomainInvalid]);

  return (
    <div className={classes.root}>
      <div ref={formEl} className={`_form_${formId}`} id="activeCampaingForm" />
    </div>
  );
};

Subscribe.defaultProps = {
  formId: '1',
};

Subscribe.propTypes = {
  formId: PropTypes.string,
};

export default withRouter(Subscribe);
