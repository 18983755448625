import { useState, useEffect } from 'react';
import isNaN from 'lodash/isNaN';
import add from 'date-fns/add';
import { HOURS_DELTA, SEEN_IN_SESSION, SEEN_WATCHLIST_MODAL } from '../constants/reminders';

const useReminder = () => {
  const [showPeriod, setShowPeriod] = useState(false);
  const [showSession, setShowSession] = useState(false);

  const now = Date.now();
  const nextTime = add(now, { hours: HOURS_DELTA }).getTime();

  const setAsShownPeriod = () => {
    localStorage.setItem(SEEN_WATCHLIST_MODAL, String(nextTime));
    setShowPeriod(true);
  };

  const setAsShownSession = () => {
    sessionStorage.setItem(SEEN_IN_SESSION, String(nextTime));
    setShowSession(true);
  };

  const clearSession = () => {
    sessionStorage.removeItem(SEEN_IN_SESSION);
  };

  useEffect(() => {
    let lastDisplayTime = parseInt(localStorage.getItem(SEEN_WATCHLIST_MODAL) || 0, 10);
    if (isNaN(lastDisplayTime)) {
      localStorage.removeItem(SEEN_WATCHLIST_MODAL);
      lastDisplayTime = 0;
    }
    setShowPeriod(lastDisplayTime < now);

    let sessionLastDisplayTime = parseInt(sessionStorage.getItem(SEEN_IN_SESSION) || 0, 10);
    if (isNaN(sessionLastDisplayTime)) {
      sessionStorage.removeItem(SEEN_IN_SESSION);
      sessionLastDisplayTime = 0;
    }
    setShowSession(sessionLastDisplayTime < now);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    showPeriod, showSession, setAsShownPeriod, setAsShownSession, clearSession,
  };
};

export default useReminder;
