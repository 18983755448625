import React from 'react';
import Box from '@material-ui/core/Box';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { SET_INTERVIEW_PERCENTS_READ_MUTATION } from '../../queries/interview/interview';
import useAllIntervies from '../../hooks/useAllIntervies';

const MarkInterviewUnread = ({ interviewPk, interviewId, ...style }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [setInterviewPercents] = useMutation(SET_INTERVIEW_PERCENTS_READ_MUTATION);
  const { updateQuery: updateAllInterviewsQuery } = useAllIntervies();

  const handleClick = () => {
    if (interviewPk) {
      setInterviewPercents({
        variables: {
          interviewPk,
          percentsRead: 0,
        },
        update: () => {
          updateAllInterviewsQuery((previousQueryResult) => {
            const results = previousQueryResult.allInterviews?.results.map((el) => {
              if (el.interviewId === interviewId) {
                return { ...el, percentsRead: 0 };
              }
              return el;
            });

            return {
              allInterviews: {
                ...previousQueryResult.allInterviews,
                results,
              },
            };
          });
        },
      }).then(({ data: { setInterviewPercentsRead = {} } = {} }) => {
        if (!isEmpty(setInterviewPercentsRead.errors)) {
          enqueueSnackbar(
            setInterviewPercentsRead.errors.map((err) => (
              `${err.field}: ${err.messages.join(', ')}`
            )),
            { variant: 'error', autoHideDuration: 4000 },
          );
        } else {
          enqueueSnackbar('Interview marked as unread.', { variant: 'success', autoHideDuration: 4000 });
        }
      });
    }
  };

  return (
    <Box
      {...style}
      onClick={handleClick}
    >
      Mark Interview Unread
    </Box>
  );
};

MarkInterviewUnread.propTypes = {
  interviewId: PropTypes.string.isRequired,
  interviewPk: PropTypes.string.isRequired,
};

export default MarkInterviewUnread;
