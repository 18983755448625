import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import { CollapseInfo } from '../../../../../components/InterviewPreviewComponents';

const userStyles = makeStyles((theme) => ({
  subtitle: {
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.text.primary,
    marginBottom: '8px',
  },
  mostProminent: {
    backgroundColor: '#F9FBFF',
    borderLeft: '2px solid',
    borderLeftColor: theme.palette.primary.main,
    marginLeft: 0,
    padding: '2px 0 4px 15px',
    '& ul': {
      listStyleType: 'none',
      '& li:before': {
        content: '"-"',
        marginLeft: '-20px',
        marginRight: '10px',
      },
    },
  },
}));

const CollapseBlock = ({
  title, content, mostProminent,
}) => {
  const classes = userStyles();

  return (
    <Box className={classes.subtitle}>
      <Box mb="6px">
        {title}
      </Box>
      <CollapseInfo className={mostProminent ? classes.mostProminent : ''}>
        {content || 'No Information'}
      </CollapseInfo>
    </Box>
  );
};

CollapseBlock.defaultProps = {
  title: '',
  content: '',
  mostProminent: false,
};

CollapseBlock.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  mostProminent: PropTypes.bool,
};

export default CollapseBlock;
