import React from 'react';
import PropTypes from 'prop-types';
import { IndustryItem } from '../../../../../components/InterviewPreviewComponents';

const Industry = ({ interview }) => {
  const primaryIndustry = interview.tickers[0].ticker.industry;
  if (!primaryIndustry) return null;

  return (
    <IndustryItem
      filterKey="industries"
      interviewId={interview.interviewId}
      itemId={primaryIndustry.id}
      itemTitle={primaryIndustry.title}
      itemUUID={primaryIndustry.uuid}
      filterMenuItemText="Filter by this Industry"
      isAddedToWatchList={primaryIndustry.isAddedToWatchList}
    />
  );
};

Industry.propTypes = {
  interview: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Industry;
