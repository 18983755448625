/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import isEmpty from 'lodash/isEmpty';
import { makeStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import clsx from 'clsx';
import { useMediaQuery } from '@material-ui/core';
import isEqual from 'lodash/isEqual';
import { useSelector, useDispatch } from 'react-redux';
import {
  ALL_COMPANIES_PARENTS_QUERY,
} from '../../../../../../../../queries/interview/interview';
import { CompanyItem } from './components';
import CompanyOrdering from './components/CompanyOrdering';
import ItemsSkeleton from '../ItemsSkeleton';
import { hideSideBar } from '../../../../../../../../actions/sidebarActions';
import { writeFilterData } from '../../../../../../../../actions/filterActions';
import { ALL_FOLLOWING_COMPANIES_QUERY, ADD_COMPANY_TO_FOLLOWING_MUTATION, DELETE_COMPANY_FOLLOWING } from '../../../../../../../../queries/interview/following';

const ORDERING_OPTIONS = [
  { value: '-last_call_date', label: 'Recent' },
  { value: 'title', label: 'A-Z' },
];
const PAGINATION_FIRST = 30;
const PAGINATION_SKIP = 0;

const useStyles = makeStyles((theme) => ({
  companiesContainer: {
    height: '100%',
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '25px',
  },
  headingPosition: {
    position: 'relative',
  },
  noResults: {
    color: theme.palette.text.secondary,
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '22px',
  },
}));

const CompaniesList = () => {
  const classes = useStyles();

  const filterData = useSelector((state) => state.filterState.filterData, isEqual);
  const dispatch = useDispatch();

  const [order, setOrder] = useState(ORDERING_OPTIONS[0]);
  // eslint-disable-next-line no-unused-vars
  // const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [search, setSearch] = useState('');

  const { search: globalSearch } = filterData;

  const initialQueryVariables = {
    first: PAGINATION_FIRST,
    skip: PAGINATION_SKIP,
    order: order.value,
    search: globalSearch || search,
  };

  const [addToFollowing] = useMutation(ADD_COMPANY_TO_FOLLOWING_MUTATION, {
    refetchQueries: [{ query: ALL_FOLLOWING_COMPANIES_QUERY }],
    update: (cache, { data: { addCompanyToFollowing: { companyWatch } } }) => {
      const { allCompanies } = cache.readQuery({
        query: ALL_COMPANIES_PARENTS_QUERY,
        variables: initialQueryVariables,
      });
      const results = allCompanies.results.map((el) => {
        if (el.id === companyWatch.company.id) {
          return { ...el, watchlistId: parseInt(companyWatch.id, 10) };
        }
        return el;
      });
      cache.writeQuery({
        query: ALL_COMPANIES_PARENTS_QUERY,
        data: { allCompanies: { ...allCompanies, results } },
      });
    },
  });

  const [deleteCompany] = useMutation(DELETE_COMPANY_FOLLOWING, {
    refetchQueries: [{ query: ALL_FOLLOWING_COMPANIES_QUERY }],
    update: (cache, { data: { deleteCompanyFollowing: { companyWatch } } }) => {
      const { allCompanies } = cache.readQuery({
        query: ALL_COMPANIES_PARENTS_QUERY,
        variables: initialQueryVariables,
      });

      const results = allCompanies.results.map((el) => {
        if (el.id === companyWatch.company.id) {
          return { ...el, watchlistId: null };
        }
        return el;
      });
      cache.writeQuery({
        query: ALL_COMPANIES_PARENTS_QUERY,
        data: { allCompanies: { ...allCompanies, results } },
      });
    },
  });

  const [
    getAllCopmanies,
    {
      data: {
        allCompanies: {
          totalCount = 0,
          results = [],
        } = {},
      } = {},
      // networkStatus,
      fetchMore,
      loading,
      called,
    }] = useLazyQuery(ALL_COMPANIES_PARENTS_QUERY, {
    // skip: true,
    variables: initialQueryVariables,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (!loading) { getAllCopmanies(); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isCompanyActive = (tickers) => {
    const ids = tickers.map((el) => el.id);
    const active = filterData.tickers.map((el) => el.id);

    const res = ids.filter((el) => {
      if (active.includes(el)) {
        return true;
      }
      return false;
    });

    if (res.length > 0) {
      return true;
    }
    return false;
  };

  // useEffect(() => {
  //   if (networkStatus === 1 || networkStatus === 2) {
  //     setLoading(true);
  //   } else {
  //     setLoading(false);
  //   }
  // }, [networkStatus]);

  useEffect(() => {
    if (!loading && called) {
      getAllCopmanies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, search]);

  useEffect(() => {
    if (search) {
      setSearch('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalSearch]);

  const { enqueueSnackbar } = useSnackbar();

  const handleRemoveFromWatchlist = (followingId) => () => {
    deleteCompany({ variables: { followingId } });
    enqueueSnackbar(
      'Removed from Watchlist.',
      { variant: 'success', autoHideDuration: 4000 },
    );
  };

  const handleAddToWatchlist = (id) => () => {
    addToFollowing({
      variables: { companyId: id },
    }).then(({ data: { addCompanyToFollowingMutation = {} } = {} }) => {
      if (!isEmpty(addCompanyToFollowingMutation.errors)) {
        enqueueSnackbar(
          addCompanyToFollowingMutation.errors.map((err) => (
            `${err.field}: ${err.messages.join(', ')}`
          )),
          { variant: 'error', autoHideDuration: 4000 },
        );
      } else {
        enqueueSnackbar(
          'Added to Watchlist.',
          { variant: 'success', autoHideDuration: 4000 },
        );
      }
    });
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: false,
  });

  const handleFilterSelecion = (values) => {
    const extraData = {};
    dispatch(writeFilterData({ ...values, ...extraData }));
    if (isMobile) {
      dispatch(hideSideBar());
    }
  };

  const loadMore = () => {
    fetchMore({
      variables: {
        skip: results.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        if (!prev) {
          return {
            allCompanies: fetchMoreResult.allCompanies,
          };
        }
        return {
          allCompanies: {
            ...prev.allCompanies,
            results: [...prev.allCompanies.results, ...fetchMoreResult.allCompanies.results],
          },
        };
      },
    });
  };

  useEffect(() => {
    if (!results) {
      setHasMore(false);
    } else if (results.length < totalCount) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  }, [results, totalCount]);

  const handleClick = (company) => () => {
    handleFilterSelecion({
      tickers: company?.tickers,
    });
  };

  const companiesTitle = () => {
    let title = 'Companies';
    if (globalSearch && !loading) {
      if (results?.length === 0) {
        title = 'No company results';
      } else if (results?.length === 1) {
        title = '1 Company found';
      } else {
        title = `${results?.length} Companies found`;
      }
    }
    return title;
  };

  return (
    <Box>
      <Box>
        <Box
          display="flex"
          flexDirection="column"
          className={classes.headingPosition}
        >
          <Box className={classes.title} mb="10px">
            {companiesTitle()}
          </Box>
          {(results?.length > 3) && (
            <Box mb="10px">
              <CompanyOrdering
                options={ORDERING_OPTIONS}
                currentOrder={order}
                handleChange={setOrder}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box className={clsx(classes.companiesContainer, 'xlink-company-list')}>
        {(loading && totalCount === 0)
          ? <ItemsSkeleton />
          : (
            <Box>
              <InfiniteScroll
                pageStart={0}
                dataLength={results && results.length}
                next={loadMore}
                hasMore={hasMore}
                loader={<ItemsSkeleton />}
                scrollableTarget="sidebar-content"
              >
                {results && results.map((company) => (
                  <CompanyItem
                    key={company.id}
                    id={company.id}
                    uuid={company.uuid}
                    lastCallDate={company.lastCallDate}
                    isItemActive={isCompanyActive(company.tickers)}
                    watchlistId={company.watchlistId}
                    description={company.title}
                    handleCompanyClick={handleClick(company)}
                    handleAddToWatchlist={handleAddToWatchlist(company.id)}
                    handleRemoveFromWatchlist={handleRemoveFromWatchlist(company.watchlistId)}
                  />
                ))}
                {globalSearch && results?.length === 0
                && (
                  <Box className={classes.noResults}>
                    Sorry, we can&#39;t find any companies called &#39;
                    {globalSearch}
                    &#39;.
                  </Box>
                )}
              </InfiniteScroll>
            </Box>
          )}
      </Box>
    </Box>
  );
};

export default CompaniesList;
