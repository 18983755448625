import { gql } from '@apollo/client';

export const VARIFY_TOKEN_MUTATION = gql`
mutation varifyToken($token: String) {
  verifyToken(token: $token) {
    payload
  }
}
`;

export const ME_QUERY = gql`
{
  me {
    id
    uuid
    isPaid
    isBonus
    isStaff
    isSuperuser
    isMigrated
    isTrial
    isInternal
    showWatchlistModal
    slug
    keepAnonymous
    nickname
    email
    isConsentToReceiveInfo
    realShortName
    realFullName
    firstName
    lastName
    userTimezone
    avatar
    role
    roleDisplay
    hasPaidAccess
    createdAt
    trialStartDate
    trialExpireDate
    upgradeRequestedDate
    becomePaid
    watchlistCount
    sectorWatchlistCount
    companyWatchlistCount
    industryWatchlistCount
    keywordWatchlistCount
    sectorWatchlistUnreadCount
    companyWatchlistUnreadCount
    keywordWatchlistUnreadCount
    industryWatchlistUnreadCount
    numOfBookmardedInterviews
    accountMembersCount
    accountComplianceApprovalRequired
    cachedFilters
    compliance {
      id
      fullName
    }
    settings{
      id
      emailAlerts
      playerSpeed
      playerSpeedOptions{
        name
        description
      }
      notifyPeriod
      notifyPeriodsOptions{
        name
        description
      }
      onlyPrimaryTickerAlerts
    }
    timezoneOptions {
      name
      description
    }
  }
}
`;

export const LOGIN_MUTATION = gql`
mutation tokenAuth($email: String!, $password: String!){
    tokenAuth(email: $email, password: $password){
        payload,
        token,
        refreshExpiresIn
    }
}
`;

export const RESET_PASSWORD_MUTATION = gql`
mutation resetPassword($email: String!){
  resetPassword(input: {email: $email}){
    email
    errors{
      field
      messages
    }
  }
}
`;

export const VALIDATE_TOKEN_MUTATION = gql`
mutation validateToken($token: String!, $uidb: String!){
  validateToken(token: $token, uidb: $uidb){
    valid
  }
}
`;

export const VALIDATE_FIRST_LOGIN_HASH_MUTATION = gql`
mutation ValidateFirstLoginHash($hashStr: String!){
  validateFirstLoginHash(hashStr: $hashStr){
    valid
    user {
      firstName
      lastName
      email
      referredInterview
    }
  }
}
`;

export const SET_PASSWORD_MUTATION = gql`
mutation setPassword($token: String!, $uidb: String!, $new_password1: String!, $new_password2: String!) {
  setPassword(token: $token, uidb: $uidb, newPassword1: $new_password1, newPassword2: $new_password2) {
    errors{
      field
      messages
    }
    user {
      email
    }
  }
}
`;

export const SET_FIRST_LOGIN_PASSWORD = gql`
mutation setFirstLoginPassword($hashStr: String!, $password1: String!, $password2: String!, $receiveInfo: Boolean, $termsAndConditions: Boolean!) {
  setFirstLoginPassword(hashStr: $hashStr, password1: $password1, password2: $password2, receiveInfo: $receiveInfo, termsAndConditions: $termsAndConditions) {
    token
    refreshExpiresIn
    errors {
      field
      messages
    }
  }
}
`;

export const UPDATE_USER_SETTINGS_MUTATION = gql`
mutation updateUserSettings($emailAlerts: Boolean, $notifyPeriod: String, $playerSpeed: Float, $onlyPrimaryTickerAlerts: Boolean, $settingsId: Int!){
  updateUserSettings(emailAlerts: $emailAlerts, notifyPeriod: $notifyPeriod, playerSpeed: $playerSpeed, onlyPrimaryTickerAlerts: $onlyPrimaryTickerAlerts, settingsId: $settingsId){
    success
  }
}
`;

export const UPDATE_USER_PROFILE_MUTATION = gql`
mutation ($firstName: String, $lastName: String, $avatar: Upload, $keepAnonymous: Boolean, $email: String, $userTimezone: String!, $oldPassword: String, $newPassword: String, $isConsentToReceiveInfo: Boolean) {
  updateUserProfile(firstName: $firstName, lastName: $lastName, avatar: $avatar, keepAnonymous: $keepAnonymous, email: $email, userTimezone: $userTimezone, oldPassword: $oldPassword, newPassword: $newPassword, isConsentToReceiveInfo: $isConsentToReceiveInfo) {
    errors {
      field
      messages
    }
    token
    refreshExpiresIn
    user {
      id
    }
  }
}
`;

export const LOGOUT_MUTATION = gql`
  mutation {
    revokeToken {
      success
    }
  }
`;

export const ALPHASENSE_SIGNUP_MUTATION = gql`
mutation ($email: String!, $firstName: String!, $lastName: String!, $phone: String, $title: String) {
  alphasenseSignup(email: $email, firstName: $firstName, lastName: $lastName, phone: $phone, title: $title) {
    success
  }
}
`;
