import { useEffect, useReducer } from 'react';
import { useLazyQuery } from '@apollo/client';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { singletonHook } from 'react-singleton-hook';
import { ME_QUERY } from '../queries/account';

const INITIAL_VALS = {
  loading: false,
  me: {},
  refetch: () => {},
  called: false,
  updateQuery: () => {},
};

const useMe = () => {
  const [meState, dispatch] = useReducer(
    (state, newState) => (
      { ...state, ...newState }
    ),
    INITIAL_VALS,
  );

  const [getMe, {
    loading,
    data: { me = {} } = {},
    refetch,
    called,
    updateQuery,
  }] = useLazyQuery(ME_QUERY);

  useEffect(() => {
    if (!loading) {
      getMe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let newState = {};
    if (!isEqual(me, meState.me) && !isEmpty(me)) {
      newState = { ...newState, me };
    }

    if (isEmpty(meState.me) && (!isEmpty(me) || me === null)) {
      newState = { ...newState, refetch, updateQuery };
    }

    if (loading !== meState.loading) {
      newState = { ...newState, loading };
    }

    if (called !== meState.called) {
      newState = { ...newState, called };
    }

    if (!isEmpty(newState)) {
      dispatch({ ...meState, ...newState });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, loading, called]);

  return meState;
};

export default singletonHook(INITIAL_VALS, useMe);
