import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { showSideBar } from '../../../../actions/sidebarActions';
import { ReactComponent as BurgerIcon } from '../../../../assets/icons/burgerIcon.svg';

const useStyles = makeStyles((theme) => ({
  burger: {
    height: '40px',
    padding: '10px',
    minWidth: '40px',
    backgroundColor: theme.palette.common.white,
    marginRight: '10px',
  },
}));

const BurgerButton = ({ handleShowSideBar }) => {
  const classes = useStyles();

  return (
    <Button
      color="inherit"
      onClick={handleShowSideBar}
      disableRipple
      className={classes.burger}
    >
      <BurgerIcon />
    </Button>
  );
};

BurgerButton.propTypes = {
  handleShowSideBar: PropTypes.func.isRequired,
};

export default connect(
  null,
  {
    handleShowSideBar: showSideBar,
  },
)(BurgerButton);
