import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import { createMatchSelector } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';
import BurgerButton from './BurgerButton';
import { tokenName } from '../../../../config';
import { store } from '../../../../store';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '71px',
    width: '100vw',
    padding: '0 20px',
    boxShadow: 'none',
    left: '0',
    flexDirection: 'row',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.info.main,
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.up('md')]: {
      padding: '0 50px',
    },
    '&.showTrialBanner': {
      top: '37px',
    },
  },
  logo: {
    display: 'flex',
    margin: '10px 15px 10px 0',
  },
  navItem: {
    margin: '0 15px',
    padding: '26px 0',
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '&.active': {
      padding: '26px 0 24px',
      borderBottom: '2px solid',
      borderBottomColor: theme.palette.primary.main,
      color: theme.palette.text.primary,
      fontWeight: 'bold',
    },
  },
  login: {
    height: '40px',
    padding: '10px 20px',
    color: theme.palette.text.secondary,
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    border: '1px solid',
    borderColor: theme.palette.info.main,
    borderRadius: '5px',
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.text.primary,
      borderColor: theme.palette.info.dark,
    },
    '&:active': {
      color: theme.palette.text.primary,
      borderColor: theme.palette.info.main,
    },
  },
  signup: {
    height: '40px',
    padding: '10px 20px',
    color: theme.palette.common.white,
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '5px',
    backgroundColor: theme.palette.primary.main,
    marginLeft: '10px',
    '&:hover': {
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.dark,
    },
    '&:active': {
      borderColor: theme.palette.primary.light,
      backgroundColor: theme.palette.primary.light,
    },
  },
  searchBtn: {
    '&:active svg path': {
      stroke: theme.palette.text.secondary,
    },
  },
  menuBtn: {
    padding: '6px 4px',
    marginLeft: '15px',
    '&:active svg path': {
      stroke: theme.palette.text.secondary,
    },
  },
  mobMenu: {
    '& .MuiDrawer-paper': {
      minWidth: '280px',
      padding: '20px',
    },
  },
  mobNavItem: {
    margin: '10px 0',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '20px',
    color: theme.palette.text.primary,
    '&.active': {
      color: theme.palette.primary.main,
    },
  },
}));

const Topbar = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const token = localStorage.getItem(tokenName);
  const { pathname } = window.location;

  const isFirstLoginPage = () => {
    const state = store.getState();
    const matchSelectorFirstLogin = createMatchSelector({ path: '/accounts/first-login/:hashStr' });
    const matchFirstLogin = matchSelectorFirstLogin(state);

    return matchFirstLogin?.isExact;
  };

  const matchSignUp = useRouteMatch({ path: '/accounts/create-account/', exact: true });
  const isSignUp = !isEmpty(matchSignUp);

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
      >
        <Box
          display="flex"
          alignItems="center"
        >
          {token && <BurgerButton />}
          <Link to="/" className={classes.logo}>
            <img
              alt="Logo"
              src="https://stream-compliance.s3.amazonaws.com/static/as-stream-logo.dib"
              width="118px"
              height="50px"
            />
          </Link>
        </Box>
        {!token && !isFirstLoginPage()
        && (
          <>
            <Hidden smDown>
              <Box
                display="flex"
                alignItems="center"
              >
                <Link
                  to={`/accounts/login/?next=${pathname}`}
                  className={classes.login}
                >
                  Login
                </Link>
                {!isSignUp
                && (
                  <a
                    href="https://stream.alpha-sense.com/enterprise-signup"
                    className={classes.signup}
                  >
                    Sign Up
                  </a>
                )}
              </Box>
            </Hidden>
            <Hidden mdUp>
              <Box
                display="flex"
                alignItems="center"
              >
                <Link
                  to="/accounts/login"
                  className={classes.login}
                >
                  Login
                </Link>
              </Box>
            </Hidden>
          </>
        )}
      </Box>
    </AppBar>
  );
};

Topbar.defaultProps = {
  className: '',
};

Topbar.propTypes = {
  className: PropTypes.string,
};

export default Topbar;
