import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { ReactComponent as UploadImage } from '../../../../../../../../../../assets/icons/uploadIcon.svg';
import { ReactComponent as WarningIcon } from '../../../../../../../../../../assets/icons/warningIcon.svg';
import { ReactComponent as DoneSuccessIcon } from '../../../../../../../../../../assets/icons/doneSuccessIcon.svg';
import {
  ALL_FOLLOWING_COMPANIES_QUERY,
  UPLOAD_WATCHLIST_MUTATION,
  WATCHLIST_IMPORT_STATUS_QUERY,
} from '../../../../../../../../../../queries/interview/following';

const useStyles = makeStyles((theme) => ({
  uploadIconBtn: {
    marginRight: '12px',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    '&:hover svg path': {
      stroke: theme.palette.primary.main,
    },
  },
  closeDialog: {
    color: theme.palette.text.secondary,
    padding: 0,
    '&:hover': {
      color: theme.palette.text.primary,
      backgroundColor: 'transparent',
    },
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    padding: '24px 24px 16px',
  },
  dialogContent: {
    padding: '0 24px 0',
  },
  dialogMessageTitle: {
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '24px',
  },
  dialogMessageText: {
    width: '100%',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '20px',
    marginBottom: '24px',
  },
  bodyMsg: {
    display: 'flex',
    alignContent: 'center',
    marginBottom: '10px',
  },
  textMsg: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '20px',
    color: theme.palette.text.primary,
    marginLeft: '8px',
  },
  divider: {
    width: '100%',
    margin: '14px 0 24px',
  },
  dialogTextarea: {
    width: '100%',
    minHeight: '200px',
    borderRadius: '5px',
    padding: '12px',
    backgroundColor: theme.palette.background.paper,
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    borderColor: theme.palette.info.main,
    outline: 'none',
    '&:hover': {
      borderColor: theme.palette.info.dark,
    },
  },
  dialogActions: {
    padding: '24px',
  },
  confirmBtn: {
    width: '100%',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    padding: '10px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '5px',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.common.white,
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.dark,
    },
    '&:active': {
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.dark,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.info.light,
      borderColor: theme.palette.info.light,
      color: theme.palette.info.dark,
    },
  },
  closeDialogBtn: {
    width: '100%',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    padding: '10px',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.common.white,
    border: '1px solid',
    borderColor: theme.palette.info.main,
    borderRadius: '5px',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.text.primary,
      borderColor: theme.palette.info.dark,
      backgroundColor: theme.palette.common.white,
    },
    '&:active': {
      borderColor: theme.palette.info.main,
      backgroundColor: theme.palette.common.white,
    },
  },
  progressWrapper: {
    position: 'relative',
    width: '100%',
    marginLeft: '18px !important',
  },
  buttonProgress: {
    color: theme.palette.info.dark,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const UploadWatchlistTool = ({
  open, setOpen,
}) => {
  const classes = useStyles();
  const [watchlistUploadValue, setWatchlistUploadValue] = useState('');
  const [maxTickersInputIsValid, setMaxTickersInputIsValid] = useState(false);
  const [taskId, setTaskId] = useState('');
  const [sentTickers, setSentTickers] = useState([]);
  const [formShouldBeEmpty, setFormShouldBeEmpty] = useState(false);
  const {
    data: { watchlistImportStatus = {} } = {},
    stopPolling,
    client,
  } = useQuery(WATCHLIST_IMPORT_STATUS_QUERY, { variables: { taskId }, pollInterval: 2000, skip: taskId === '' });

  const [uploadWatchlist] = useMutation(UPLOAD_WATCHLIST_MUTATION);

  useEffect(() => {
    const len = watchlistUploadValue.split(/[\s,\n]+/).length;
    setMaxTickersInputIsValid(len <= 50);
  }, [watchlistUploadValue]);

  const handleUploadWatchlist = () => {
    uploadWatchlist({ variables: { tickers: watchlistUploadValue } })
      .then(({ data: { uploadWatchlistForUser = {} } = {} }) => {
        if (isEmpty(uploadWatchlistForUser.errors)) {
          setTaskId(uploadWatchlistForUser.taskId);
          setSentTickers(uploadWatchlistForUser.inputTickers);
          setFormShouldBeEmpty(false);
        } else {
          // eslint-disable-next-line react-hooks/exhaustive-deps,no-console
          console.log(uploadWatchlistForUser.errors);
        }
      });
  };

  useEffect(() => {
    if (watchlistImportStatus?.status === 'FAILURE') {
      stopPolling();
    }
    if (watchlistImportStatus?.status === 'SUCCESS') {
      stopPolling();
      setWatchlistUploadValue(watchlistImportStatus?.result.join('\n'));
      client.refetchQueries({
        include: [ALL_FOLLOWING_COMPANIES_QUERY],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchlistImportStatus]);

  const getTotalAddedTickers = () => sentTickers.length - watchlistImportStatus.result.length;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setWatchlistUploadValue('');
    setFormShouldBeEmpty(true);
  };

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="upload_watchlist"
        size="small"
        disableRipple
        className={classes.uploadIconBtn}
        onClick={handleOpen}
      >
        <UploadImage />
      </IconButton>
      <Dialog onClose={handleClose} open={open}>
        <MuiDialogTitle disableTypography className={classes.dialogTitle}>
          <Box className={classes.dialogMessageTitle}>
            Upload Your Watchlist
          </Box>
          <IconButton
            aria-label="close"
            className={classes.closeDialog}
            onClick={handleClose}
            disableRipple
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <MuiDialogContent className={classes.dialogContent}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box width="100%">
              {watchlistImportStatus?.status === 'SUCCESS' && getTotalAddedTickers() > 0 && !formShouldBeEmpty
                && (
                  <Box className={classes.bodyMsg}>
                    <DoneSuccessIcon />
                    <Box className={classes.textMsg}>
                      {getTotalAddedTickers()}
                      &nbsp;successfully added
                    </Box>
                  </Box>
                )}
              {watchlistImportStatus?.status === 'SUCCESS' && watchlistImportStatus?.result?.length > 0 && !formShouldBeEmpty
                && (
                  <Box className={classes.bodyMsg}>
                    <WarningIcon />
                    <Box className={classes.textMsg}>
                      {watchlistImportStatus?.result?.length}
                      &nbsp;not found in our system
                    </Box>
                  </Box>
                )}
              {!maxTickersInputIsValid
                && (
                  <Box className={classes.bodyMsg}>
                    <WarningIcon />
                    <Box className={classes.textMsg}>
                      Only 50 tickers can be added at once.
                      Please limit your list to 50 tickers and try again.
                    </Box>
                  </Box>
                )}
            </Box>
            {watchlistImportStatus?.status === 'SUCCESS' && watchlistImportStatus?.result?.length > 0 && !formShouldBeEmpty
              ? (
                <>
                  <Divider className={classes.divider} />
                  <Box className={classes.dialogMessageText}>
                    The following tickers were not uploaded.
                    Double check them for typos and errors.
                    Start each ticker on a new line or separate tickers with a comma.
                  </Box>
                </>
              )
              : (
                <Box className={classes.dialogMessageText}>
                  Enter the tickers you want to add to your watchlist below.
                  Start each ticker on a new line, or separate tickers with a comma.
                  You may only upload 50 or fewer tickers at a time.
                </Box>
              )}
            <textarea
              value={watchlistUploadValue}
              onChange={(e) => { setWatchlistUploadValue(e.target.value); }}
              className={classes.dialogTextarea}
            />
          </Box>
        </MuiDialogContent>
        <MuiDialogActions className={classes.dialogActions}>
          <Button
            onClick={handleClose}
            disableRipple
            className={classes.closeDialogBtn}
          >
            Close
          </Button>
          <Box className={classes.progressWrapper}>
            <Button
              onClick={handleUploadWatchlist}
              disableRipple
              className={classes.confirmBtn}
              disabled={
                watchlistImportStatus?.status === 'PENDING'
                || watchlistImportStatus?.status === 'STARTED'
                || watchlistUploadValue === ''
                || !maxTickersInputIsValid
                || (watchlistImportStatus?.result?.join() === watchlistUploadValue.split(/[\s,\n]+/).join())
}
            >
              {watchlistImportStatus?.status === 'SUCCESS' && watchlistImportStatus?.result?.length > 0 && !formShouldBeEmpty ? 'Retry' : 'Upload'}
            </Button>
            {(watchlistImportStatus?.status === 'PENDING' || watchlistImportStatus?.status === 'STARTED') && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Box>
        </MuiDialogActions>
      </Dialog>
    </>
  );
};

UploadWatchlistTool.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default UploadWatchlistTool;
