/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/styles';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import WatchlistCompanySelect from '../WatchlistCompanySelect/WatchlistCompanySelect';
import SelectedTicker from './SelectedTicker';
import {
  ADD_COMPANY_TO_FOLLOWING_MUTATION_BULK,
  ALL_FOLLOWING_COMPANIES_QUERY,
  STOP_ASKING_FOR_WATCHLIST,
} from '../../queries/interview/following';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: '15px',
    [theme.breakpoints.up('md')]: {
      padding: '24px',
    },
  },
  dialogMessageTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: '32px',
    textAlign: 'center',
    margin: '4px 0 8px',
  },
  dialogMessage: {
    width: '100%',
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'center',
    marginBottom: '24px',
    [theme.breakpoints.up('md')]: {
      maxWidth: '85%',
    },
  },
  dialogMessageSubtitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '20px',
    marginBottom: 16,
  },
  checkbox: {
    padding: '0 12px 0 2px',
    color: theme.palette.info.main,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.paper,
    },
    '&.Mui-checked': {
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
  labelLink: {
    fontWeight: '500',
    color: theme.palette.text.primary,
  },
  checkboxLabel: {
    marginLeft: 0,
  },
  confirmBtn: {
    width: '100%',
    height: '40px',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '5px',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.common.white,
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.dark,
    },
    '&:active': {
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.dark,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.info.light,
      borderColor: theme.palette.info.light,
      color: theme.palette.info.dark,
    },
  },
  closeDialogBtn: {
    width: '100%',
    height: '40px',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.common.white,
    border: '1px solid',
    borderColor: theme.palette.info.main,
    borderRadius: '5px',
    textTransform: 'capitalize',
    marginRight: 4,
    '&:hover': {
      color: theme.palette.text.primary,
      borderColor: theme.palette.info.dark,
      backgroundColor: theme.palette.common.white,
    },
    '&:active': {
      borderColor: theme.palette.info.main,
      backgroundColor: theme.palette.common.white,
    },
  },
  tickersBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  loadProgress: {
    color: theme.palette.info.dark,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgress: {
    color: theme.palette.info.dark,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ForceEnterWatchlist = ({ open, handleClose }) => {
  const classes = useStyles();
  const [tickers, setTickers] = useState([]);
  const [addToFollowing, { loading }] = useMutation(ADD_COMPANY_TO_FOLLOWING_MUTATION_BULK, {
    errorPolicy: 'all',
    refetchQueries: [
      { query: ALL_FOLLOWING_COMPANIES_QUERY },
    ],
  });
  const [stopAskingForWatchlist] = useMutation(STOP_ASKING_FOR_WATCHLIST);
  const { enqueueSnackbar } = useSnackbar();
  const [hideInFuture, setHideInFuture] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
  } = useForm({
    resolver: undefined,
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });

  const handleDialogClose = () => {
    reset();
    handleClose();
    if (hideInFuture) {
      stopAskingForWatchlist();
    }
  };

  const onSubmit = () => {
    addToFollowing({
      variables: {
        tickerTitles: tickers.map((ticker) => ticker.ticker),
      },
    }).then(() => {
      enqueueSnackbar('Added to Watchlist', {
        variant: 'success',
        autoHideDuration: 4000,
      });
      handleClose();
    });

    if (hideInFuture) {
      stopAskingForWatchlist();
    }
  };

  const handleAddTicker = (ticker) => setTickers((tickersObj) => [...tickersObj, ticker]);
  const handlePopTicker = (ticker) => setTickers(
    (tickersObj) => tickersObj.filter((t) => t !== ticker),
  );

  return (
    <Dialog onClose={handleDialogClose} open={open}>
      <MuiDialogContent className={classes.dialogContent}>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Box className={classes.dialogMessageTitle}>
            Create your Watchlist!
          </Box>
          <Box className={classes.dialogMessage}>
            Optimize your Stream experience by adding the companies you follow.
          </Box>
          <Box width="100%">
            <WatchlistCompanySelect handleAddTicker={handleAddTicker} />
            {tickers.length > 0 && (
              <>
                <Box className={classes.dialogMessageSubtitle}>Watchlist</Box>
                <Box display="flex" flexDirection="column" mb="20px">
                  {tickers.map((ticker, idx) => (
                    <SelectedTicker
                      key={idx}
                      ticker={ticker}
                      handlePopTicker={handlePopTicker}
                    />
                  ))}
                </Box>
              </>
            )}
          </Box>
          <Box width="100%">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box display="flex" alignItems="center">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={(
                        <Checkbox
                          {...field}
                          innerRef={field.ref}
                          checked={hideInFuture}
                          disableRipple
                          className={classes.checkbox}
                          onChange={() => setHideInFuture(!hideInFuture)}
                        />
                        )}
                      label={
                        <Box className={classes.labelLink} component="span">Don’t remind me about this again</Box>
                        }
                      className={classes.checkboxLabel}
                    />
                  )}
                  name="doNotRemindMe"
                />
              </Box>
              <Box display="flex" justifyContent="center" mt="24px">
                <Button
                  onClick={handleDialogClose}
                  disableRipple
                  className={classes.closeDialogBtn}
                >
                  Close
                </Button>
                <Box width="100%" ml="4px">
                  <Button
                    type="submit"
                    disableRipple
                    className={classes.confirmBtn}
                    disabled={!tickers.length || loading}
                    startIcon={
                        loading && <CircularProgress size={24} className={classes.buttonProgress} />
                      }
                  >
                    {loading ? 'Adding' : 'Add'}
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </MuiDialogContent>
    </Dialog>
  );
};

ForceEnterWatchlist.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ForceEnterWatchlist;
