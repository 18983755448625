import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  tickerTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
  },
  selectedTicker: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '2px 4px 2px 9px',
    border: '1px solid',
    borderColor: theme.palette.info.main,
    borderRadius: '5px',
    position: 'relative',
    marginBottom: 8,
    height: 30,
  },
  removeSelected: {
    padding: 0,
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.primary,
      backgroundColor: 'transparent',
    },
  },
}));

const SelectedTicker = ({ ticker, handlePopTicker }) => {
  const classes = useStyles();
  return (
    <Box className={classes.selectedTicker}>
      <Box className={classes.tickerTitle}>{ticker.company}</Box>
      <IconButton
        aria-label="remove"
        className={classes.removeSelected}
        onClick={() => handlePopTicker(ticker)}
        disableRipple
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

SelectedTicker.propTypes = {
  ticker: PropTypes.string.isRequired,
  handlePopTicker: PropTypes.func.isRequired,
};

export default SelectedTicker;
