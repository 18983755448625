import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Slide from '@material-ui/core/Slide';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import isEqual from 'lodash/isEqual';
import { PaginationLink } from '../../components';
import Ordering from './components/Ordering/Ordering';
import { InterviewItem, SearchInput, SelectedFilters } from './components';
import InterviewPreview from './components/InterviewPreview';
import InterviewsNotFound from './components/InterviewsNotFound/InterviewsNotFound';
import InterviewItemSkeleton from './components/InterviewItem/InterviewItemSkeleton';
import PrimaryTickerSearchResultsSection from './components/PrimaryTickerSearchResultsSection';
import { Footer } from '../../layouts/Main/components';
import { searchToObject } from '../../helpers/locationSearchToJson';
import { INTERVIEW_PAGINATION_FIRST } from '../../constants/pagination';
import BulkActions from './components/BulkActions/BulkActions';
import SelectAllBtn from './components/SelectAllBtn/SelectAllBtn';
import { ReactComponent as CheckboxIcon } from '../../assets/icons/checkboxIcon.svg';
import { ReactComponent as DotsIcon } from '../../assets/icons/dotsIcon.svg';

const useStyles = makeStyles((theme) => ({
  scrollContainer: {
    height: '100%',
    overflowY: 'scroll',
  },
  content: {
    padding: '10px 20px',
    minHeight: 'calc(100vh - 149px)',
  },
  appBar: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.text.secondary,
    boxShadow: 'none',
  },
  button: {
    width: '20px',
    height: '20px',
    minWidth: '20px',
    padding: '1px',
    borderRadius: '0',
    backgroundColor: theme.palette.background.default,
    marginLeft: '12px',
    transition: 'all .25s',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      '& svg path': {
        fill: theme.palette.primary.dark,
      },
    },
    '&:active': {
      backgroundColor: theme.palette.background.default,
      '& svg path': {
        fill: theme.palette.primary.light,
      },
    },
    '&.Mui-disabled svg path': {
      fill: theme.palette.info.main,
    },
  },
  backBtn: {
    textTransform: 'capitalize',
  },
  previewFooter: {
    padding: '12px 0',
    '& > div': {
      flexDirection: 'column',
      maxWidth: '400px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  showCount: {
    width: '100%',
    height: '30px',
    padding: '6px 10px',
    marginBottom: '10px',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    backgroundColor: theme.palette.info.main,
    color: theme.palette.text.primary,
    borderRadius: '5px',
  },
  companiesLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  divider: {
    marginTop: '16px',
    marginBottom: '16px',
    backgroundColor: '#DFE6F1',
  },
  searchSectionTitle: {
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    textAlign: 'center',
    marginBottom: '16px',
  },
}));

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="left" ref={ref} {...props} />
));

const InterviewMobile = ({
  allInterviews, loading, interviewId,
  showSearchCounts, companiesCount,
  selected, isSelected, isReadingList,
  handleSidebarOpen, handleWriteFilterData,
  showTrialBanner, me,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const { trackEvent } = useTracking();
  const filterData = useSelector((state) => state.filterState.filterData, isEqual);
  const query = new URLSearchParams(location.search);
  const intSkip = parseInt(query.get('skip') || 0, 10);

  const bulkActionRef = React.createRef();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (interviewId) {
      handleClickOpen();
    }
  }, [interviewId]);

  function handleRowWidth(ratingBoxShouldSticky) {
    const actionsBox = document.getElementById('interviewActions');
    const player = document.getElementById('audioPlayer');
    const ratingBox = document.getElementById('transcriptRating');
    const bulkAction = document.getElementById('bulk-actions');

    if (actionsBox) {
      const { width } = actionsBox.getBoundingClientRect();
      actionsBox.firstChild.style.width = `${width}px`;
    }
    if (player) {
      const { width } = player.getBoundingClientRect();
      player.firstChild.style.width = `${width}px`;
    }
    if (ratingBox) {
      const { width } = ratingBox.getBoundingClientRect();
      const intercomWidth = 90;
      if (ratingBoxShouldSticky) {
        ratingBox.firstChild.style.width = `${width - intercomWidth}px`;
      } else {
        ratingBox.firstChild.style.width = `${width}px`;
      }
    }
    if (bulkAction) {
      const { width } = bulkAction.getBoundingClientRect();
      bulkAction.firstChild.style.width = `${width}px`;
    }
  }

  const handleScroll = ({ actionsBoxShouldSticky, playerShouldSticky, ratingBoxShouldSticky }) => {
    if (actionsBoxShouldSticky || playerShouldSticky || ratingBoxShouldSticky) {
      handleRowWidth(ratingBoxShouldSticky);
    }
  };

  const handleBtnSticky = () => {
    let headerHeight = 71;
    if (showTrialBanner) {
      headerHeight = 108;
    }
    const bulkAction = document.getElementById('bulk-actions');
    if (bulkAction) {
      if (bulkAction.getBoundingClientRect().top <= headerHeight) {
        bulkAction.firstChild.style.position = 'fixed';
        bulkAction.firstChild.style.top = `${headerHeight}px`;
        handleRowWidth();
      } else {
        bulkAction.firstChild.style.position = 'static';
      }
    }
  };

  const handlePaginationClick = (page) => {
    const interviewIds = allInterviews.results.map((el) => el.id);
    trackEvent({
      viewName: 'interview:interview_list',
      path: location.pathname,
      getDict: JSON.stringify(searchToObject(location.search)),
      extraData: JSON.stringify({
        page,
        interviewIds,
      }),
    });
  };

  return (
    <Box className={classes.scrollContainer} onScroll={handleBtnSticky}>
      <Box className={classes.content}>
        <SearchInput loading={loading} />
        <SelectedFilters />

        {showSearchCounts
        && (
          <Box className={classes.showCount}>
            {!loading
            && (
              <>
                <Box component="span">
                  {`${allInterviews.totalCount} interview${allInterviews.totalCount !== 1 ? 's' : ''} and `}
                </Box>
                <Box component="span" onClick={handleSidebarOpen} className={classes.companiesLink}>
                  {`${companiesCount} compan${companiesCount !== 1 ? 'ies' : 'y'}`}
                </Box>
                <Box component="span">{' found'}</Box>
              </>
            )}
          </Box>
        )}

        <Box display="flex" justifyContent="space-between" alignItems="center" mb="10px">
          <Ordering />
          <Box display="flex" alignItems="center">
            <Box display="none">
              <IconButton
                color="inherit"
                aria-label="checkList"
                size="small"
                disableRipple
                disabled
                className={classes.button}
              >
                <CheckboxIcon />
              </IconButton>
            </Box>
            <Box display="none">
              <IconButton
                color="inherit"
                aria-label="menu"
                size="small"
                disableRipple
                disabled
                className={classes.button}
              >
                <DotsIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>

        <BulkActions ref={bulkActionRef} selected={selected} isReadingList={isReadingList} />

        <Fade in={allInterviews.totalCount > 0 && !loading}>
          <Box>
            {(allInterviews.totalCount > 0 && !loading)
            && (
              <>
                {filterData.search && intSkip === 0
                && (
                  <PrimaryTickerSearchResultsSection primaryCount={allInterviews.primaryCount} />
                )}
                {allInterviews.results.map((interview, index) => (
                  <>
                    {filterData.search && (index + intSkip === allInterviews.primaryCount)
                    && (
                      <>
                        <Divider className={classes.divider} />
                        <Box className={classes.searchSectionTitle}>
                          Related interviews (
                          {allInterviews.totalCount - allInterviews.primaryCount}
                          )
                        </Box>
                      </>
                    )}
                    <InterviewItem
                      key={interview.interviewId}
                      id={interview.id}
                      uuid={interview.uuid}
                      keywords={interview.keywords}
                      headline={interview.headline}
                      callDate={interview.callDate}
                      interviewId={interview.interviewId}
                      tickers={interview.tickers}
                      relevantRole={interview.relevantRole}
                      perspective={interview.perspective}
                      publishedAt={interview.publishedAt}
                      readingTime={interview.readingTime}
                      isActive={`U${me.id}-${interview.interviewId}` === interviewId}
                      isUnread={interview.isUnread}
                      isSeenInRl={interview.isSeenInRl}
                      isSelected={isSelected(interview.interviewId)}
                      accessRequest={interview.accessRequest || {}}
                      accessRequests={interview.accessRequests || {}}
                      readingListItem={interview.readingListItem}
                      userCanViewInterview={interview.userCanViewInterview}
                      transcriptionStatus={interview.transcriptionStatus}
                      userHasComplianceLock={interview.userHasComplianceLock}
                      percentsRead={interview.percentsRead}
                      me={me}
                      handleWriteFilterData={handleWriteFilterData}
                    />
                  </>
                ))}
                <PaginationLink
                  totalCount={allInterviews.totalCount}
                  initialFirst={INTERVIEW_PAGINATION_FIRST}
                  onClick={handlePaginationClick}
                />
              </>
            )}
          </Box>
        </Fade>

        {filterData.search && allInterviews.totalCount === 0
        && <PrimaryTickerSearchResultsSection primaryCount={0} />}

        {loading
        && (
          <Fade in={loading}>
            <InterviewItemSkeleton />
          </Fade>
        )}

        <Fade in={allInterviews.totalCount === 0 && !loading}>
          <Box display="flex" justifyContent="center" alignItems="center" flexGrow="1">
            {allInterviews.totalCount === 0 ? <InterviewsNotFound /> : ''}
          </Box>
        </Fade>

        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Button
                autoFocus
                color="inherit"
                onClick={handleClose}
                disableRipple
                className={classes.backBtn}
                startIcon={
                  <ArrowBackIcon />
                }
              >
                Back to interview list
              </Button>
            </Toolbar>
          </AppBar>
          <InterviewPreview
            interviewId={interviewId}
            onScroll={handleScroll}
          />
        </Dialog>
      </Box>
      <Fade in={!loading}>
        <SelectAllBtn
          interviews={allInterviews.results}
          selected={selected}
          isSelected={isSelected}
          isReadingList={isReadingList}
        />
      </Fade>

      <Fade in={!loading}>
        <Footer className={classes.previewFooter} />
      </Fade>
    </Box>
  );
};

InterviewMobile.defaultProps = {
  showSearchCounts: false,
  companiesCount: 0,
};

InterviewMobile.propTypes = {
  allInterviews: PropTypes.shape({
    totalCount: PropTypes.number,
    results: PropTypes.arrayOf(PropTypes.object),
    primaryCount: PropTypes.number.isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  interviewId: PropTypes.string.isRequired,
  showSearchCounts: PropTypes.bool,
  companiesCount: PropTypes.number,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  isSelected: PropTypes.func.isRequired,
  isReadingList: PropTypes.bool.isRequired,
  handleSidebarOpen: PropTypes.func.isRequired,
  handleWriteFilterData: PropTypes.func.isRequired,
  showTrialBanner: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  me: PropTypes.object.isRequired,
};

export default InterviewMobile;
