import React from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/styles';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CONTACT_SALES_MUTATION } from '../../queries/mail';

const useStyles = makeStyles((theme) => ({
  sendBtn: {
    height: '24px',
    backgroundColor: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    color: theme.palette.common.white,
    padding: '3px 8px',
    borderRadius: '5px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  closeDialog: {
    padding: 0,
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.primary,
      backgroundColor: 'transparent',
    },
  },
  dialogContent: {
    padding: '0 15px 15px',
    [theme.breakpoints.up('md')]: {
      padding: '0 24px 24px',
    },
  },
  dialogMessageTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: '32px',
    textAlign: 'center',
  },
  dialogMessageSubtitle: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
  },
  dialogError: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    color: theme.palette.error.main,
  },
  textareaDesc: {
    fontSize: '10px',
    fontWeight: '500',
    lineHeight: '14px',
    color: theme.palette.text.secondary,
  },
  dialogTextarea: {
    width: '100%',
    minHeight: '60px',
    borderRadius: '5px',
    padding: '12px',
    backgroundColor: theme.palette.background.paper,
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    borderColor: theme.palette.info.main,
    outline: 'none',
    '&:hover': {
      borderColor: theme.palette.info.dark,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '512px',
    },
  },
  closeDialogBtn: {
    width: '100%',
    height: '40px',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.common.white,
    border: '1px solid',
    borderColor: theme.palette.info.main,
    borderRadius: '5px',
    textTransform: 'capitalize',
    marginRight: 4,
    '&:hover': {
      color: theme.palette.text.primary,
      borderColor: theme.palette.info.dark,
      backgroundColor: theme.palette.common.white,
    },
    '&:active': {
      borderColor: theme.palette.info.main,
      backgroundColor: theme.palette.common.white,
    },
  },
  confirmBtn: {
    width: '100%',
    height: '40px',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '5px',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.common.white,
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.dark,
    },
    '&:active': {
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.dark,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.info.light,
      borderColor: theme.palette.info.light,
      color: theme.palette.info.dark,
    },
  },
}));

const schema = yup.object().shape({
  message: yup.string()
    .max(1000, 'Max 1000 characters'),
});

const ContactSalesPopup = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const isIE = !!document.documentMode;
  const [open, setOpen] = React.useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: isIE ? undefined : yupResolver(schema),
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    reset();
    setOpen(false);
  };

  const [sendContactSales, { loading }] = useMutation(CONTACT_SALES_MUTATION, {
    onCompleted: () => {
      enqueueSnackbar(
        'Message has been sent',
        { variant: 'success' },
      );
      handleDialogClose();
    },
    onError: (error) => {
      enqueueSnackbar(
        error.message,
        { variant: 'error' },
      );
    },
  });

  const onSubmit = (data) => {
    sendContactSales({
      variables: {
        input: {
          message: data.message,
        },
      },
    });
  };

  return (
    <>
      <Button
        onClick={handleDialogOpen}
        className={classes.sendBtn}
        disableRipple
      >
        Contact Sales
      </Button>
      <Dialog onClose={handleDialogClose} open={open}>
        <MuiDialogTitle disableTypography>
          <Box
            display="flex"
            justifyContent="space-between"
            alignContent="center"
            width="100%"
          >
            <Box className={classes.dialogMessageTitle}>
              Contact sales
            </Box>
            <IconButton
              aria-label="close"
              className={classes.closeDialog}
              onClick={handleDialogClose}
              disableRipple
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </MuiDialogTitle>
        <MuiDialogContent className={classes.dialogContent}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box width="100%">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box className={classes.dialogMessageSubtitle} mb="4px">
                  Message
                  <Box
                    className={classes.dialogError}
                    component="span"
                    ml="4px"
                  >
                    {errors.message?.message}
                  </Box>
                </Box>
                <textarea
                  {...register('message')}
                  maxLength="1000"
                  className={classes.dialogTextarea}
                />
                <Box className={classes.textareaDesc}>Maximum 1000 characters</Box>
                <Box display="flex" justifyContent="center" mt="24px">
                  <Button
                    onClick={handleDialogClose}
                    disableRipple
                    className={classes.closeDialogBtn}
                  >
                    Close
                  </Button>
                  <Box width="100%" ml="4px">
                    <Button
                      type="submit"
                      disableRipple
                      disabled={loading}
                      className={classes.confirmBtn}
                    >
                      {loading ? 'Sending' : 'Send'}
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
          </Box>
        </MuiDialogContent>
      </Dialog>
    </>
  );
};

export default ContactSalesPopup;
