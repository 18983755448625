import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import { formatDate } from '../../../../../helpers/dateHelpers';
import { ReactComponent as ApproveSuccessIcon } from '../../../../../assets/icons/approveSuccessIcon.svg';

const useStyles = makeStyles((theme) => ({
  requestStatus: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '16px',
    float: 'right',
    display: 'flex!important',
  },
  requestStatusSpan: {
    fontWeight: '500',
    color: theme.palette.text.secondary,
    marginLeft: '3px',
    marginRight: '3px',
  },
}));

const AccessRequestStatus = ({ accessRequest }) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      mb={{ xs: '10px', md: '20px' }}
    >
      {accessRequest && accessRequest.status === 'APPROVED'
      && (
        <Box className={classes.requestStatus}>
          <ApproveSuccessIcon />
          <Box display="flex!important">
            <span className={classes.requestStatusSpan}>Approved by</span>
            {accessRequest.statusChangedBy?.fullName}
            <span className={classes.requestStatusSpan}>on</span>
            {formatDate(accessRequest.statusChangedAt)}
          </Box>
        </Box>
      )}
    </Box>
  );
};

AccessRequestStatus.propTypes = {
  accessRequest: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AccessRequestStatus;
