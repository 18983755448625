import React, { useEffect, useState } from 'react';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import parseISO from 'date-fns/parseISO';
import isNaN from 'lodash/isNaN';
import isEmpty from 'lodash/isEmpty';
import useReminder from '../../../../hooks/useReminder';
import ForceEnterWatchlist from '../../../../components/ForceEnterWatchlist/ForceEnterWatchlist';
import useMe from '../../../../hooks/useMe';

const WatchlistReminders = () => {
  const { me, loading: meLoading } = useMe();
  const [dialogOpen, setDialogOpen] = useState(false);
  const {
    showPeriod, showSession, setAsShownPeriod, setAsShownSession,
  } = useReminder();
  const {
    watchlistCount, trialStartDate, isPaid, isTrial,
    isInternal, showWatchlistModal, role,
  } = me;

  const askForWatchlist = role === 'MEMBER' && !isInternal;

  const showForPaid = isPaid && !watchlistCount && showWatchlistModal && showPeriod;
  const showForTrial = isTrial && !watchlistCount && showWatchlistModal && showSession;

  const handleClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    if (showForPaid) {
      setDialogOpen(true);
      setAsShownPeriod();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showForPaid]);

  useEffect(() => {
    const now = Date.now();
    const start = parseISO(trialStartDate);
    const daysDelta = differenceInCalendarDays(now, start);
    if (!isNaN(daysDelta) && daysDelta >= 1 && showForTrial) {
      setDialogOpen(true);
      setAsShownSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showForTrial]);

  if (meLoading || isEmpty(me) || !askForWatchlist) return null;

  return (
    <ForceEnterWatchlist open={dialogOpen} handleClose={handleClose} />
  );
};

export default WatchlistReminders;
