import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import Divider from '@material-ui/core/Divider';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
  smallRect: {
    backgroundColor: theme.palette.info.main,
    borderRadius: '5px',
  },
  mr10: {
    marginRight: '10px',
    backgroundColor: theme.palette.info.main,
    borderRadius: '5px',
  },
  mr15: {
    marginRight: '15px',
    backgroundColor: theme.palette.info.main,
    borderRadius: '5px',
  },
  divider: {
    marginTop: '12px',
    marginBottom: '12px',
  },
}));

const InterviewPreviewSkeleton = ({
  totalLoading,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: false,
  });

  return (
    <Fade in={totalLoading} timeout={{ enter: 100, exit: 100 }}>
      <Box>
        {isMobile
          ? (
            <>
              <Box display="flex" justifyContent="space-between" alignItems="center" mt="10px" mb="10px">
                <Skeleton className={classes.smallRect} variant="rect" width="86px" height="30px" />
                <Box display="flex">
                  <Skeleton className={classes.mr10} variant="rect" width="30px" height="30px" />
                  <Skeleton className={classes.mr10} variant="rect" width="30px" height="30px" />
                  <Skeleton className={classes.smallRect} variant="rect" width="30px" height="30px" />
                </Box>
              </Box>
              <Box display="flex" mb="10px">
                <Skeleton className={classes.smallRect} variant="rect" width="100px" height="20px" />
              </Box>
              <Box display="flex" mb="8px">
                <Skeleton className={classes.mr15} variant="rect" width="180px" height="22px" />
                <Skeleton className={classes.smallRect} variant="rect" width="80px" height="22px" />
              </Box>
              <Box display="flex" mb="8px">
                <Skeleton className={classes.smallRect} variant="rect" width="100%" height="40px" />
              </Box>
              <Box display="flex">
                <Skeleton className={classes.smallRect} variant="rect" width="200px" height="20px" />
              </Box>
              <Divider className={classes.divider} />
              <Box display="flex" mb="8px">
                <Skeleton className={classes.smallRect} variant="rect" width="100%" height="75px" />
              </Box>
              <Box display="flex" mb="6px">
                <Skeleton className={classes.smallRect} variant="rect" width="106px" height="20px" />
              </Box>
              <Box display="flex" mb="8px">
                <Skeleton className={classes.smallRect} variant="rect" width="100%" height="70px" />
              </Box>
              <Box display="flex" mb="6px">
                <Skeleton className={classes.smallRect} variant="rect" width="130px" height="20px" />
              </Box>
              <Box display="flex">
                <Skeleton className={classes.smallRect} variant="rect" width="100%" height="70px" />
              </Box>
              <Divider className={classes.divider} />
              <Box display="flex" mb="6px">
                <Skeleton className={classes.smallRect} variant="rect" width="78px" height="20px" />
              </Box>
              <Box display="flex" mb="8px">
                <Skeleton className={classes.smallRect} variant="rect" width="100%" height="60px" />
              </Box>
              <Box display="flex" mb="8px">
                <Skeleton className={classes.smallRect} variant="rect" width="258" height="30px" />
              </Box>
              <Box display="flex" mb="6px">
                <Skeleton className={classes.smallRect} variant="rect" width="152px" height="20px" />
              </Box>
              <Box display="flex">
                <Skeleton className={classes.smallRect} variant="rect" width="100%" height="60px" />
              </Box>
              <Divider className={classes.divider} />
              <Box display="flex" mb="12px">
                <Skeleton className={classes.smallRect} variant="rect" width="100%" height="50px" />
              </Box>
              <Box display="flex" mb="8px">
                <Skeleton className={classes.smallRect} variant="rect" width="130px" height="20px" />
              </Box>
              <Box display="flex" mb="8px">
                <Skeleton className={classes.smallRect} variant="rect" width="100%" height="40px" />
              </Box>
              <Box display="flex" mb="8px">
                <Skeleton className={classes.smallRect} variant="rect" width="100%" height="40px" />
              </Box>
              <Box display="flex" mb="8px">
                <Skeleton className={classes.smallRect} variant="rect" width="100%" height="40px" />
              </Box>
            </>
          )
          : (
            <>
              <Box display="flex" justifyContent="space-between" alignItems="center" mt="10px" mb="10px">
                <Skeleton className={classes.smallRect} variant="rect" width="86px" height="30px" />
                <Box display="flex">
                  <Skeleton className={classes.mr10} variant="rect" width="30px" height="30px" />
                  <Skeleton className={classes.mr10} variant="rect" width="30px" height="30px" />
                  <Skeleton className={classes.mr10} variant="rect" width="30px" height="30px" />
                  <Skeleton className={classes.smallRect} variant="rect" width="30px" height="30px" />
                </Box>
              </Box>
              <Box display="flex" mb="10px">
                <Skeleton className={classes.smallRect} variant="rect" width="100px" height="20px" />
              </Box>
              <Box display="flex" mb="8px">
                <Skeleton className={classes.mr15} variant="rect" width="300px" height="22px" />
                <Skeleton className={classes.smallRect} variant="rect" width="80px" height="22px" />
              </Box>
              <Box display="flex" mb="8px">
                <Skeleton className={classes.smallRect} variant="rect" width="60%" height="20px" />
              </Box>
              <Box display="flex">
                <Skeleton className={classes.smallRect} variant="rect" width="200px" height="20px" />
              </Box>
              <Divider className={classes.divider} />
              <Box display="flex" mb="8px">
                <Skeleton className={classes.smallRect} variant="rect" width="75%" height="28px" />
              </Box>
              <Box display="flex" mb="6px">
                <Skeleton className={classes.smallRect} variant="rect" width="106px" height="20px" />
              </Box>
              <Box display="flex" mb="8px">
                <Skeleton className={classes.smallRect} variant="rect" width="100%" height="75px" />
              </Box>
              <Box display="flex" mb="6px">
                <Skeleton className={classes.smallRect} variant="rect" width="130px" height="20px" />
              </Box>
              <Box display="flex">
                <Skeleton className={classes.smallRect} variant="rect" width="100%" height="75px" />
              </Box>
              <Divider className={classes.divider} />
              <Box display="flex" mb="6px">
                <Skeleton className={classes.smallRect} variant="rect" width="78px" height="20px" />
              </Box>
              <Box display="flex" mb="8px">
                <Skeleton className={classes.smallRect} variant="rect" width="100%" height="60px" />
              </Box>
              <Box display="flex" mb="8px">
                <Skeleton className={classes.smallRect} variant="rect" width="257px" height="30px" />
              </Box>
              <Box display="flex" mb="6px">
                <Skeleton className={classes.smallRect} variant="rect" width="152px" height="20px" />
              </Box>
              <Box display="flex">
                <Skeleton className={classes.smallRect} variant="rect" width="100%" height="60px" />
              </Box>
              <Divider className={classes.divider} />
              <Box display="flex" mb="12px">
                <Skeleton className={classes.smallRect} variant="rect" width="100%" height="52px" />
              </Box>
              <Box display="flex" mb="8px">
                <Skeleton className={classes.smallRect} variant="rect" width="130px" height="20px" />
              </Box>
              <Box display="flex" mb="8px">
                <Skeleton className={classes.smallRect} variant="rect" width="100%" height="40px" />
              </Box>
              <Box display="flex" mb="8px">
                <Skeleton className={classes.smallRect} variant="rect" width="100%" height="40px" />
              </Box>
              <Box display="flex" mb="8px">
                <Skeleton className={classes.smallRect} variant="rect" width="100%" height="40px" />
              </Box>
              <Box display="flex" mb="8px">
                <Skeleton className={classes.smallRect} variant="rect" width="100%" height="40px" />
              </Box>
              <Box display="flex" mb="8px">
                <Skeleton className={classes.smallRect} variant="rect" width="100%" height="40px" />
              </Box>
              <Box display="flex" mb="8px">
                <Skeleton className={classes.smallRect} variant="rect" width="100%" height="40px" />
              </Box>
              <Box display="flex" mb="8px">
                <Skeleton className={classes.smallRect} variant="rect" width="100%" height="40px" />
              </Box>
              <Box display="flex" mb="8px">
                <Skeleton className={classes.smallRect} variant="rect" width="100%" height="40px" />
              </Box>
              <Box display="flex" mb="8px">
                <Skeleton className={classes.smallRect} variant="rect" width="100%" height="40px" />
              </Box>
            </>
          )}
      </Box>
    </Fade>
  );
};

InterviewPreviewSkeleton.propTypes = {
  totalLoading: PropTypes.bool.isRequired,
};

export default InterviewPreviewSkeleton;
