import React from 'react';
import PropTypes from 'prop-types';
import { IndustryItem } from '../../../../../components/InterviewPreviewComponents';

const Sector = ({ interview }) => {
  const primarySector = interview.tickers[0].ticker.sector;
  if (!primarySector) return null;

  return (
    <IndustryItem
      filterKey="sectors"
      interviewId={interview.interviewId}
      itemId={primarySector.id}
      itemTitle={primarySector.title}
      itemUUID={primarySector.uuid}
      filterMenuItemText="Filter by this Sector"
      isAddedToWatchList={primarySector.isAddedToWatchList}
    />
  );
};

Sector.propTypes = {
  interview: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sector;
