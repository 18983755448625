import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { ReactComponent as BookmarkIcon } from '../../../../../assets/icons/bookmarkIcon.svg';
import { ReactComponent as BookmarkSelectedIcon } from '../../../../../assets/icons/bookmarkSelectedIcon.svg';
import { ReactComponent as BookmarkBorderIcon } from '../../../../../assets/icons/bookmarkBorderIcon.svg';
import { ReactComponent as PrintIcon } from '../../../../../assets/icons/printIcon.svg';
import { ReactComponent as PrintSelectedIcon } from '../../../../../assets/icons/printSelectedIcon.svg';
import DeleteFromReadingList from './DeleteFromReadingList';
import { addItem, removeItem } from '../../../../../actions/bulkSelectActions';
import useReadingListTitle from '../../../../../hooks/useReadingListTitle';

const useStyles = makeStyles((theme) => ({
  bookmarkIcon: {
    display: 'flex',
    padding: '2px 4px',
    cursor: 'default',
  },
  bookmarkBtn: {
    padding: '2px 4px',
    borderRadius: '2px',
    '&:hover': {
      backgroundColor: theme.palette.info.light,
    },
  },
  seenIcon: {
    display: 'flex',
    padding: '2px',
  },
  printBtn: {
    padding: '2px',
    borderRadius: '2px',
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: theme.palette.info.light,
    },
  },
}));

const CommonActions = ({
  handleAdd, handleDelete, isSelected, isSeenInRl, interviewId,
}) => {
  const classes = useStyles();
  const readingListTitle = useReadingListTitle();

  const addToReadingList = () => {
    if (isSelected) {
      return (
        <IconButton
          className={classes.bookmarkBtn}
          onClick={handleDelete}
          aria-label="unmark interview"
          disableRipple
        >
          <BookmarkSelectedIcon />
        </IconButton>
      );
    }
    return (
      <Tooltip disableFocusListener title={`Add to ${readingListTitle.toLowerCase()}`}>
        <IconButton
          className={classes.bookmarkBtn}
          onClick={handleAdd}
          aria-label="mark interview"
          disableRipple
        >
          <BookmarkBorderIcon />
        </IconButton>
      </Tooltip>
    );
  };

  return (
    (isSeenInRl !== null) ? (
    // <>DELETE</>
      <DeleteFromReadingList
        interviewId={interviewId}
        icon={<BookmarkIcon />}
      />
    ) : (
    // <>SELECT/DESELECT</>
      addToReadingList()
    )
  );
};

CommonActions.defaultProps = {
  isSeenInRl: null,
};

CommonActions.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  isSeenInRl: PropTypes.bool,
  interviewId: PropTypes.string.isRequired,
  handleAdd: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

const ReadingListActions = ({
  handleAdd, handleDelete, isSelected, interviewId, isPrintAllowed,
}) => {
  const classes = useStyles();

  return (
    <>
      {isPrintAllowed
        && (
        <Tooltip disableFocusListener title={isSelected ? 'Unselect Interview for Print' : 'Select Interview for Print'}>
          <IconButton
            className={classes.printBtn}
            aria-label={isSelected ? 'unmark interview for print' : 'mark interview for print'}
            onClick={isSelected ? handleDelete : handleAdd}
            disableRipple
          >
            {isSelected ? <PrintSelectedIcon /> : <PrintIcon />}
          </IconButton>
        </Tooltip>
        )}

      <DeleteFromReadingList
        interviewId={interviewId}
        icon={<BookmarkIcon />}
      />
    </>
  );
};

ReadingListActions.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  interviewId: PropTypes.string.isRequired,
  isPrintAllowed: PropTypes.bool.isRequired,
  handleAdd: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

const InterviewItemActions = ({
  isSeenInRl, interviewId, isPrintAllowed, stopPropagation,
}) => {
  const dispatch = useDispatch();
  const filterData = useSelector((state) => state.filterState.filterData, isEqual);
  // eslint-disable-next-line max-len
  const isSelected = useSelector((state) => state.bulkSelectedState.selected.includes(interviewId), isEqual);
  const handleSelectItem = (intId) => (e) => {
    e.stopPropagation();
    dispatch(addItem(intId));
  };
  const handleDeSelectItem = (intId) => (e) => {
    e.stopPropagation();
    dispatch(removeItem(intId));
  };
  const isReadingList = filterData.readingLists && filterData.readingLists.length > 0;

  return (
    <Box display="flex">
      {isReadingList
        ? (
          <ReadingListActions
            isSelected={isSelected}
            handleAdd={handleSelectItem(interviewId)}
            handleDelete={handleDeSelectItem(interviewId)}
            interviewId={interviewId}
            isSeenInRl={isSeenInRl}
            isPrintAllowed={isPrintAllowed}
            stopPropagation={stopPropagation}
            filterData={filterData}
          />
        )
        : (
          <CommonActions
            isSelected={isSelected}
            handleAdd={handleSelectItem(interviewId)}
            handleDelete={handleDeSelectItem(interviewId)}
            isSeenInRl={isSeenInRl}
            interviewId={interviewId}
          />
        )}
    </Box>
  );
};

InterviewItemActions.defaultProps = {
  isSeenInRl: null,
  stopPropagation: null,
};

InterviewItemActions.propTypes = {
  isSeenInRl: PropTypes.bool,
  interviewId: PropTypes.string.isRequired,
  isPrintAllowed: PropTypes.bool.isRequired,
  stopPropagation: PropTypes.func,
};

const areEqual = (prevProps, nextProps) => {
  const prevObj = {
    isSeenInRl: prevProps.isSeenInRl,
    isSelected: prevProps.isSelected,
  };
  const nextObj = {
    isSeenInRl: nextProps.isSeenInRl,
    isSelected: nextProps.isSelected,
  };

  return isEqual(prevObj, nextObj);
};

export default React.memo(InterviewItemActions, areEqual);
