/* eslint-disable react/jsx-no-duplicate-props */
import React, { useCallback, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/styles';
import isEmpty from 'lodash/isEmpty';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ASK_QUESTIONS_MUTATION } from '../../queries/mail';
import { ReactComponent as RequestContentImage } from '../../assets/images/requestContent.svg';

const useStyles = makeStyles((theme) => ({
  closeDialog: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.primary,
      backgroundColor: 'transparent',
    },
  },
  dialogContent: {
    padding: '0 15px 15px',
    [theme.breakpoints.up('md')]: {
      padding: '0 24px 24px',
    },
  },
  dialogMessageTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: '32px',
    textAlign: 'center',
    margin: '26px 0 10px',
  },
  dialogMessage: {
    width: '100%',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '20px',
    textAlign: 'center',
  },
  dialogMessageSubtitle: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
  },
  dialogError: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    color: theme.palette.error.main,
  },
  expertBio: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    marginBottom: '12px',
    '& ul': {
      paddingLeft: '1.3rem',
    },
  },
  textareaDesc: {
    fontSize: '10px',
    fontWeight: '500',
    lineHeight: '14px',
    color: theme.palette.text.secondary,
  },
  dialogTextarea: {
    width: '100%',
    minHeight: '90px',
    borderRadius: '5px',
    padding: '12px',
    backgroundColor: theme.palette.background.paper,
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    borderColor: theme.palette.info.main,
    outline: 'none',
    '&:hover': {
      borderColor: theme.palette.info.dark,
    },
  },
  selectContainer: {
    marginBottom: '12px',
    '& .MuiInputBase-root': {
      height: '40px',
      cursor: 'pointer',
      '& .MuiSelect-root': {
        backgroundColor: 'transparent',
        padding: '10px 30px 10px 10px!important',
        minHeight: '18px',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '18px',
        color: theme.palette.text.primary,
        borderRadius: '5px',
      },
      '& svg': {
        position: 'absolute',
        right: '7px',
        pointerEvents: 'none',
      },
      '& fieldset': {
        border: '1px solid',
        borderColor: theme.palette.info.main,
      },
      '&:hover': {
        '& fieldset': {
          borderColor: theme.palette.info.dark,
        },
      },
      '&.Mui-focused': {
        '& fieldset': {
          borderColor: theme.palette.info.dark,
          borderWidth: '1px',
        },
        '&:hover': {
          '& fieldset': {
            borderColor: theme.palette.info.dark,
          },
        },
      },
    },
  },
  menuItem: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    [theme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
    },
    '&:active': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
      [theme.breakpoints.up('md')]: {
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
          color: theme.palette.common.white,
        },
      },
    },
  },
  inputField: {
    display: 'block',
    width: '100%',
    border: '1px solid',
    borderColor: theme.palette.info.main,
    borderRadius: '5px',
    padding: '5px 10px',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    height: '40px',
    outline: 'none',
    '&:hover': {
      borderColor: theme.palette.info.dark,
    },
    '&:focus': {
      borderColor: theme.palette.info.dark,
    },
  },
  confirmBtn: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    padding: '5px 80px',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '5px',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.common.white,
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.dark,
    },
    '&:active': {
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const schema = yup.object().shape({
  topic: yup.string()
    .required('Field is required')
    .max(200, 'Max 200 characters'),
  questions: yup.string()
    .max(1000, 'Max 1000 characters'),
});

const AskQuestionsExpertBtn = ({
  variant, className, companies, expert, perspective,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const isIE = !!document.documentMode;
  const [open, setOpen] = React.useState(false);
  const [openTextField, setOpenTextField] = React.useState(false);

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: isIE ? undefined : yupResolver(schema),
    reValidateMode: 'onBlur',
    mode: 'onChange',
    defaultValues: {
      pubOptions: 'Schedule a call with this expert',
    },
  });

  const createTopic = useCallback(() => {
    const labels = companies.map((el) => `${el.ticker.description} (${el.ticker.title})`);
    return labels.length > 0 ? `${labels.join(', ')}` : '';
  }, [companies]);

  useEffect(() => {
    setValue('topic', createTopic());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDialogOpen = () => {
    setOpen(true);
    setOpenTextField(false);
  };

  const handleDialogClose = () => {
    reset({
      topic: createTopic(),
      questions: '',
    }, {
      keepDefaultValues: true,
    });
    setOpen(false);
  };

  const handleTextFieldOpen = () => {
    setOpenTextField(true);
  };

  const handleTextFieldClose = () => {
    reset({
      questions: '',
    }, {
      keepDefaultValues: false,
    });
    setOpenTextField(false);
  };

  const [sendAskQuestions, { loading }] = useMutation(ASK_QUESTIONS_MUTATION, {
    onCompleted: () => {
      enqueueSnackbar(
        'Request has been sent',
        { variant: 'success' },
      );
      handleDialogClose();
    },
    onError: (error) => {
      enqueueSnackbar(
        error.message,
        { variant: 'error' },
      );
    },
  });

  const onSubmit = (data) => {
    sendAskQuestions({
      variables: {
        input: {
          expertId: expert?.id,
          perspective,
          ...data,
        },
      },
    });
  };

  return (
    <>
      <Button
        onClick={handleDialogOpen}
        variant={variant}
        className={className}
        disableRipple
      >
        Request a Call
      </Button>
      <Dialog onClose={handleDialogClose} open={open}>
        <MuiDialogTitle disableTypography>
          <IconButton
            aria-label="close"
            className={classes.closeDialog}
            onClick={handleDialogClose}
            disableRipple
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <MuiDialogContent className={classes.dialogContent}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <RequestContentImage />
            <Box className={classes.dialogMessageTitle}>
              Request a Call
            </Box>
            <Box className={classes.dialogMessage}>
              <Box mb="28px">
                Press the Submit button below and we&apos;ll contact you
                with times this expert is available to speak with you!
              </Box>
            </Box>
            <Box width="100%">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box className={classes.dialogMessageSubtitle} mb="4px">
                  Topic
                  <Box
                    className={classes.dialogError}
                    component="span"
                    ml="4px"
                  >
                    {errors.topic?.message}
                  </Box>
                </Box>
                <Box mb="12px">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="topicField"
                        className={classes.inputField}
                        InputProps={{ disableUnderline: true }}
                        inputProps={{ maxLength: 200 }}
                        fullWidth
                      />
                    )}
                    name="topic"
                  />
                </Box>
                {!isEmpty(expert) && !isEmpty(expert.bio)
                && (
                  <>
                    <Box className={classes.dialogMessageSubtitle} mb="4px">
                      Expert Bio
                    </Box>
                    <Box
                      dangerouslySetInnerHTML={{ __html: expert.bio }}
                      className={classes.expertBio}
                    />
                  </>
                )}
                <Box className={classes.dialogMessageSubtitle} mb="4px">
                  Method of Contact
                  <Box
                    className={classes.dialogError}
                    component="span"
                    ml="4px"
                  >
                    {errors.pubOptions?.message}
                  </Box>
                </Box>
                <Box className={classes.selectContainer}>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControl error={errors.pubOptions?.message} fullWidth>
                        <Select
                          id="pubOptionsField"
                          variant="outlined"
                          value={value}
                          onChange={onChange}
                        >
                          <MenuItem
                            onClick={handleTextFieldClose}
                            className={classes.menuItem}
                            value="Schedule a call with this expert"
                          >
                            Schedule a call with this expert
                          </MenuItem>
                          <MenuItem
                            onClick={handleTextFieldOpen}
                            className={classes.menuItem}
                            value="Provide questions for a call run by a Stream analyst"
                          >
                            Provide questions for a call run by a Stream analyst
                          </MenuItem>
                        </Select>
                      </FormControl>
                    )}
                    name="pubOptions"
                  />
                </Box>
                {openTextField ? (
                  <div>
                    <Box className={classes.dialogMessageSubtitle} mb="4px">
                      Questions to Ask the Expert
                      <Box
                        className={classes.dialogError}
                        component="span"
                        ml="4px"
                      >
                        {errors.questions?.message}
                      </Box>
                    </Box>
                    <textarea
                      {...register('questions')}
                      maxLength="1000"
                      className={classes.dialogTextarea}
                    />
                    <Box className={classes.textareaDesc}>Maximum 1000 characters</Box>
                  </div>
                ) : null}
                <Box display="flex" justifyContent="center" mt="24px">
                  <Button
                    type="submit"
                    autoFocus
                    disableRipple
                    disabled={loading}
                    className={classes.confirmBtn}
                  >
                    {loading ? 'Submitting' : 'Submit' }
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </MuiDialogContent>
      </Dialog>
    </>
  );
};

AskQuestionsExpertBtn.defaultProps = {
  variant: 'text',
  className: '',
  perspective: '',
};

AskQuestionsExpertBtn.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  expert: PropTypes.arrayOf(PropTypes.object).isRequired,
  perspective: PropTypes.string,
};

export default AskQuestionsExpertBtn;
