import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/styles';
import { useMutation } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { CONTENT_REQUEST_MUTATION } from '../../../queries/mail';
import { getFiltersLabels } from '../../../helpers/filterHelpers';
import { ReactComponent as RequestContentImage } from '../../../assets/images/requestContent.svg';

const useStyles = makeStyles((theme) => ({
  closeDialog: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.primary,
      backgroundColor: 'transparent',
    },
  },
  dialogContent: {
    padding: '0 15px 15px',
    [theme.breakpoints.up('md')]: {
      padding: '0 24px 24px',
    },
  },
  dialogMessageTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: '32px',
    textAlign: 'center',
    margin: '26px 0 10px',
  },
  dialogMessage: {
    width: '100%',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '20px',
    textAlign: 'center',
  },
  dialogMessageSubtitle: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
  },
  dialogError: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    color: theme.palette.error.main,
  },
  checkbox: {
    padding: '0 12px 0 2px',
    color: theme.palette.info.main,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.paper,
    },
    '&.Mui-checked': {
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
  labelLink: {
    fontWeight: '500',
    color: theme.palette.text.primary,
  },
  radio: {
    padding: '0 12px 0 2px',
    color: theme.palette.info.main,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.paper,
    },
    '&.Mui-checked': {
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
  radioLabel: {
    marginLeft: 0,
    marginBottom: '5px',
  },
  textareaDesc: {
    fontSize: '10px',
    fontWeight: '500',
    lineHeight: '14px',
    color: theme.palette.text.secondary,
  },
  dialogTextarea: {
    width: '100%',
    minHeight: '60px',
    borderRadius: '5px',
    padding: '12px',
    backgroundColor: theme.palette.background.paper,
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    borderColor: theme.palette.info.main,
    outline: 'none',
    '&:hover': {
      borderColor: theme.palette.info.dark,
    },
  },
  inputField: {
    display: 'block',
    width: '100%',
    border: '1px solid',
    borderColor: theme.palette.info.main,
    borderRadius: '5px',
    padding: '5px 10px',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    height: '40px',
    outline: 'none',
    '&:hover': {
      borderColor: theme.palette.info.dark,
    },
    '&:focus': {
      borderColor: theme.palette.info.dark,
    },
  },
  confirmBtn: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    padding: '5px 80px',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '5px',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.common.white,
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.dark,
    },
    '&:active': {
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.dark,
    },
  },
  checkboxLabel: {
    marginLeft: 0,
  },
  red: {
    color: theme.palette.error.main,
  },
  options: {
    flexDirection: 'row',
  },
}));

const schema = yup.object().shape({
  isPrivateCompany: yup.boolean(),
  topic: yup.string()
    .when('isPrivateCompany', {
      is: true,
      then: yup.string(),
      otherwise: yup.string().required('Field is required'),
    }),
  companyName: yup.string()
    .required('Field is required')
    .max(1000, 'Max 1000 characters'),
  questions: yup.string()
    .max(1000, 'Max 1000 characters'),
});

const checkboxes = [
  { name: 'formerPerspective', label: 'Former' },
  { name: 'competitorPerspective', label: 'Competitor' },
  { name: 'customerPerspective', label: 'Customer' },
  { name: 'otherPerspective', label: 'Other' },
];

const RequestNewContentDialog = ({
  handleOpen, handleClose,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const filterData = useSelector((state) => state.filterState.filterData, isEqual);
  const isIE = !!document.documentMode;

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: isIE ? undefined : yupResolver(schema),
    reValidateMode: 'onBlur',
    mode: 'onChange',
    defaultValues: {
      callOptions: 'Have a Stream analyst do the call(s)',
    },
  });

  const options = [
    { value: 'I want to do the call(s)' },
    { value: 'Have a Stream analyst do the call(s)' },
  ];

  const watchIsPrivateCompany = watch('isPrivateCompany', false);
  const watchPerspective = watch('otherPerspective', false);
  const watchCompetitor = watch('competitorPerspective', false);

  const handleDialogClose = () => {
    reset();
    handleClose();
  };

  const [sendContentRequest, { loading }] = useMutation(CONTENT_REQUEST_MUTATION, {
    onCompleted: () => {
      enqueueSnackbar(
        'Request has been sent',
        { variant: 'success' },
      );
      handleDialogClose();
    },
    onError: (error) => {
      enqueueSnackbar(
        error,
        { variant: 'error' },
      );
    },
  });

  const filterLabels = getFiltersLabels(filterData);

  const onSubmit = (data) => {
    const checkboxKeys = checkboxes.map((el) => el.name);
    const perspective = Object.entries(data)
      .filter(([key, value]) => checkboxKeys.includes(key) && value === true)
      .map(([key]) => checkboxes.find((obj) => obj.name === key))
      .map((el) => el.label);

    sendContentRequest({
      variables: {
        input: {
          topic: data.topic,
          companyName: data.companyName,
          questions: data.questions,
          perspectiveInput: data.perspectiveInput,
          competitorInput: data.competitorInput,
          isPrivateCompany: data.isPrivateCompany,
          callOptions: data.callOptions,
          perspective: perspective.join('; '),
          filterData: JSON.stringify([
            ...filterLabels,
            { label: filterData.search, value: 'Search', filterKey: 'search' },
          ]),
        },
      },
    });
  };

  const createTopic = useCallback(() => {
    const topicSearch = filterData.search ? filterData.search : '';
    const labels = filterLabels.filter((el) => el.label).map((el) => el.label);
    const labelsString = labels.length > 0 ? `Filters: ${labels.join(', ')}` : '';

    return `${(topicSearch && labelsString) && 'Search: '}${topicSearch}${(topicSearch && labelsString) && '; '}${labelsString}`;
  }, [filterData.search, filterLabels]);

  useEffect(() => {
    setValue('topic', createTopic());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog onClose={handleDialogClose} open={handleOpen}>
      <MuiDialogTitle disableTypography>
        <IconButton
          aria-label="close"
          className={classes.closeDialog}
          onClick={handleDialogClose}
          disableRipple
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <MuiDialogContent className={classes.dialogContent}>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <RequestContentImage />
          <Box className={classes.dialogMessageTitle}>
            Content Request
          </Box>
          <Box className={classes.dialogMessage}>
            <Box mb="20px">
              Help us create fresh, relevant content by sharing ideas for companies
              and topics we should cover. We’ll do our best to get the answers
              you’re looking for. Thanks in advance!
            </Box>
            <Box mb="28px">
              Request as many companies as you like... but only one at a time.
            </Box>
          </Box>
          <Box width="100%">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box display="flex" alignItems="center" mb="18px">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={(
                        <Checkbox
                          {...field}
                          innerRef={field.ref}
                          checked={field.value}
                          disableRipple
                          className={classes.checkbox}
                        />
                      )}
                      label={
                        <Box className={classes.labelLink} component="span">Is Private Company</Box>
                      }
                      className={classes.checkboxLabel}
                    />
                  )}
                  name="isPrivateCompany"
                />
              </Box>
              {!watchIsPrivateCompany
              && (
                <>
                  <Box className={classes.dialogMessageSubtitle} mb="4px">
                    Ticker
                    <span className={classes.red}>*</span>
                    <Box
                      className={classes.dialogError}
                      component="span"
                      ml="4px"
                    >
                      {errors.topic?.message}
                    </Box>
                  </Box>
                  <Box mb="12px">
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="topicField"
                          className={classes.inputField}
                          InputProps={{ disableUnderline: true }}
                          fullWidth
                        />
                      )}
                      name="topic"
                    />
                  </Box>
                </>
              )}
              <Box className={classes.dialogMessageSubtitle} mb="4px">
                Company Name
                <span className={classes.red}>*</span>
                <Box
                  className={classes.dialogError}
                  component="span"
                  ml="4px"
                >
                  {errors.companyName?.message}
                </Box>
              </Box>
              <Box mb="12px">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="companyNameField"
                      className={classes.inputField}
                      InputProps={{ disableUnderline: true }}
                      fullWidth
                    />
                  )}
                  name="companyName"
                />
              </Box>
              <Box className={classes.dialogMessageSubtitle} mb="14px">
                Expert’s Perspective (Select all that apply)
              </Box>
              <Box
                display="flex"
                flexDirection={{ xs: 'column', md: 'row' }}
                mb="18px"
              >
                {checkboxes.map((v) => (
                  <Box display="flex" alignItems="center" mb={{ xs: '8px', md: 0 }}>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={(
                            <Checkbox
                              {...field}
                              innerRef={field.ref}
                              checked={field.value}
                              disableRipple
                              className={classes.checkbox}
                            />
                          )}
                          label={
                            <Box className={classes.labelLink} component="span">{v.label}</Box>
                          }
                          className={classes.checkboxLabel}
                        />
                      )}
                      name={v.name}
                    />
                  </Box>
                ))}
              </Box>
              {watchPerspective
              && (
                <Box mb="12px">
                  <TextField
                    {...register('perspectiveInput')}
                    id="otherPerspectiveField"
                    className={classes.inputField}
                    InputProps={{ disableUnderline: true }}
                    placeholder="Type a perspective"
                    fullWidth
                  />
                </Box>
              )}
              {watchCompetitor
              && (
                <>
                  <Box className={classes.dialogMessageSubtitle} mb="4px">
                    Which competitor(s)?
                  </Box>
                  <Box mb="12px">
                    <TextField
                      {...register('competitorInput')}
                      id="whichCompetitorField"
                      className={classes.inputField}
                      InputProps={{ disableUnderline: true }}
                      fullWidth
                    />
                  </Box>
                </>
              )}
              <Box className={classes.dialogMessageSubtitle} mb="4px">
                Questions to Ask the Expert
                <Box
                  className={classes.dialogError}
                  component="span"
                  ml="4px"
                >
                  {errors.questions?.message}
                </Box>
              </Box>
              <textarea
                {...register('questions')}
                maxLength="1000"
                className={classes.dialogTextarea}
              />
              <Box className={classes.textareaDesc}>Maximum 1000 characters</Box>
              <Box className={classes.dialogMessageSubtitle} mt="16px" mb="14px">
                Call Options
                <Box
                  className={classes.dialogError}
                  component="span"
                  ml="4px"
                >
                  {errors.callOptions?.message}
                </Box>
              </Box>
              <Box>
                <Controller
                  render={({ field }) => (
                    <RadioGroup aria-label="callOptions" {...field} className={classes.options}>
                      {options.map((v) => (
                        <FormControlLabel
                          value={v.value}
                          control={(
                            <Radio
                              disableRipple
                              className={classes.radio}
                            />
                          )}
                          label={
                            <Box className={classes.labelLink} component="span">{v.value}</Box>
                          }
                          className={classes.radioLabel}
                        />
                      ))}
                    </RadioGroup>
                  )}
                  name="callOptions"
                  control={control}
                />
              </Box>
              <Box display="flex" justifyContent="center" mt="24px">
                <Button
                  type="submit"
                  autoFocus
                  disableRipple
                  disabled={loading}
                  className={classes.confirmBtn}
                >
                  {loading ? 'Submitting' : 'Submit' }
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </MuiDialogContent>
    </Dialog>
  );
};

RequestNewContentDialog.propTypes = {
  handleOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default RequestNewContentDialog;
