import { useEffect, useState } from 'react';
import parseISO from 'date-fns/parseISO';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import useMe from './useMe';

const useTrialReminder = () => {
  const [isExpiring, setIsExpiring] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [daysLeft, setDaysLeft] = useState(null);

  const { me } = useMe();

  const {
    isTrial, hasPaidAccess, isInternal, role, trialExpireDate,
  } = me || {};

  useEffect(() => {
    if (!isInternal && role === 'MEMBER') {
      const now = Date.now();
      const endDate = parseISO(trialExpireDate);
      const daysDelta = differenceInCalendarDays(endDate, now);

      setDaysLeft(daysDelta);
      if (daysDelta >= 0 && daysDelta <= 7 && isTrial) {
        setIsExpiring(true);
      } else if (daysDelta < 0 && !hasPaidAccess) {
        setIsExpired(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  return {
    isExpiring, isExpired, daysLeft,
  };
};

export default useTrialReminder;
