import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { useLazyQuery } from '@apollo/client';
import { Link, useLocation } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import { makeStyles } from '@material-ui/styles';
import isEmpty from 'lodash/isEmpty';
import { formatDate } from '../../helpers/dateHelpers';
import useMe from '../../hooks/useMe';
import { searchToObject } from '../../helpers/locationSearchToJson';
import { INTERVIEW_RELATED_QUERY } from '../../queries/interview/interview';
import { ReactComponent as DateIcon } from '../../assets/icons/dateIcon.svg';

const useStyles = makeStyles((theme) => ({
  more: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.info.main,
    paddingBottom: '15px',
    marginBottom: '20px',
  },
  moreon: {
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '23px',
    color: theme.palette.text.primary,
    paddingBottom: '15px',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.info.dark,
  },
  link: {
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '23px',
    color: theme.palette.text.primary,
    marginBottom: '5px',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

const MoreOn = ({ tickers, interviewId }) => {
  const classes = useStyles();
  const { me } = useMe();
  const location = useLocation();
  const { trackEvent } = useTracking();

  const userId = me.id ? `U${me.id}-` : '';
  const tickerIds = tickers.map((el) => el.ticker.id);

  const [getRelatedInterviews, {
    data: { allTickers = {} } = {},
    loading,
  }] = useLazyQuery(INTERVIEW_RELATED_QUERY, {
    variables: {
      ids: tickerIds,
    },
  });

  useEffect(() => {
    getRelatedInterviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    trackEvent({
      viewName: 'interview:more_on_link',
      path: location.pathname,
      getDict: JSON.stringify(searchToObject(location.search)),
      extraData: JSON.stringify({ interviewId }),
    });
  };

  if (loading) return 'Loading...';

  if (isEmpty(allTickers)) return null;

  return (
    <Box mt="65px" id="moreOn">
      {allTickers.results.map((ticker) => {
        const relatedInterviews = ticker.interviews.results.filter(
          (i) => i.interviewId !== interviewId,
        );

        return (
          relatedInterviews.map((interview, index) => (
            <Box key={interview.id}>
              {index === 0 && (
                <Box className={classes.more}>
                  <Box component="span" className={classes.moreon}>
                    More on
                    {' '}
                    {ticker.title}
                  </Box>
                </Box>
              )}
              <Box
                key={interview.id}
                display="flex"
                flexDirection={{ xs: 'column', md: 'row' }}
                justifyContent="space-between"
                alignItems="start"
                mb="20px"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  mr="20px"
                >
                  <Box>
                    <Link
                      className={classes.link}
                      to={`/${userId}${interview.interviewId}`}
                      onClick={handleClick}
                    >
                      {me.role === 'EXPERT'
                        ? (
                          interview.headline
                        )
                        : (
                          interview.relevantRole
                        )}
                    </Link>
                  </Box>
                  <Box
                    fontSize="14px"
                    fontWeight="bold"
                    lineHeight="18px"
                    color="text.primary"
                  >
                    {interview.perspective}
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  mt="4px"
                >
                  <DateIcon />
                  <Box
                    fontSize="14px"
                    fontWeight="500"
                    lineHeight="18px"
                    color="text.secondary"
                    whiteSpace="nowrap"
                    ml="10px"
                  >
                    {formatDate(interview.publishedAt)}
                  </Box>
                </Box>
              </Box>
            </Box>
          ))
        );
      })}
    </Box>
  );
};

MoreOn.propTypes = {
  tickers: PropTypes.arrayOf(PropTypes.object).isRequired,
  interviewId: PropTypes.string.isRequired,
};

export default MoreOn;
