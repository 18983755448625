import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { SECTORS_OPTIONS_QUERY } from '../../../../../../../../../queries/interview/filterOptions';
import EraAutocompleteInfinite from '../../../../../../../../../components/EraAutocompleteInfinite';

const PAGINATION_FIRST = 20;
const PAGINATION_SKIP = 0;

const SectorsFilter = ({ filterTickers, handleFilterSelection }) => {
  const [
    getAllTickers,
    {
      data: {
        allSectors: {
          totalCount = 0,
          results = [],
        } = {},
      } = {},
      // loading,
      fetchMore,
      refetch,
    }] = useLazyQuery(SECTORS_OPTIONS_QUERY, {
    variables: { first: PAGINATION_FIRST, skip: PAGINATION_SKIP },
  });

  const loadMore = () => {
    fetchMore({
      variables: {
        skip: results.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        if (!prev) {
          return {
            allSectors: fetchMoreResult.allSectors,
          };
        }
        return {
          allSectors: {
            ...prev.allSectors,
            results: [...prev.allSectors.results, ...fetchMoreResult.allSectors.results],
          },
        };
      },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getAllTickers, []);

  const handleInputChange = (event, newInputValue) => {
    if (refetch !== undefined) {
      refetch({ search: newInputValue });
    }
  };

  return (
    <div>
      <EraAutocompleteInfinite
        label="Sectors"
        emptyLabel="All"
        multiple
        limitTags={1}
        loadMore={loadMore}
        options={results}
        totalCount={totalCount}
        value={filterTickers}
        getOptionLabel={(option) => option.title}
        onChange={(event, value) => {
          handleFilterSelection({ sectors: value });
        }}
        getOptionSelected={(option, value) => option.id === value.id}
        disableCloseOnSelect
        onInputChange={handleInputChange}
      />
    </div>
  );
};

SectorsFilter.propTypes = {
  filterTickers: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleFilterSelection: PropTypes.func.isRequired,
};

export default SectorsFilter;
