import React from 'react';
import PropTypes from 'prop-types';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { useMutation } from '@apollo/client';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useLocation } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import { formatDate } from '../../helpers/dateHelpers';
import { COMPLIANCE_REQUEST_ACTION } from '../../queries/interview/interview';
import { searchToObject } from '../../helpers/locationSearchToJson';
import useAllIntervies from '../../hooks/useAllIntervies';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  accessRequestField: {
    borderBottom: '1px solid #E2E9F1',
    paddingBottom: '0.5rem!important',
    marginBottom: '1rem',
    fontFamily: 'PT Root UI',
    width: '100%',
    '&:last-child': {
      border: 'none',
      marginBottom: '0',
    },
  },
  pb_10: {
    // paddingBottom: '10px',
    width: '100%',
  },
  accessRequestMessage: {
    alignItems: 'center!important',
    justifyContent: 'space-between!important',
    flexDirection: 'row!important',
    display: 'flex!important',
    textAlign: 'left!important',
  },
  accessInfo: {
    // marginBottom: '0!important',
    display: 'block',
    textAlign: 'left!important',
    // marginBottom: '1rem!important',
  },
  accessRequestUser: {
    color: '#182341',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '20px',
  },
  mb_1: {
    // marginBottom: '.25rem!important',
  },
  subtext: {
    color: '#8293A6',
    fontWeight: '500',
    fontSize: '16px',
  },
  accessRequestButtonGroup: {
    flexDirection: 'row!important',
    display: 'flex!important',
    boxSizing: 'border-box',
    textAlign: 'left!important',
  },
  approveBttn: {
    backgroundColor: '#1168A7',
    border: '1px solid #E2E9F1',
    color: '#FFFFFF',
    lineHeight: '18px',
    padding: '5px 20px',
    transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    fontWeight: '700!important',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    display: 'inline-block',
    '&:hover': {
      color: '#1168A7',
    },
  },
  declineBttn: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #E2E9F1',
    color: '#182341',
    lineHeight: '18px',
    padding: '5px 20px',
    transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    fontWeight: '700!important',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    display: 'inline-block',
  },
  ml5: {
    marginLeft: '5px',
  },
  requestCount: {
    fontWeight: 'bold',
    color: '#1168A7',
  },
  expPannelColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const AccessRequest = ({
  requestId, interviewId, interviewPK, userName, createdAt,
}) => {
  const { updateQuery: updateAllInterviewsQuery } = useAllIntervies();
  const [requestAction] = useMutation(COMPLIANCE_REQUEST_ACTION, {
    // errorPolicy: 'all',
    update: (cache, { data: { complianceAccessRequest } }) => {
      cache.modify({
        id: `InterviewType:${interviewPK}`,
        fields: {
          accessRequests(existingRequestRefs, { readField }) {
            const refs = existingRequestRefs || [];
            return refs.filter(
              (ref) => readField('id', ref) !== complianceAccessRequest.accessRequest.id,
            );
          },
        },
      });
      updateAllInterviewsQuery((previousQueryResult) => {
        const results = previousQueryResult.allInterviews.results.map((el) => {
          if (el.interviewId === interviewId) {
            const accessRequests = [...el.accessRequests, complianceAccessRequest.accessRequest];
            return { ...el, accessRequests };
          }
          return el;
        });

        return {
          allInterviews: {
            ...previousQueryResult.allInterviews,
            results,
          },
        };
      });
    },
  });
  const classes = useStyles();
  const location = useLocation();
  const { trackEvent } = useTracking();

  const handleApprove = () => {
    requestAction({
      variables: {
        access_request_id: requestId,
        status: 'approved',
      },
    });
    trackEvent({
      viewName: 'interview:request_access_action',
      path: location.pathname,
      getDict: JSON.stringify(searchToObject(location.search)),
      extraData: JSON.stringify({
        interviewId,
        status: 'approved',
      }),
    });
  };

  const handleDecline = () => {
    requestAction({
      variables: {
        access_request_id: requestId,
        status: 'rejected',
      },
    });
    trackEvent({
      viewName: 'interview:request_access_action',
      path: location.pathname,
      getDict: JSON.stringify(searchToObject(location.search)),
      extraData: JSON.stringify({
        interviewId,
        status: 'rejected',
      }),
    });
  };

  return (
    <Box key={requestId} className={classes.accessRequestField}>
      <Box className={classes.pb_10}>
        <Box className={classes.accessRequestMessage}>
          <Box className={classes.accessInfo}>
            <Box className={`${classes.accessRequestUser} ${classes.mb_1}`}>{userName}</Box>
            <Box className={classes.accessRequestUser}>
              <span className={classes.subtext}>Requested on: </span>
              {formatDate(createdAt)}
            </Box>
          </Box>
          <Box className={classes.accessRequestButtonGroup}>
            <Button onClick={handleApprove} className={classes.approveBttn}>Approve</Button>
            <Button onClick={handleDecline} className={`${classes.declineBttn} ${classes.ml5}`}>Decline</Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

AccessRequest.propTypes = {
  requestId: PropTypes.string.isRequired,
  interviewId: PropTypes.string.isRequired,
  interviewPK: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  // status: PropTypes.string.isRequired,
};

const numOfPending = (requests) => {
  let num = 0;

  if (requests) {
    for (let i = 0; i < requests.length; i += 1) {
      if (requests[i].status === 'PENDING') {
        num += 1;
      }
    }
  }
  return (num);
};

const ComplianceAccessRequests = ({ accessRequests }) => {
  const classes = useStyles();

  return (
    <Box mb="15px">
      <ExpansionPanel className={classes.root} defaultExpanded>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            Access Request
            <span className={`request-count ${classes.ml5} ${classes.requestCount}`}>
              (
              {numOfPending(accessRequests)}
              )
            </span>
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.expPannelColumn}>
          {(accessRequests && accessRequests.length > 0)
            ? (
              accessRequests.map((ar) => (
                ar.status === 'PENDING'
                  && (
                  <AccessRequest
                    key={ar.id}
                    requestId={ar.id}
                    createdAt={ar.createdAt}
                    interviewId={ar.interview.interviewId}
                    interviewPK={ar.interview.id}
                    userName={ar.user.realFullName}
                    status={ar.status}
                  />
                  )
              ))
            )
            : <div className="theme-grey mb-10px">No pending requests.</div>}
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <Divider />
    </Box>
  );
};

ComplianceAccessRequests.defaultProps = {
  accessRequests: [],
};

ComplianceAccessRequests.propTypes = {
  accessRequests: PropTypes.arrayOf(PropTypes.object),
};

export default ComplianceAccessRequests;
