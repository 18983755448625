// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/app';
import React, { useEffect, useState } from 'react';
import './wdyr';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from '@material-ui/styles';
import {
  ApolloClient, ApolloProvider, from, InMemoryCache, useMutation,
} from '@apollo/client';
import { SingletonHooksContainer } from 'react-singleton-hook';
import { Provider, useSelector, useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { setContext } from '@apollo/link-context';
import { onError } from '@apollo/client/link/error';
import { IntercomProvider } from 'react-use-intercom';
import track from 'react-tracking';
import { SnackbarProvider } from 'notistack';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns'; // choose your lib
import { LocalizationProvider } from '@material-ui/pickers';
import { createUploadLink } from 'apollo-upload-client';
import * as Sentry from '@sentry/react';
import { SentryLink } from 'apollo-link-sentry';
import { datadogRum } from '@datadog/browser-rum';
import jwtDecode from 'jwt-decode';
import theme from './theme';
import './assets/scss/index.scss';
import { store, history } from './store';
import Routes from './Routes';
import { tokenName } from './config';
import { TRACK_ACTION_MUTATION } from './queries/auditlog';
import SentryFallback from './components/SentryFallback/SentryFallback';
import Integrations from './components/Integrations';
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics';
import OptimizelyHOC from './hocs/optimizelyProviderHoc';
import useMe from './hooks/useMe';
import { FILTER_INIT_VALUES } from './constants/filterInitValues';
import { SAVE_USER_SESSION_FILTERS } from './queries/interview/interview';
import useAllIntervies from './hooks/useAllIntervies';

import { writeFilterData } from './actions/filterActions';

const SavedFiltersWraper = ({ children }) => {
  const [filtersHasBeenSet, setFiltersHasBeenSet] = useState(false);
  const filterData = useSelector((state) => state.filterState.filterData, isEqual);
  const { me } = useMe();
  const dispatch = useDispatch();
  const [saveUserSessionFiltersCache] = useMutation(SAVE_USER_SESSION_FILTERS);
  const handleWriteFilterData = (data) => dispatch(writeFilterData(data));
  const { refetch } = useAllIntervies();
  const [meCachedFilters, setMeCachedFilters] = useState({});

  const isFiltersEmpty = JSON.stringify(filterData) === JSON.stringify(FILTER_INIT_VALUES);

  useEffect(() => {
    if (isEmpty(me.cachedFilters)) {
      return;
    }
    const cachedFilters = JSON.parse(me.cachedFilters);
    setMeCachedFilters({ ...cachedFilters, readingLists: [] });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(me)) {
      saveUserSessionFiltersCache({
        variables: {
          filterData: JSON.stringify(filterData),
        },
      });
      refetch();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  useEffect(() => {
    if (!isEmpty(meCachedFilters) && isFiltersEmpty && !filtersHasBeenSet) {
      handleWriteFilterData(meCachedFilters);
      setFiltersHasBeenSet(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me.cachedFilters]);

  return children;
};

const firebaseConfig = {
  apiKey: 'AIzaSyAx_u4TWhHwsp-4kfkYgTA4LBwX299SHRI',
  authDomain: 'stream-b57c5.firebaseapp.com',
  projectId: 'stream-b57c5',
  storageBucket: 'stream-b57c5.appspot.com',
  messagingSenderId: '101906532733',
  appId: '1:101906532733:web:477bb6c96632989c45a414',
  measurementId: 'G-8Z2DX0KBXC',
};

firebase.initializeApp(firebaseConfig);

window.dataLayer = window.dataLayer || [];

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(tokenName) || '';
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : '',
    },
  };
});

// eslint-disable-next-line no-unused-vars
const errorLink = onError(({ graphQLErrors, networkError }) => {
  let decoded;
  try {
    decoded = jwtDecode(localStorage.getItem(tokenName));
  } catch (e) {
    decoded = 'anonymous';
  }

  if (graphQLErrors) {
    const criticalErrors = [
      'Error decoding signature',
      'Signature has expired',
      'User is disabled',
      'Unauthorized',
    ];
    // eslint-disable-next-line no-unused-vars
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (criticalErrors.includes(message)) {
        window.localStorage.clear();
        const locationParams = `${window.location.pathname}${window.location.search}`;
        window.location.href = `/accounts/login/?next=${locationParams}`;
      }
    });
    Sentry.captureException(JSON.stringify({ email: decoded.email, graphQLErrors }));
  }
  if (!isEmpty(networkError)) {
    Sentry.captureException(JSON.stringify({ email: decoded.email, networkError }));
  }
});

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_APOLLO_CLIENT_URI,
});

const sentryLink = new SentryLink();

const cache = new InMemoryCache();

const client = new ApolloClient({
  cache,
  link: from([sentryLink, authLink, errorLink, uploadLink]),
  resolvers: {},
});

let prevPath = null;

// listen and notify Segment of client-side page updates
history.listen((location) => {
  if (location.pathname !== prevPath) {
    prevPath = location.pathname;
    window.analytics.page();
  }
});

datadogRum.init({
  applicationId: 'f523b280-0a63-4a3d-b44a-91611369767a',
  clientToken: 'pub11a49f401166321ce6ad2158665e0b13',
  site: 'datadoghq.com',
  service: 'stream-react',
  env: process.env.REACT_APP_TARGET,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: 100,
  trackInteractions: true,
});

const App = () => (
  <Provider store={store}>
    <ApolloProvider client={client}>
      <LocalizationProvider dateAdapter={DateFnsAdapter}>
        <ThemeProvider theme={theme}>
          <Sentry.ErrorBoundary fallback={SentryFallback}>
            <ConnectedRouter history={history}>
              <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <SavedFiltersWraper>
                  <OptimizelyHOC>
                    <IntercomProvider appId="pkeyx02n" initializeDelay={5000}>
                      <SingletonHooksContainer />
                      <Integrations />
                      <Routes />
                    </IntercomProvider>
                  </OptimizelyHOC>
                </SavedFiltersWraper>
              </SnackbarProvider>
            </ConnectedRouter>
          </Sentry.ErrorBoundary>
        </ThemeProvider>
      </LocalizationProvider>
    </ApolloProvider>
  </Provider>
);

export default track(
  // app-level tracking data
  { token: localStorage.getItem(tokenName) || '' },

  // top-level options
  {
    // custom dispatch to console.log in addition to pushing to dataLayer[]
    dispatch: (data) => {
      client.mutate({
        mutation: TRACK_ACTION_MUTATION,
        variables: { input: { ...data } },
      });
    },
  },
)(Sentry.withProfiler(App));
