import React, { useReducer, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import EditIcon from '@material-ui/icons/Edit';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsOffOutlinedIcon from '@material-ui/icons/NotificationsOffOutlined';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PeriodMenu from './PeriodMenu';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: '10px',
    '& .MuiPaper-root': {
      borderRadius: '5px',
      boxShadow: 'none',
      border: '1px solid',
      padding: '1px',
      borderColor: theme.palette.info.main,
      '& .MuiButtonBase-root': {
        padding: '0',
        minHeight: '26px',
        '& .MuiAccordionSummary-content': {
          alignItems: 'center',
          margin: '0',
          overflow: 'hidden',
          minHeight: '26px',
        },
      },
      '&:hover': {
        borderColor: theme.palette.info.dark,
      },
      '&.Mui-expanded': {
        borderColor: theme.palette.primary.main,
        '& span svg': {
          display: 'block',
        },
      },
    },
    '&:hover span svg': {
      display: 'block',
    },
    '& .MuiPaper-root.active': {
      border: '2px solid',
      borderColor: theme.palette.primary.main,
      padding: '0',
      '&:hover': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  headingBox: {
    flexGrow: '1',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
  },
  heading: {
    width: '100%',
    padding: '4px 6px 4px 10px',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  badge: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '14px',
    padding: '2px 10px',
    borderRadius: '9px',
    marginRight: '2px',
  },
  editBtn: {
    width: '26px',
    minWidth: '26px',
  },
  edit: {
    display: 'block',
    width: '18px',
    height: '26px',
    cursor: 'pointer',
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  notification: {
    '& svg': {
      width: '18px',
      height: '26px',
      marginRight: '10px',
      marginLeft: '2px',
    },
  },
  details: {
    flexDirection: 'column',
    alignItems: 'start',
    margin: '0 10px',
    padding: '10px 0 0',
    borderTop: '1px solid',
    borderTopColor: theme.palette.info.main,
  },
  secondaryHeading: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    whiteSpace: 'wrap',
    marginBottom: '10px',
  },
  checkbox: {
    margin: '0 0 10px',
    '& .MuiTypography-root': {
      fontSize: '13px',
      fontWeight: '500',
      lineHeight: '16px',
      color: theme.palette.text.secondary,
    },
    '& .MuiButtonBase-root': {
      minHeight: '18px!important',
      padding: '0 5px!important',
      '& .MuiSvgIcon-root': {
        width: '20px',
        height: '20px',
        '& path': {
          color: theme.palette.info.main,
        },
      },
      '&:hover': {
        backgroundColor: theme.palette.common.white,
        '& .MuiSvgIcon-root path': {
          color: theme.palette.info.dark,
        },
      },
      '&.Mui-checked': {
        '& .MuiSvgIcon-root path': {
          color: theme.palette.primary.main,
        },
        '&:hover .MuiSvgIcon-root path': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  actions: {
    padding: '10px',
  },
  cancelBtn: {
    cursor: 'pointer!important',
    minWidth: '40px',
    flexGrow: '1',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.common.white,
    border: '1px solid',
    borderColor: theme.palette.info.main,
    borderRadius: '5px',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.text.primary,
      borderColor: theme.palette.info.dark,
      backgroundColor: theme.palette.common.white,
    },
    '&:active': {
      borderColor: theme.palette.info.main,
      backgroundColor: theme.palette.common.white,
    },
  },
  deleteBtn: {
    cursor: 'pointer!important',
    minWidth: '40px',
    flexGrow: '1',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.warning.main,
    border: '1px solid',
    borderColor: theme.palette.warning.main,
    borderRadius: '5px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
      borderColor: theme.palette.warning.dark,
    },
    '&:active': {
      backgroundColor: theme.palette.warning.light,
      borderColor: theme.palette.warning.light,
    },
  },
  saveBtn: {
    cursor: 'pointer!important',
    minWidth: '40px',
    flexGrow: '1',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '5px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      borderColor: theme.palette.primary.dark,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
    },
  },
}));

const FollowingItem = ({
  itemName, unreadCount, onDelete, onUpdate, onClick, initials, isItemActive, ...rest
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const [settings, setSettings] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initials,
  );

  const handleItemClick = (event) => {
    event.stopPropagation();
    onClick();
  };

  const handleSettingsAlertChange = (event) => {
    setSettings({ ...settings, [event.target.name]: event.target.checked });
  };

  const handleFrequency = (value) => {
    setSettings({ ...settings, notify: value });
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSave = () => {
    onUpdate(settings);
  };

  const handleCancel = () => {
    setExpanded(false);
    setSettings({ ...settings, ...initials });
  };

  useEffect(() => {
    setExpanded(false);
  }, [initials]);

  return (
    <Box className={classes.root} {...rest}>
      <Accordion
        expanded={expanded}
        onChange={handleChange('panel1')}
        className={isItemActive && 'active'}
      >
        <AccordionSummary
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Box
            className={classes.headingBox}
            onClick={handleItemClick}
          >
            <Box className={classes.heading}>
              {itemName}
            </Box>
          </Box>
          <Box
            component="span"
            ml={{ xs: '5px', md: '0' }}
            className={classes.editBtn}
          >
            <EditIcon
              color="primary"
              className={classes.edit}
            />
          </Box>
          {unreadCount > 0
          && (
          <Box
            component="span"
            className={classes.badge}
          >
            {unreadCount}
          </Box>
          )}
          <Box
            display="flex"
            alignItems="center"
            className={classes.notification}
          >
            {initials.emailAlerts
              ? (
                <NotificationsNoneIcon
                  color="primary"
                />
              )
              : (
                <NotificationsOffOutlinedIcon
                  color="primary"
                />
              )}
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Box className={classes.secondaryHeading}>{itemName}</Box>
          <FormControlLabel
            labelPlacement="start"
            label="Enable email alerts"
            className={classes.checkbox}
            control={(
              <Checkbox
                checked={settings.emailAlerts}
                onChange={handleSettingsAlertChange}
                name="emailAlerts"
                color="primary"
                disableRipple
              />
            )}
          />
          { !settings.hidePrimaryTickerAlerts
            ? (
              <FormControlLabel
                labelPlacement="start"
                label="Primary ticker alert only"
                className={classes.checkbox}
                control={(
                  <Checkbox
                    checked={settings.emailAlerts ? settings.onlyPrimaryTickerAlerts : false}
                    onChange={handleSettingsAlertChange}
                    name="onlyPrimaryTickerAlerts"
                    color="primary"
                    disableRipple
                  />
                )}
              />
            ) : null }
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Box
              fontSize="13px"
              fontWeight="500"
              lineHeight="16px"
              color={settings.emailAlerts ? 'text.secondary' : 'info.main'}
            >
              Email Frequency
            </Box>
            <PeriodMenu
              onChange={handleFrequency}
              disabled={!settings.emailAlerts}
              frequency={settings.notify}
            />
          </Box>
        </AccordionDetails>
        <AccordionActions className={classes.actions}>
          <Button
            size="small"
            color="inherit"
            disableRipple
            onClick={handleCancel}
            className={classes.cancelBtn}
          >
            Cancel
          </Button>
          <Button
            size="small"
            color="inherit"
            disableRipple
            onClick={onDelete}
            className={classes.deleteBtn}
          >
            Delete
          </Button>
          <Button
            size="small"
            color="inherit"
            disableRipple
            className={classes.saveBtn}
            onClick={handleSave}
          >
            Save
          </Button>
        </AccordionActions>
      </Accordion>
    </Box>
  );
};

FollowingItem.defaultProps = {
  onDelete: () => {},
  unreadCount: 0,
};

FollowingItem.propTypes = {
  itemName: PropTypes.string.isRequired,
  unreadCount: PropTypes.number,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  initials: PropTypes.shape({
    notify: PropTypes.string,
    emailAlerts: PropTypes.bool,
    onlyPrimaryTickerAlerts: PropTypes.bool,
    // hidePrimaryTickerAlters: PropTypes.bool
  }).isRequired,
  isItemActive: PropTypes.bool.isRequired,
};

export default FollowingItem;
