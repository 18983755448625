import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Field, Form } from 'react-final-form';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useMutation } from '@apollo/client';
import { INTERVIEW_QUERY, ADD_COMPLIANCE_OFFICER_MUTATION } from '../../queries/interview/interview';
import { ME_QUERY } from '../../queries/account';
import { ReactComponent as SearchTextIcon } from '../../assets/icons/searchTextIcon.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/closeIcon.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid',
    borderColor: theme.palette.info.main,
    borderRadius: '5px',
    backgroundColor: theme.palette.background.paper,
    padding: '20px',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '10px',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      marginBottom: '0',
      marginRight: '10px',
    },
  },
  logo: {
    width: '48px',
    minWidth: '48px',
    height: '48px',
    backgroundColor: theme.palette.info.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    marginBottom: '10px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '0',
      marginRight: '10px',
    },
  },
  text: {
    width: '80%',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '21px',
    color: theme.palette.text.primary,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      width: '100%',
      textAlign: 'left',
      marginBottom: '0',
      marginRight: '10px',
    },
  },
  sendBtn: {
    height: '40px',
    backgroundColor: theme.palette.info.main,
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    padding: '11px 20px',
    borderRadius: '5px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },
  },
  modalBody: {
    padding: '20px',
    position: 'relative',
    flex: '1 1 auto',
  },
  p0: {
    padding: '0',
  },
  closeBtn: {
    minWidth: '18px',
    position: 'absolute',
    right: '10px',
    top: '10px',
    padding: '5px',
  },
  modalTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '26px',
    color: theme.palette.text.primary,
    textAlign: 'center',
    margin: '0 15px',
  },
  formContent: {
    padding: '0',
  },
  inputWrap: {
    marginBottom: '15px',
    flexWrap: 'wrap',
    display: 'flex',
  },
  inputLabel: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    display: 'inline-block',
    marginBottom: '5px',
  },
  inputField: {
    display: 'block',
    width: '100%',
    border: '1px solid',
    borderColor: theme.palette.info.main,
    borderRadius: '5px',
    padding: '5px 10px',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    height: '40px',
    outline: 'none',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
  },
  submitBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: '100%',
    height: '40px',
    borderRadius: '5px',
    display: 'inline-block',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
  },
  buttonProgress: {
    color: theme.palette.info.dark,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const AddComplianceOfficer = ({ interviewId }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [addOfficer, {
    loading,
  }] = useMutation(ADD_COMPLIANCE_OFFICER_MUTATION, {
    errorPolicy: 'all',
    refetchQueries: [
      { query: INTERVIEW_QUERY, variables: { interviewId } },
      { query: ME_QUERY },
    ],
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (values) => {
    addOfficer({
      variables: {
        interviewId,
        ...values,
      },
    });
    handleClose();
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.leftSide}>
        <Box className={classes.logo}>
          <SearchTextIcon />
        </Box>
        <Box className={classes.text}>
          Need a Compliance Review? Send this interview to your Compliance Officer
        </Box>
      </Box>
      <Button
        onClick={handleClickOpen}
        className={classes.sendBtn}
        disableRipple
      >
        Send
      </Button>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <Box className={classes.modalBody}>
          <DialogTitle id="form-dialog-title" className={classes.p0}>
            <Button
              onClick={handleClose}
              data-dismiss="modal"
              aria-label="Close"
              className={classes.closeBtn}
              disableRipple
            >
              <CloseIcon />
            </Button>
            <Box className={classes.modalTitle}>
              Send to your Compliance Officer
            </Box>
          </DialogTitle>
          <Form
            onSubmit={onSubmit}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = 'Required';
              }
              return errors;
            }}
            render={({
              handleSubmit,
              submitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <DialogContent className={classes.formContent}>
                  <Field name="email">
                    {({ input, meta }) => (
                      <Box className={classes.inputWrap}>
                        <Box component="div" className={classes.inputLabel}>
                          <label htmlFor="id_email">Compliance Officer&apos;s Email:</label>
                        </Box>
                        <TextField
                          {...input}
                          type="email"
                          id="id_email"
                          className={classes.inputField}
                          InputProps={{ disableUnderline: true }}
                          fullWidth
                        />
                        {(meta.error || meta.submitError) && meta.touched && (
                          <Box
                            component="span"
                            color="error.main"
                          >
                            {meta.error || meta.submitError}
                          </Box>
                        )}
                      </Box>
                    )}
                  </Field>
                  <Field name="first_name">
                    {({ input, meta }) => (
                      <Box className={classes.inputWrap}>
                        <Box component="div" className={classes.inputLabel}>
                          <label htmlFor="id_first_name">Compliance Officer&apos;s First Name:</label>
                        </Box>
                        <TextField
                          {...input}
                          type="first_name"
                          id="id_first_name"
                          className={classes.inputField}
                          InputProps={{ disableUnderline: true }}
                          fullWidth
                        />
                        {(meta.error || meta.submitError) && meta.touched && (
                          <Box
                            component="span"
                            color="error.main"
                          >
                            {meta.error || meta.submitError}
                          </Box>
                        )}
                      </Box>
                    )}
                  </Field>
                  <Field name="last_name">
                    {({ input, meta }) => (
                      <Box className={classes.inputWrap}>
                        <Box component="div" className={classes.inputLabel}>
                          <label htmlFor="id_last_name">Compliance Officer&apos;s Last Name:</label>
                        </Box>
                        <TextField
                          {...input}
                          type="last_name"
                          id="id_last_name"
                          className={classes.inputField}
                          InputProps={{ disableUnderline: true }}
                          fullWidth
                        />
                        {(meta.error || meta.submitError) && meta.touched && (
                          <Box
                            component="span"
                            color="error.main"
                          >
                            {meta.error || meta.submitError}
                          </Box>
                        )}
                      </Box>
                    )}
                  </Field>
                  <DialogActions class={classes.p0}>
                    <Button
                      className={classes.submitBtn}
                      color="primary"
                      type="submit"
                      disabled={submitting}
                      disableRipple
                    >
                      Send
                    </Button>
                    {loading
                    && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </DialogActions>
                </DialogContent>
              </form>
            )}
          />
        </Box>
      </Dialog>
    </Box>
  );
};

AddComplianceOfficer.propTypes = {
  interviewId: PropTypes.string.isRequired,
};

export default AddComplianceOfficer;
