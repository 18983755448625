import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import RequestNewContentDialog from './RequestNewContentDialog';

const useStyles = makeStyles((theme) => ({
  searchSectionTitle: {
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    textAlign: 'center',
    marginBottom: '16px',
  },
  noMatchTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '20px',
    color: theme.palette.text.primary,
    textAlign: 'center',
    marginBottom: '16px',
  },
  noMatchSubtitle: {
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    textAlign: 'center',
    marginBottom: '16px',
  },
  noMatchText: {
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    textAlign: 'center',
  },
  requestBtn: {
    lineHeight: '18px',
    backgroundColor: theme.palette.primary.main,
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginBottom: '16px',
    padding: '4px 20px',
    borderRadius: '5px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
    '&:active': {
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
}));
const PrimaryTickerSearchResultsSection = ({ primaryCount }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box mb="10px">
      <Box className={classes.searchSectionTitle}>
        Primary ticker
        {primaryCount > 0 && ` (${primaryCount})`}
      </Box>
      {primaryCount === 0
      && (
        <>
          <Box className={classes.noMatchTitle}>No primary ticker matches</Box>
          <Box className={classes.noMatchSubtitle}>
            Let us know if you want to create interviews on this ticker/company.
          </Box>
          <Box textAlign="center">
            <Button
              onClick={handleOpen}
              disableRipple
              className={classes.requestBtn}
            >
              Request new content
            </Button>
          </Box>
          <Box className={classes.noMatchText}>
            included as part of your subscription
          </Box>
          <RequestNewContentDialog handleOpen={open} handleClose={handleClose} />
        </>
      )}
    </Box>
  );
};

PrimaryTickerSearchResultsSection.propTypes = {
  primaryCount: PropTypes.number.isRequired,
};

export default PrimaryTickerSearchResultsSection;
