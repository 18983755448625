import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Fade from '@material-ui/core/Fade';
import { useMutation, useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import {
  ALL_FOLLOWING_COMPANIES_QUERY,
  DELETE_COMPANY_FOLLOWING,
  UPDATE_COMPANY_MUTATION,
} from '../../../../../../../../../../queries/interview/following';
import useMe from '../../../../../../../../../../hooks/useMe';
import FollowingItem from '../FollowingItem';
import ItemsSkeleton from '../../../ItemsSkeleton';
import FollowingPlaceholder from '../FollowingPlaceholder';
import FollowingItemsSection from '../FollowingItemsSection';
import TickerSelect from '../TickerSelect';

const Accordion = withStyles(() => ({
  root: {
    borderBottom: '1px solid #EAEEF4',
    boxShadow: 'none',
    '&:last-child': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles(() => ({
  root: {
    minHeight: '30px',
    padding: '0 20px',
    '&$expanded': {
      minHeight: '30px',
    },
  },
  content: {
    margin: '6px 0',
    '&$expanded': {
      margin: '6px 0',
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    padding: '5px 20px',
    overflow: 'auto',
    height: 'calc(100vh - 180px)',
    '&.showTrialBanner': {
      height: 'calc(100vh - 217px)',
    },
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  uploadBtn: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    padding: '8px 21px',
    marginTop: '8px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '5px',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.common.white,
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.dark,
    },
    '&:active': {
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const FollowingCompanies = ({
  handleFilterSelection, handleExpandedAccordion, expandedAccordion,
  ariaControls, accordionId, sectionTitle, trackingClass, setOpen,
  showTrialBanner,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { me } = useMe();
  const filterData = useSelector((state) => state.filterState.filterData, isEqual);

  const {
    data: {
      allCompanyWatchList = [],
    } = {},
    loading,
    updateQuery,
    refetch,
  } = useQuery(ALL_FOLLOWING_COMPANIES_QUERY);

  const refetchFunc = () => {
    refetch();
  };

  const [deleteCompany] = useMutation(DELETE_COMPANY_FOLLOWING, {
    refetchQueries: [{ query: ALL_FOLLOWING_COMPANIES_QUERY }],
  });

  const [updateCompany] = useMutation(UPDATE_COMPANY_MUTATION, {
    refetchQueries: [{ query: ALL_FOLLOWING_COMPANIES_QUERY }],
  });

  const handleCompanyDelete = (followingId) => {
    deleteCompany({ variables: { followingId } });
  };

  const handleCompanyUpdate = (followingId, companyId) => (params) => {
    updateCompany({
      variables: {
        id: followingId,
        company:
          companyId,
        ...params,
      },
    }).then(({ data: { updateCompanyMutation = {} } = {} }) => {
      if (!isEmpty(updateCompanyMutation.errors)) {
        enqueueSnackbar(
          updateCompanyMutation.errors.map((err) => (
            `${err.field}: ${err.messages.join(', ')}`
          )),
          { variant: 'error', autoHideDuration: 4000 },
        );
      } else {
        enqueueSnackbar(
          'Saved.',
          { variant: 'success', autoHideDuration: 4000 },
        );
      }
    });
  };

  const isCompanyActive = (tickers) => {
    const ids = tickers.map((el) => el.id);
    const active = filterData.tickers?.map((el) => el.id);

    const res = ids.filter((el) => {
      if (active.includes(el)) {
        return true;
      }
      return false;
    });

    if (res.length > 0) {
      return true;
    }
    return false;
  };

  const handleClick = ({ company, onlyPrimaryTickerAlerts }) => () => {
    handleFilterSelection({
      tickers: company?.tickers,
      isPrimaryTicker: onlyPrimaryTickerAlerts,
    });
    updateQuery((previousQueryResult) => {
      const result = previousQueryResult.allCompanyWatchList.map((el) => {
        if (el.company.id === company.id) {
          return { ...el, notSeenInterviewsCount: 0 };
        }
        return el;
      });
      return {
        allCompanyWatchList: result,
      };
    });
  };

  return (
    <Accordion expanded={expandedAccordion} onChange={handleExpandedAccordion}>
      <AccordionSummary aria-controls={ariaControls} id={accordionId}>
        <FollowingItemsSection
          sectionTitle={sectionTitle}
          expanded={expandedAccordion}
          totalCount={allCompanyWatchList?.length}
          unreadCount={me.companyWatchlistUnreadCount}
        />
      </AccordionSummary>
      <AccordionDetails className={showTrialBanner && 'showTrialBanner'}>
        <Box className={trackingClass} width="100%">
          <TickerSelect />
          <Fade in={loading}>
            <Box>
              {loading && <ItemsSkeleton />}
            </Box>
          </Fade>
          <Fade in={!loading}>
            <Box overflow="auto">
              {allCompanyWatchList.length > 0
                ? allCompanyWatchList?.map((item) => (
                  <FollowingItem
                    key={item.id}
                    // data-xlink-company-id={item.ticker.uuid}
                    itemName={item.company.title}
                    isItemActive={isCompanyActive(item.company.tickers)}
                    unreadCount={item.notSeenInterviewsCount}
                    onDelete={() => handleCompanyDelete(item.id)}
                    onUpdate={handleCompanyUpdate(item.id, item.company.id)}
                    onClick={handleClick({
                      company: item.company,
                      onlyPrimaryTickerAlerts: item.onlyPrimaryTickerAlerts,
                    })}
                    initials={{
                      notify: item.notify,
                      emailAlerts: item.emailAlerts,
                      onlyPrimaryTickerAlerts: item.onlyPrimaryTickerAlerts,
                    }}
                  />
                ))
                : (
                  <FollowingPlaceholder
                    content={(
                      <Button
                        onClick={() => { setOpen(true); }}
                        className={classes.uploadBtn}
                        refetchFollowing={refetchFunc}
                        disableRipple
                      >
                        Upload Your Watchlist
                      </Button>
                    )}
                  >
                    Add companies to your watchlist to know first about new interviews
                  </FollowingPlaceholder>
                )}
            </Box>
          </Fade>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

FollowingCompanies.propTypes = {
  // filterDataTickers: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleFilterSelection: PropTypes.func.isRequired,
  handleExpandedAccordion: PropTypes.func.isRequired,
  expandedAccordion: PropTypes.bool.isRequired,
  ariaControls: PropTypes.string.isRequired,
  accordionId: PropTypes.string.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  trackingClass: PropTypes.string.isRequired,
  showTrialBanner: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default FollowingCompanies;
