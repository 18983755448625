import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import { useMutation } from '@apollo/client';
import { Button } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import { useSnackbar } from 'notistack';
import { UPGRADE_NOW_MUTATION } from '../../queries/interview/interview';
import useMe from '../../hooks/useMe';

const useStyles = makeStyles((theme) => ({
  message: {
    padding: '20px',
    border: '1px solid',
    borderColor: theme.palette.info.main,
    borderRadius: '5px',
    margin: '10px 0 20px',
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '32px',
  },
  list: {
    paddingLeft: '1.3rem',
  },
  postBox: {
    padding: '20px',
    margin: '20px 0',
    backgroundColor: theme.palette.info.main,
    color: theme.palette.text.primary,
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '20px',
    borderRadius: '5px',
    [theme.breakpoints.up('md')]: {
      padding: '30px 20px',
      fontSize: '20px',
      lineHeight: '25px',
    },
  },
  signUp: {
    display: 'inline-block',
    height: '40px',
    padding: '11px 20px',
    color: theme.palette.common.white,
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    backgroundColor: theme.palette.primary.main,
    textTransform: 'capitalize',
    borderRadius: '5px',
    '&:hover': {
      color: theme.palette.common.white,
      borderColor: theme.palette.primary.dark,
    },
    '&:active': {
      color: theme.palette.common.white,
      borderColor: theme.palette.primary.light,
    },
  },
}));

const TrialBanner = () => {
  const classes = useStyles();
  const [upgradeNowFunc, { loading, called }] = useMutation(UPGRADE_NOW_MUTATION);
  const { enqueueSnackbar } = useSnackbar();

  const { me, refetch: refetchMe } = useMe();

  const handleUpgradeClick = () => {
    if (loading || called || me.upgradeRequestedDate) return null;

    upgradeNowFunc().then(({ data: { upgradeNow = {} } = {} }) => {
      if (!isEmpty(upgradeNow.errors)) {
        enqueueSnackbar(
          upgradeNow.errors.map((err) => (
            `${err.field}: ${err.messages.join(', ')}`
          )),
          { variant: 'error', autoHideDuration: 4000 },
        );
      } else {
        enqueueSnackbar(
          'Upgrade request sent. We will reach out to you soon!',
          { variant: 'success', autoHideDuration: 4000 },
        );
      }
      refetchMe();
    });
  };
  return (
    <Box>
      <Box className={classes.message}>
        <Box mb="20px">Nice job, you’ve read the first 1/10 of this interview.</Box>
        <Box mb="20px">
          Become a paid Stream member to read or listen to the
          full interview.
        </Box>
        <Box>
          Upgrade now to finish this interview, and have access
          to these additional paid membership features:
        </Box>
        <ul className={classes.list}>
          <li>Audio of all interviews</li>
          <li>
            Full text and audio of 70+ new interviews per week with
            experts in the TMT, healthcare, consumer and industrials sectors
          </li>
          <li>Smart email alerts</li>
          <li>Full text search and filtering</li>
          <li>Daily email digest of top content</li>
          <li>Phone access to Stream analysts and experts</li>
        </ul>
      </Box>
      <Box className={classes.postBox}>
        {me.upgradeRequestedDate
          ? <Box>Upgrade request sent. We will reach out to you soon!</Box>
          : (
            <>
              <Box mb="20px">Stream members have access to full text and audio of all interviews.</Box>
              <Button
                disableRipple
                className={classes.signUp}
                onClick={handleUpgradeClick}
              >
                Upgrade Now
              </Button>
            </>
          )}
      </Box>
    </Box>
  );
};

export default TrialBanner;
