import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Player } from '../../../../../components/InterviewPreviewComponents';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: '16px',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    '& a': {
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.dark,
      },
      '&:active': {
        color: theme.palette.primary.light,
      },
    },
  },
  disabled: {
    '& .plyr.plyr--audio': {
      pointerEvents: 'none',
      cursor: 'default',
    },
  },
}));

const InterviewPlayer = ({
  hasPaidAccess, userCanViewInterview, audio, playerId,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePlayerClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const popoverOpen = Boolean(anchorEl);
  const popoverId = popoverOpen ? 'simple-popover' : undefined;
  return (
    (!hasPaidAccess || !userCanViewInterview)
      ? (
        <>
          <Box
            aria-describedby={popoverId}
            className={classes.disabled}
            onClick={handlePlayerClick}
          >
            <Player url="" playerId={playerId} />
          </Box>
          <Popover
            id={popoverId}
            open={popoverOpen}
            onClose={handlePopoverClose}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Box className={classes.typography}>
              <Box>Full audio is available to paying members of Stream.</Box>
              <Box>
                <Link to="/accounts/subscribe">Click here</Link>
                {' '}
                to request free trial access.
              </Box>
            </Box>
          </Popover>
        </>
      )
      : (
        <Box className={clsx({
          [classes.disabled]: !userCanViewInterview,
        })}
        >
          <Player url={audio} playerId={playerId} />
        </Box>
      )
  );
};

InterviewPlayer.defaultProps = {
  hasPaidAccess: false,
  userCanViewInterview: false,
  audio: '',
  playerId: '',
};

InterviewPlayer.propTypes = {
  hasPaidAccess: PropTypes.bool,
  userCanViewInterview: PropTypes.bool,
  audio: PropTypes.string,
  playerId: PropTypes.string,
};

export default InterviewPlayer;
