import RoutePath from './utils/RoutePath';

export default {
  interviewPublicView: new RoutePath('/i/:hashid'),
  interviewPublicView2: new RoutePath('/interview/:interviewHash'),
  createAccountView: new RoutePath(['/start', '/accounts/create-account']),
  subscribeView: new RoutePath('/accounts/subscribe'),
  logInView: new RoutePath('/accounts/login'),
  firstLoginView: new RoutePath('/accounts/first-login/:hashStr'),
  resetPasswordView: new RoutePath('/accounts/password-reset'),
  resetPasswordCompleteView: new RoutePath('/accounts/password-reset-complete'),
  setPasswordView: new RoutePath('/accounts/reset/:uidb/:token'),
  interviewGuestsView: new RoutePath('/f/:hashid'),
  termsAndConditionsView: new RoutePath('/terms-and-conditions'),
  privacyPolicyView: new RoutePath('/privacy-policy'),
  redirectToASView: new RoutePath('/redirect-to-AS'),
  notFoundView: new RoutePath('/not-found'),
  discoveryPageView: new RoutePath('/discovery'),
  savedFiltersView: new RoutePath('/sf/:hashid'),
  sidebarTabsInitialView: new RoutePath('/tab/:tabname'),
  profileView: new RoutePath('/accounts/profile'),
  interviewFullscreenView: new RoutePath('/:interviewId/fullscreen'),
  interviewRouteView: new RoutePath('/:interviewId'),
  interviewView: new RoutePath('/'),
};
