import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import { Link as Anchor } from 'react-scroll';

const useStyles = makeStyles((theme) => ({
  trialBanner: {
    display: 'none',
    position: 'fixed',
    bottom: '10px',
    flexDirection: 'column',
    padding: '20px !important',
    margin: '0 auto',
    width: 'calc(100% - 40px)',
    maxWidth: '365px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '5px',
    textAlign: 'center',
  },
  bannerTitle: {
    fontSize: '20px',
    lineHeight: '32px',
    fontWeight: 'bold',
    color: theme.palette.common.white,
    textAlign: 'center',
    marginBottom: '10px',
  },
  bannerSubtitle: {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: '500',
    color: theme.palette.common.white,
    textAlign: 'center',
    marginBottom: '10px',
  },
  bannerBtn: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.warning.main,
    border: '1px solid',
    borderColor: theme.palette.warning.main,
    display: 'inline-block',
    width: '60%',
    padding: '10px',
    fontSize: '14px',
    fontWeight: '500',
    height: '40px',
    lineHeight: '18px',
    borderRadius: '5px',
    textTransform: 'inherit',
    boxShadow: 'none',
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.warning.dark,
      borderColor: theme.palette.warning.dark,
    },
    '&:active': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.warning.light,
      borderColor: theme.palette.warning.light,
    },
  },
}));

const TrialFormAnchor = () => {
  const classes = useStyles();

  return (
    <Box
      id="trialBanner"
      className={classes.trialBanner}
    >
      <Box className={classes.bannerTitle}>Read this transcript now</Box>
      <Box className={classes.bannerSubtitle}>
        Sign up for a 14-days free trial (no credit card required)
      </Box>
      <Anchor
        className={classes.bannerBtn}
        to="activeCampaingForm"
        smooth
        offset={-81}
      >
        Read Now
      </Anchor>
    </Box>
  );
};

export default TrialFormAnchor;
