/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Fade from '@material-ui/core/Fade';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useMediaQuery } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import { useDispatch } from 'react-redux';
import { formatDate } from '../../../../helpers/dateHelpers';
import {
  ComplianceAccessRequests,
  EndlessScrollPreview,
  InterviewNotFound,
  KeywordsGroup,
  LegalDisclaimer,
  MemberAccessRequest,
  MemberAccessRequestStatus,
  MoreOn,
  OrderInterview,
  OrderInterviewStatus,
  Person,
  Perspective,
  AskQuestionsExpertBtn,
  Tickers,
  TranscriptRatingBanner,
  TrialBanner,
} from '../../../../components/InterviewPreviewComponents';
import {
  INTERVIEW_QUERY,
  SET_INTERVIEW_PERCENTS_READ_MUTATION,
} from '../../../../queries/interview/interview';
import { COMPLIANCE_ROLES } from '../../../../config';
import InterviewPlayer from './components/InterviewPlayer';
import InterviewPreviewSkeleton from './components/InterviewPreviewSkeleton';
import { searchToObject } from '../../../../helpers/locationSearchToJson';
import InterviewActions from './components/InterviewActions';
import AccessRequestStatus from './components/AccessRequestStatus';
import Headline from './components/Headline';
import CollapseBlock from './components/CollapseBlock';
import Sector from './components/Sector';
import Industry from './components/Industry';
import { writeFilterData } from '../../../../actions/filterActions';
import useAllIntervies from '../../../../hooks/useAllIntervies';
import useMe from '../../../../hooks/useMe';
import useTrialReminder from '../../../../hooks/useTrialReminder';
import interviewPreviewStyles from './styles';

const InterviewPreview = ({
  interviewId, playerId, isFullScreen, onScroll,
}) => {
  const interviewContainer = useRef(null);
  useEffect(() => {
    interviewContainer.current.scrollTop = 0;
  }, [interviewId]);

  const classes = interviewPreviewStyles();
  const [targetScrolled, setTargetScrolled] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { trackEvent } = useTracking();
  const { me, loading: meLoading } = useMe();
  let legalDisclaimerElHeight = 0;
  let moreOnElHeight = 0;
  let endlessScrollPreviewElHeight = 0;
  let scrollProgress = 0;
  let readProgress;
  let percent;

  const matchInterview = useRouteMatch({ path: '/:interviewId', exact: true });
  const matchHome = useRouteMatch({ path: '/', exact: true });
  const isInterviewPage = !isEmpty(matchInterview) || !isEmpty(matchHome);
  const { isExpiring, isExpired } = useTrialReminder();
  const showTrialBanner = isExpiring || isExpired;

  const isAutoDisplayed = () => {
    if (location.state) {
      return location.state.auto_displayed || false;
    }
    return false;
  };

  const [getInterview, {
    loading,
    // error,
    data = {},
    // called,
  }] = useLazyQuery(INTERVIEW_QUERY);

  const interview = data.interview || {};

  // TODO conditions in progress
  const isCanViewInterview = interview.userCanViewInterview;
  const isTranscribed = interview.transcriptionStatus === 'TRANSCRIBED';
  const isPaidAccess = me.hasPaidAccess;
  const isMember = me.role === 'MEMBER';
  const isExpert = me.role === 'EXPERT';
  const isNotExpert = me.role !== 'EXPERT';

  useEffect(() => {
    if (!loading) {
      const userIdRegex = /^U[0-9]*-/;
      const interviewIdCleaned = interviewId.replace(userIdRegex, '');
      getInterview({ variables: { interviewId: interviewIdCleaned, autoDisplayed: isAutoDisplayed() } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewId]);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [setInterviewPercentsRead] = useMutation(SET_INTERVIEW_PERCENTS_READ_MUTATION);

  useEffect(() => {
    if (interview.interviewId) {
      trackEvent({
        viewName: 'interview:interview_detail',
        appLabel: 'interview',
        model: 'interview',
        objectId: interview.id,
        path: location.pathname,
        getDict: JSON.stringify({ ...location.state, ...searchToObject(location.search) }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interview.interviewId]);

  const handleExpertFilter = (user) => {
    if (!isInterviewPage) {
      history.push('/');
    }
    dispatch(writeFilterData({ expert: [user] }));
  };

  const totalLoading = loading || meLoading;
  const { updateQuery: updateAllInterviewsQuery } = useAllIntervies();

  const handleProgress = debounce(() => {
    if ((interview?.id && percent) && (percent > interview.percentsRead)) {
      setInterviewPercentsRead({
        variables: {
          interviewPk: interview.id,
          percentsRead: percent,
        },
        update: () => {
          updateAllInterviewsQuery((previousQueryResult) => {
            const results = previousQueryResult.allInterviews?.results.map((el) => {
              if (el.interviewId === interview.interviewId) {
                return { ...el, percentsRead: percent };
              }
              return el;
            });

            return {
              allInterviews: {
                ...previousQueryResult.allInterviews,
                results,
              },
            };
          });
        },
      });
    }
  }, 1000);

  const handleSticky = () => {
    let headerHeight = isDesktop ? 0 : 56;
    if (showTrialBanner) {
      headerHeight = isDesktop ? 37 : 56;
    }
    const actionsHeight = 45;
    const actionsBox = document.getElementById('interviewActions');
    const player = document.getElementById('audioPlayer');
    const ratingBox = document.getElementById('transcriptRating');
    let actionsBoxShouldSticky;
    let playerShouldSticky;
    let ratingBoxShouldSticky;
    if (actionsBox) {
      actionsBoxShouldSticky = actionsBox.getBoundingClientRect().top <= headerHeight;
      if (actionsBoxShouldSticky) {
        actionsBox.firstChild.style.position = 'fixed';
        actionsBox.firstChild.style.top = `${headerHeight}px`;
      } else {
        actionsBox.firstChild.style.position = 'static';
      }
    }
    if (player) {
      playerShouldSticky = player.getBoundingClientRect().top <= actionsHeight + headerHeight;
      if (playerShouldSticky) {
        player.firstChild.style.position = 'fixed';
        player.firstChild.style.top = `${actionsHeight + headerHeight}px`;
        player.firstChild.style.height = '75px';
        player.firstChild.style.background = 'linear-gradient(180deg, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 0.2) 100%)';
        player.firstChild.firstChild.style.height = '30px';
        player.firstChild.firstChild.style.padding = '0 10px';
      } else {
        player.firstChild.style.position = 'static';
        player.firstChild.style.height = '50px';
        player.firstChild.firstChild.style.height = 'auto';
        player.firstChild.firstChild.style.padding = '8px 10px';
      }
    }
    if (ratingBox && percent >= 40) {
      ratingBoxShouldSticky = ratingBox.getBoundingClientRect().top >= document.documentElement.clientHeight;
      if (ratingBoxShouldSticky && interview.grade === null) {
        ratingBox.firstChild.style.position = 'fixed';
        ratingBox.firstChild.style.bottom = '0';
      } else {
        ratingBox.firstChild.style.position = 'static';
      }
    }
    return { actionsBoxShouldSticky, playerShouldSticky, ratingBoxShouldSticky };
  };

  const handleReadProgress = () => {
    const interviewEl = interviewContainer.current;
    const legalDisclaimerEl = document.getElementById('legalDisclaimer');
    const moreOnEl = document.getElementById('moreOn');
    const endlessScrollPreviewEl = document.getElementById('endlessScrollPreview');
    const otherSpace = 204;
    if (legalDisclaimerEl) {
      legalDisclaimerElHeight = legalDisclaimerEl.scrollHeight;
    }
    if (moreOnEl) {
      moreOnElHeight = moreOnEl.scrollHeight;
    }
    if (endlessScrollPreviewEl) {
      endlessScrollPreviewElHeight = endlessScrollPreviewEl.scrollHeight;
    }
    const bottomContent = legalDisclaimerElHeight + moreOnElHeight + endlessScrollPreviewElHeight + otherSpace;
    if (interviewEl) {
      const interviewHeight = interviewEl.scrollHeight - bottomContent;
      const scrollDistance = interviewEl.scrollTop + window.innerHeight;
      if (scrollProgress < scrollDistance) {
        scrollProgress = scrollDistance;
        readProgress = scrollProgress / interviewHeight;
        if (readProgress < 1) {
          percent = Math.round(readProgress * 100);
        } else {
          percent = 100;
        }
      }
    }
    return percent;
  };

  const handleDelayedLoad = () => {
    if (!targetScrolled) {
      const interviewEl = interviewContainer.current;
      const interviewHeight = interviewEl.scrollHeight;
      const scrollDistance = interviewEl.scrollTop + window.innerHeight;
      if ((scrollDistance / interviewHeight) > 0.2) {
        setTargetScrolled(true);
      }
    }
  };

  const loadIfShortInterview = () => {
    const interviewEl = interviewContainer.current;
    const interviewHeight = interviewEl.scrollHeight - 204;
    if (interviewHeight < window.innerHeight) {
      setTargetScrolled(true);
    }
  };

  const handleScroll = () => {
    if (isCanViewInterview && isNotExpert && isPaidAccess && isTranscribed) {
      handleReadProgress();
    }
    const stickyStatus = handleSticky();
    handleProgress();
    handleDelayedLoad();
    onScroll(stickyStatus);
  };

  const memberAccessRequest = () => {
    if (interview.accessRequest) {
      return (
        <MemberAccessRequestStatus
          accessRequest={interview.accessRequest}
          interviewId={interview.interviewId}
          interviewPK={interview.id}
        />
      );
    }
    return <MemberAccessRequest interviewId={interview.interviewId} />;
  };

  const orderInterview = () => {
    if (interview.orders && interview.orders.length > 0) {
      return <OrderInterviewStatus createdAt={interview.orders[0].createdAt} />;
    }
    return <OrderInterview interviewId={interview.interviewId} />;
  };

  const fullView = () => {
    if (isCanViewInterview && !isPaidAccess) {
      return <Box dangerouslySetInnerHTML={{ __html: interview.content }} className={classes.trialView} />;
    }
    return <Box dangerouslySetInnerHTML={{ __html: interview.content }} />;
  };

  const transcriptView = () => {
    if (isExpert) {
      return <Box dangerouslySetInnerHTML={{ __html: interview.content }} className={classes.expertView} />;
    }
    return fullView();
  };

  useEffect(() => {
    if (!isEmpty(interview)) {
      window.dataLayer.push({
        event: 'interviewRendered', id: interview.uuid, auto_shown: isAutoDisplayed(),
      });
    }
    loadIfShortInterview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interview]);

  const getPrimaryTicker = (tickers) => {
    const INITIAL = {
      ticker: {
        id: '',
        uuid: '',
        title: '',
        slug: '',
        description: '',
        logoUrl: '',
      },
    };

    const res = tickers.filter((t) => t.isPrimary && t);

    if (res.length > 0) {
      return res[0];
    }
    return INITIAL;
  };

  return (
    <Box
      className={clsx(classes.root, 'xlink-interview-full-panel')}
      data-xlink-interview-id={interview.uuid}
      data-xlink-auto-shown={isAutoDisplayed()}
      onScroll={handleScroll}
      ref={interviewContainer}
    >
      {totalLoading && (
        <InterviewPreviewSkeleton totalLoading={totalLoading} />
      )}
      <Fade in={!totalLoading} timeout={{ enter: 100, exit: 100 }}>
        <Box height="100%">
          {!isEmpty(interview)
            ? (
              <>
                {COMPLIANCE_ROLES.includes(me.role)
                && (
                  <ComplianceAccessRequests accessRequests={interview.accessRequests} />
                )}

                {interview.accessRequest && interview.userHasComplianceLock
                && (
                  <AccessRequestStatus accessRequest={interview.accessRequest} />
                )}

                <InterviewActions
                  interviewId={interview.interviewId}
                  interviewPK={interview.id}
                  isFullScreen={isFullScreen}
                  isDesktop={isDesktop}
                  isPrintAllowed={isCanViewInterview && isPaidAccess && isTranscribed}
                  isMarkUnreadAllowed={isMember && isCanViewInterview && isPaidAccess && isTranscribed && interview.percentsRead >= 5}
                  isInviteGuestsAllowed={isMember && isCanViewInterview && isPaidAccess && isTranscribed}
                  readingListItem={interview.readingListItem}
                  isExpert={isExpert}
                  companies={interview.tickers}
                  expert={interview.expert}
                  perspective={interview.perspective}
                />

                <Box mb="10px">
                  {!isEmpty(interview.callDate)
                  && (
                    <Box component="span" className={classes.callDate}>
                      {formatDate(interview.callDate)}
                    </Box>
                  )}
                  {!isEmpty(interview.tickers)
                  && <Sector interview={interview} />}
                  {!isEmpty(interview.tickers)
                  && <Industry interview={interview} />}
                </Box>

                {!isEmpty(interview.tickers)
                && (
                  <Box display="flex" flexWrap="wrap" alignItems="center">
                    {(!isEmpty(getPrimaryTicker(interview.tickers)) && !isEmpty(getPrimaryTicker(interview.tickers).ticker.description))
                    && (
                      <Box className={classes.tickerDesc}>
                        {getPrimaryTicker(interview.tickers).ticker.description}
                      </Box>
                    )}
                    {interview.tickers.map(({ ticker }) => (
                      <Tickers
                        key={ticker.id}
                        uuid={ticker.uuid}
                        tickerId={ticker.id}
                        title={ticker.title}
                        interviewId={interview.interviewId}
                        company={ticker?.company?.id}
                        isAddedToWatchList={ticker.isAddedToWatchList}
                      />
                    ))}
                  </Box>
                )}

                <Box mb="8px">
                  {!isEmpty(interview.perspective)
                  && (
                    <Perspective>
                      {interview.perspective}
                    </Perspective>
                  )}

                  {(!isEmpty(interview.perspective) && isNotExpert)
                  && (
                    <Box component="span" className={classes.vDivider} />
                  )}

                  {isNotExpert
                  && (
                    <Box component="span" className={classes.title}>
                      {interview.relevantRole}
                    </Box>
                  )}
                </Box>

                {!isEmpty(interview.keywords)
                && (
                  <Box display="flex" flexWrap="wrap" alignItems="center">
                    <Box className={classes.keyTitle}>
                      Tags:
                    </Box>
                    <KeywordsGroup
                      interviewId={interview.interviewId}
                      keywords={interview.keywords}
                    />
                  </Box>
                )}

                <Divider className={classes.fDivider} />

                {!isEmpty(interview.headline)
                && (
                  <Headline>
                    {interview.headline}
                  </Headline>
                )}

                <Box>
                  {(!isEmpty(interview.summary) && isCanViewInterview && isPaidAccess)
                  && (
                    <CollapseBlock title="Call Summary:" content={interview.summary} mostProminent />
                  )}

                  {(!isEmpty(interview.tableOfContents) && isTranscribed)
                  && (
                    <CollapseBlock title="Table of Contents:" content={interview.tableOfContents} mostProminent />
                  )}

                  {(!isEmpty(interview.headline) || !isEmpty(interview.summary) || !isEmpty(interview.tableOfContents))
                  && (
                    <Divider className={classes.nDivider} />
                  )}

                  {(!isEmpty(interview.expert) && isNotExpert)
                  && (
                    <>
                      <CollapseBlock title="Expert Bio:" content={interview.expert.bio} />
                      <Box mb="8px">
                        <AskQuestionsExpertBtn
                          variant="outlined"
                          className={classes.expertCallBtn}
                          companies={interview.tickers}
                          expert={interview.expert}
                          perspective={interview.perspective}
                        />
                      </Box>
                      <CollapseBlock title="Employment History:" content={interview.expert.employmentHistory} />
                    </>
                  )}

                  {(COMPLIANCE_ROLES.includes(me.role) && !isEmpty(interview.questionnaire))
                  && (
                    <CollapseBlock title="Questionnaire:" content={interview.questionnaire} />
                  )}

                  {(!isEmpty(interview.expert) || !isEmpty(interview.questionnaire))
                  && (
                    <Divider className={classes.nDivider} />
                  )}
                </Box>

                {/* TODO remove from backend */}
                {/* {(me.compliance && me.compliance.length === 0 && MEMBER_ROLES.includes(me.role))
                && <AddComplianceOfficer interviewId={interview.interviewId} />} */}

                {!isEmpty(interview.audio) && isNotExpert
                && (
                  <InterviewPlayer
                    hasPaidAccess={isPaidAccess}
                    userCanViewInterview={isCanViewInterview}
                    playerId={playerId}
                    audio={interview.audio}
                  />
                )}

                <Box
                  display="flex"
                  alignItems={{ xs: 'start', md: 'center' }}
                  flexDirection={{ xs: 'column', md: 'row' }}
                >
                  {!isEmpty(interview.expert)
                  && (
                    <Person
                      purpose="Expert"
                      name={interview.expert.fullName}
                      onClick={() => handleExpertFilter(interview.expert)}
                    />
                  )}
                </Box>

                {(!isCanViewInterview && isNotExpert && isTranscribed)
                && memberAccessRequest()}

                {interview.transcriptionStatus === 'NOT_TRANSCRIBED'
                && orderInterview()}

                {isTranscribed
                && transcriptView()}

                {(isCanViewInterview && !isPaidAccess && isTranscribed)
                && <TrialBanner />}

                {!isEmpty(interview.expert)
                && (
                  <Box mb="24px">
                    <AskQuestionsExpertBtn
                      variant="outlined"
                      className={classes.expertCallBtn}
                      companies={interview.tickers}
                      expert={interview.expert}
                      perspective={interview.perspective}
                    />
                  </Box>
                )}

                {(isCanViewInterview && isPaidAccess && isTranscribed)
                && (
                  <TranscriptRatingBanner
                    interviewId={interview.interviewId}
                    interviewGrade={interview?.grade?.value}
                    isAutoDisplayed={isAutoDisplayed()}
                  />
                )}

                <LegalDisclaimer />

                {!isEmpty(interview.tickers) && isFullScreen
                && <MoreOn tickers={interview.tickers} interviewId={interview.interviewId} />}

                {!isEmpty(interview.tickers) && targetScrolled
                && <MoreOn tickers={interview.tickers} interviewId={interview.interviewId} />}

                {!isEmpty(interview.tickers) && isFullScreen
                && <EndlessScrollPreview tickers={interview.tickers} interviewId={interview.interviewId} />}

                {!isEmpty(interview.tickers) && targetScrolled
                && <EndlessScrollPreview tickers={interview.tickers} interviewId={interview.interviewId} />}
              </>
            )
            : (
              <InterviewNotFound />
            )}
        </Box>
      </Fade>
    </Box>
  );
};

InterviewPreview.defaultProps = {
  playerId: '',
  isFullScreen: false,
  onScroll: () => {},
};

InterviewPreview.propTypes = {
  interviewId: PropTypes.string.isRequired,
  isFullScreen: PropTypes.bool,
  playerId: PropTypes.string,
  onScroll: PropTypes.func,
};

const areEqual = (prevProps, nextProps) => prevProps.interviewId === nextProps.interviewId;

export default React.memo(InterviewPreview, areEqual);
