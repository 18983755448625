import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '66px',
    marginRight: '10px',
  },
  label: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '15px',
    color: theme.palette.text.secondary,
    textAlign: 'end',
  },
  lineProgress: {
    height: '2px',
    borderRadius: '1px',
    backgroundColor: theme.palette.info.dark,
  },
}));

const LinearDeterminate = ({ progress }) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="end"
      className={classes.container}
    >
      <Box className={classes.label}>
        {progress}
        % read
      </Box>
      <LinearProgress
        variant="determinate"
        value={progress}
        className={classes.lineProgress}
      />
    </Box>
  );
};

LinearDeterminate.propTypes = {
  progress: PropTypes.number.isRequired,
};

export default LinearDeterminate;
