import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/styles';
import { formatDate } from '../../helpers/dateHelpers';
import { MEMBER_ACCESS_REQUEST } from '../../queries/interview/interview';
import useAllIntervies from '../../hooks/useAllIntervies';
import { ReactComponent as ClockCompIcon } from '../../assets/icons/clockCompIcon.svg';
import { ReactComponent as Clock2Icon } from '../../assets/icons/clock2Icon.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/userIcon.svg';
import { ReactComponent as CancelDangerIcon } from '../../assets/icons/cancelDangerIcon.svg';

const useStyles = makeStyles(() => ({
  statusContent: {
    border: '1px solid #E2E9F1',
    backgroundColor: '#FFFFFF',
    color: '#182341',
    padding: '20px',
    margin: '20px 0',
    textAlign: 'center!important',
  },
  statusLogo: {
    height: '48px',
    width: '48px',
    backgroundColor: '#E6EAF2',
    marginBottom: '10px',
    marginLeft: 'auto!important',
    marginRight: 'auto!important',
    alignItems: 'center!important',
    justifyContent: 'center!important',
    display: 'flex!important',
    borderRadius: '50%!important',
  },
  statusText: {
    fontFamily: 'PT Serif Caption!important',
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '24px',
    marginBottom: '.5rem!important',
  },
  statusInfoLine: {
    fontFamily: 'PT Root UI',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    marginBottom: '10px',
    alignItems: 'center!important',
    justifyContent: 'center!important',
    display: 'flex!important',
  },
  mr3: {
    marginRight: '0.5em',
    marginBottom: '-3px',
  },
  redBckgrnd: {
    backgroundColor: '#FFDADA',
  },
  repeatRequestBttn: {
    border: '1px solid #E2E9F1',
    borderRadius: '0px',
    height: '40px',
    color: '#182341',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    padding: '11px 20px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1168A7',
      color: 'white',
    },
  },
}));

const MemberAccessRequestStatus = ({
  accessRequest, interviewId, interviewPK,
}) => {
  const classes = useStyles();
  const { updateQuery: updateAllInterviewsQuery } = useAllIntervies();
  const [createRequest, { loading, called }] = useMutation(MEMBER_ACCESS_REQUEST, {
    errorPolicy: 'all',
    update: (cache, { data: { memberAccessRequest } }) => {
      cache.modify({
        id: `InterviewType:${interviewPK}`,
        fields: {
          accessRequest(fieldValue, details) {
            return details.INVALIDATE;
          },
        },
      });
      updateAllInterviewsQuery((previousQueryResult) => {
        const results = previousQueryResult.allInterviews.results.map((el) => {
          if (el.interviewId === interviewId) {
            return { ...el, accessRequest: memberAccessRequest.accessRequest };
          }
          return el;
        });

        return {
          allInterviews: {
            ...previousQueryResult.allInterviews,
            results,
          },
        };
      });
    },
  });

  const handleRequest = () => {
    if (!loading) {
      createRequest({ variables: { interviewId } });
    }
  };
  return (
    accessRequest.status === 'PENDING'
      ? (
        <Box className={classes.statusContent}>
          <Box className={classes.statusLogo}>
            <ClockCompIcon />
          </Box>
          <Box className={classes.statusText}>
            Your request has been successfully sent to your Compliance Officer
          </Box>
          <Box component="div" className={classes.statusInfoLine}>
            <Box className={classes.mr3}>
              <Clock2Icon />
            </Box>
            {formatDate(accessRequest.createdAt)}
          </Box>
          {/* <Box component="div" className={classes.statusInfoLine}>
            {accessRequest.statusChangedAt}
          </Box> */}
          <Box component="div" className={classes.statusInfoLine}>
            <Box className={classes.mr3}>
              <UserIcon />
            </Box>
            {/* {complianceOfficers.map((officer, index) => {
              if (complianceOfficers.length === index + 1) {
              // last iteration
                return <Box key={officer.id}>{officer.fullName}</Box>;
              }
              return (
                <Box key={officer.id}>
                  {`${officer.fullName}, `}
                </Box>
              );
            })} */}
          </Box>
        </Box>
      ) : (
        <Box className={classes.statusContent}>
          <Box className={`${classes.statusLogo} ${classes.redBckgrnd}`}>
            <CancelDangerIcon />
          </Box>
          <Box className={classes.statusText}>
            Your request to access this Interview has been declined
          </Box>
          <Box component="div" className={classes.statusInfoLine}>
            <Box className={classes.mr3}>
              <Clock2Icon />
            </Box>
            {formatDate(accessRequest.createdAt)}
          </Box>
          {/* <Box component="div" className={classes.statusInfoLine}>
            {accessRequest.statusChangedAt}
          </Box> */}
          <Box component="div" className={classes.statusInfoLine}>
            <Box className={classes.mr3}>
              <UserIcon />
            </Box>
            {/* {complianceOfficers.map((officer, index) => {
              if (complianceOfficers.length === index + 1) {
              // last iteration
                return <Box key={officer.id}>{officer.fullName}</Box>;
              }
              return (
                <Box key={officer.id}>
                  {`${officer.fullName}, `}
                </Box>
              );
            })} */}
          </Box>
          <Button
            onClick={handleRequest}
            className={classes.repeatRequestBttn}
            disabled={loading || called}
          >
            Repeat Request
          </Button>
        </Box>
      )
  );
};

MemberAccessRequestStatus.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  accessRequest: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  // complianceOfficers: PropTypes.array.isRequired,
  interviewId: PropTypes.string.isRequired,
  interviewPK: PropTypes.string.isRequired,
};

export default MemberAccessRequestStatus;
