import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import pluralize from 'pluralize';
import ContactSalesPopup from '../../../../components/ContactSalesPopup';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '37px',
    width: '100vw',
    padding: '4px 15px',
    boxShadow: 'none',
    left: '0',
    flexDirection: 'row',
    borderBottom: '1px solid',
    borderBottomColor: '#C9D5E5',
    backgroundColor: '#DEEEFA',
    [theme.breakpoints.up('md')]: {
      padding: '4px 24px 4px 84px',
    },
    '&.expiredTrialBanner': {
      backgroundColor: '#F5EAE1',
    },
  },
  reminderTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.text.primary,
  },
  badge: {
    display: 'block',
    height: '24px',
    backgroundColor: 'rgba(204, 0, 0, 0.08)',
    color: '#B60000',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    textAlign: 'center',
    padding: '3px 8px',
    borderRadius: '8px',
    marginLeft: '8px',
  },
  reminderText: {
    display: 'none',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '14px',
    color: theme.palette.text.primary,
    marginLeft: '24px',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  span: {
    color: theme.palette.primary.main,
    marginRight: '4px',
  },
  mailTo: {
    fontWeight: '500',
    color: theme.palette.primary.main,
    margin: '0 4px',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
    '&:active': {
      color: theme.palette.primary.light,
    },
  },
}));

const TrialReminderBanner = ({ className, expired, daysLeft }) => {
  const classes = useStyles();

  let message;
  if (expired) {
    message = 'Your free trial has expired';
  } else if (daysLeft > 0) {
    message = 'Your trial expires in';
  } else {
    message = 'Your trial expires';
  }

  return (
    <AppBar
      className={clsx(classes.root, className)}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
      >
        <Box
          display="flex"
          alignItems="center"
        >
          <Box className={classes.reminderTitle} color={expired && '#C0641E !important'}>
            {message}
          </Box>
          {!expired
            && (
              <Box className={classes.badge}>
                {daysLeft > 0 ? `${daysLeft} ${pluralize('day', daysLeft)}` : 'today'}
              </Box>
            )}
          <Box className={classes.reminderText}>
            {expired
              ? (
                <>
                  <Box>
                    <span className={classes.span}>You no longer have full access to Stream.</span>
                    Make sure you don’t miss a single new
                    transcript in your coverage universe—your peers certainly won’t.
                  </Box>
                  <Box>
                    Email your account manager or
                    <a className={classes.mailTo} href="mailto:sales@streamrg.com">sales@streamrg.com</a>
                    today to extend your trial or subscribe.
                  </Box>
                </>
              )
              : (
                <>
                  <Box>
                    Make sure you don’t miss a single new transcript in your coverage
                    universe—your peers certainly won’t.
                  </Box>
                  <Box>
                    Email your account manager or
                    <a className={classes.mailTo} href="mailto:sales@streamrg.com">sales@streamrg.com</a>
                    today to ensure uninterrupted access to Stream transcripts.
                  </Box>
                </>
              )}
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
        >
          <ContactSalesPopup />
        </Box>
      </Box>
    </AppBar>
  );
};

TrialReminderBanner.defaultProps = {
  className: '',
  expired: false,
  daysLeft: '',
};

TrialReminderBanner.propTypes = {
  className: PropTypes.string,
  expired: PropTypes.bool,
  daysLeft: PropTypes.string,
};

export default TrialReminderBanner;
