import React from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import useMe from '../../hooks/useMe';
import { ReactComponent as FullscreenIcon } from '../../assets/icons/fullscreenIcon.svg';

const useStyles = makeStyles((theme) => ({
  fullScreen: {
    height: '30px',
    padding: '5px',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    border: '1px solid',
    borderRadius: '5px',
    borderColor: theme.palette.info.main,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    marginLeft: '10px',
    textTransform: 'capitalize',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      '& svg path': {
        fill: theme.palette.common.white,
      },
    },
    '&:active': {
      borderColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const FullScreenBtn = ({ interviewId }) => {
  const classes = useStyles();
  const { me } = useMe();
  const userId = me.id ? `U${me.id}-` : '';

  return (
    <Tooltip title="Full Screen" arrow>
      <Link
        to={`/${userId}${interviewId}/fullscreen`}
        target="_blank"
        color="inherit"
        size="small"
        className={classes.fullScreen}
      >
        <Box display="flex">
          <FullscreenIcon />
        </Box>
      </Link>
    </Tooltip>
  );
};

FullScreenBtn.propTypes = {
  interviewId: PropTypes.string.isRequired,
};

export default FullScreenBtn;
