import { useEffect, useReducer } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { singletonHook } from 'react-singleton-hook';
import {
  ADD_INTERVIEW_TO_READING_LIST_MUTATION,
  ALL_READING_LISTS_QUERY,
  REMOVE_FROM_READING_LIST_MUTATION,
} from '../queries/interview/interview';
import { ME_QUERY } from '../queries/account';

const INITIAL_VALS = {
  loading: false,
  allReadingList: {},
  refetch: () => {},
  updateQuery: () => {},
  called: false,
  addInterviewToReadingList: () => {},
  removeInterviewFromReadingList: () => {},
};

const useReadingList = () => {
  const [readingListState, dispatch] = useReducer(
    (state, newState) => (
      { ...state, ...newState }
    ),
    INITIAL_VALS,
  );

  const [getAllReadingList, {
    loading,
    data: { allReadingList = {} } = {},
    refetch,
    called,
    updateQuery,
  }] = useLazyQuery(ALL_READING_LISTS_QUERY, {
    fetchPolicy: 'network-only',
  });

  const [addInterviewToReadingList] = useMutation(ADD_INTERVIEW_TO_READING_LIST_MUTATION, {
    refetchQueries: [
      { query: ALL_READING_LISTS_QUERY },
      { query: ME_QUERY },
    ],
  });

  const [removeInterviewFromReadingList] = useMutation(REMOVE_FROM_READING_LIST_MUTATION, {
    refetchQueries: [
      { query: ALL_READING_LISTS_QUERY },
      { query: ME_QUERY },
    ],
  });

  useEffect(() => {
    if (!loading) {
      getAllReadingList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let newState = {};
    if (!isEqual(allReadingList, readingListState.allReadingList) && !isEmpty(allReadingList)) {
      newState = { ...newState, allReadingList };
    }

    if (isEmpty(readingListState.allReadingList)
        && (!isEmpty(allReadingList) || allReadingList === null)) {
      newState = {
        ...newState,
        refetch,
        updateQuery,
        addInterviewToReadingList,
        removeInterviewFromReadingList,
      };
    }

    if (loading !== readingListState.loading) {
      newState = { ...newState, loading };
    }

    if (called !== readingListState.called) {
      newState = { ...newState, called };
    }

    if (!isEmpty(newState)) {
      dispatch({ ...readingListState, ...newState });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allReadingList, loading, called]);

  return readingListState;
};

export default singletonHook(INITIAL_VALS, useReadingList);
