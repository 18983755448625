import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => createStyles({
  root: {
    padding: '56px 10px 20px',
    [theme.breakpoints.up('md')]: {
      height: '100%',
      padding: '10px 30px 20px 20px',
    },
    overflowY: 'scroll',
  },
  title: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '20px',
    color: theme.palette.text.primary,
  },
  trialView: {
    position: 'relative',
    '&::before': {
      content: '""',
      width: '100%',
      height: '100px',
      position: 'absolute',
      left: '0',
      bottom: '0',
      top: 'auto',
      backgroundImage: 'linear-gradient(180deg,rgba(255,255,255,0) 0,rgba(255,255,255,.6) 30%,#fff 100%)',
    },
    '& .tts': {
      pointerEvents: 'none',
      cursor: 'default',
    },
  },
  expertView: {
    '& .tts': {
      display: 'none !important',
    },
  },
  callDate: {
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    marginRight: '10px',
  },
  tickerDesc: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '22px',
    color: theme.palette.text.primary,
    marginRight: '15px',
    marginBottom: '8px',
  },
  vDivider: {
    borderRight: '1px solid',
    borderRightColor: theme.palette.info.dark,
    paddingRight: '10px',
    marginRight: '10px',
  },
  keyTitle: {
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.text.primary,
    marginBottom: '8px',
    marginRight: '7px',
  },
  fDivider: {
    marginTop: '4px',
    marginBottom: '12px',
  },
  nDivider: {
    marginTop: '12px',
    marginBottom: '12px',
  },
  expertCallBtn: {
    height: '30px',
    padding: '5px 10px',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    border: '1px solid',
    borderRadius: '5px',
    borderColor: theme.palette.info.main,
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    textTransform: 'capitalize',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      '& svg path': {
        fill: theme.palette.common.white,
      },
    },
    '&.Mui-disabled': {
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.info.main,
      borderColor: theme.palette.info.main,
      '& svg path': {
        fill: theme.palette.text.secondary,
      },
    },
  },
}));
