import {
  SET_ACTIVE_TAB, SET_ACTIVE_TAB_NULL, HIDE_SIDEBAR, SHOW_SIDEBAR,
} from '../actions/sidebarConstants';
import sidebarTabs from '../constants/sidebarTabs';

const initialState = {
  activeTab: sidebarTabs.companies,
  sideBarVisible: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE': {
      // Tab has to be close on all pages except interview
      const regex = /^\/[a-zA-Z0-9-]*\/?$/g;
      const isInterviewPage = regex.test(action.payload.location.pathname);
      // undefined means tab companies shoud be opened automatically.
      // null if user has closed sidebar manually
      if (!isInterviewPage) {
        return { ...state, activeTab: sidebarTabs.companies };
      }
      if (isInterviewPage && state.activeTab === undefined) {
        return { ...state, activeTab: sidebarTabs.companies };
      }
      return state;
    }
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };
    case SET_ACTIVE_TAB_NULL:
      return {
        ...state,
        activeTab: null,
      };
    case HIDE_SIDEBAR:
      return {
        ...state,
        sideBarVisible: false,
        // activeTab: null,
      };
    case SHOW_SIDEBAR: {
      const activeTab = state.activeTab === null ? sidebarTabs.companies : state.activeTab;
      return {
        ...state,
        sideBarVisible: true,
        activeTab,
      };
    }
    default:
      return state;
  }
};
