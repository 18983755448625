import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { ReactComponent as ChevronUpIcon } from '../../../../../../../../../../assets/icons/chevronUpIcon.svg';
import { ReactComponent as ChevronRightIcon } from '../../../../../../../../../../assets/icons/chevronRightIcon.svg';
import { ReactComponent as LeftBracketIcon } from '../../../../../../../../../../assets/icons/leftBracketIcon.svg';
import { ReactComponent as RightBracketIcon } from '../../../../../../../../../../assets/icons/rightBracketIcon.svg';

const useStyles = makeStyles((theme) => ({
  collapse: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
  },
  badge: {
    display: 'inline-block',
    minWidth: '28px',
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '14px',
    padding: '2px 8px',
    borderRadius: '9px',
    marginRight: '10px',
  },
  chevron: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '18px',
  },
}));

const FollowingItemsSection = ({
  sectionTitle, expanded, totalCount, unreadCount,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.collapse}>
      <Box
        display="flex"
        alignItems="center"
      >
        <Box className={classes.title}>
          {sectionTitle}
          {totalCount > 0
              && (
                <Box
                  display="flex"
                  alignItems="center"
                  ml="2px"
                >
                  <LeftBracketIcon />
                  {totalCount}
                  <RightBracketIcon />
                </Box>
              )}
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
      >
        {unreadCount > 0
            && (
              <Box className={classes.badge}>
                {unreadCount}
              </Box>
            )}
        <Box className={classes.chevron}>
          {expanded ? <ChevronUpIcon /> : <ChevronRightIcon />}
        </Box>
      </Box>
    </Box>
  );
};

FollowingItemsSection.defaultProps = {
  totalCount: '0',
};

FollowingItemsSection.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  totalCount: PropTypes.number,
  unreadCount: PropTypes.number.isRequired,
};

export default FollowingItemsSection;
