/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/styles';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Portal from '@material-ui/core/Portal';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import useDebounce from '../../../../hooks/useDebounce';
import { Filters } from '../index';
import { writeFilterData } from '../../../../actions/filterActions';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/searchIcon.svg';
import { ReactComponent as FiltersIcon } from '../../../../assets/icons/filtersIcon.svg';

const SearchInputField = withStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '5px',
      height: '50px',
      '& input': {
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '18px',
        color: theme.palette.text.primary,
        paddingLeft: '4px',
        paddingTop: '15.5px',
        paddingBottom: '15.5px',
      },
      '& fieldset': {
        borderColor: theme.palette.info.main,
      },
      '&:hover': {
        '& svg path': {
          stroke: theme.palette.primary.main,
        },
        '& button svg path': {
          stroke: 'none',
        },
        '& fieldset': {
          borderColor: theme.palette.primary.main,
        },
      },
      '&.Mui-focused': {
        '& svg path': {
          stroke: theme.palette.primary.main,
        },
        '& button svg path': {
          stroke: 'none',
        },
        '& fieldset': {
          borderWidth: '1px',
        },
      },
    },
  },
}))(TextField);

const useStyles = makeStyles((theme) => ({
  button: {
    padding: '10px 0',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      '& svg path': {
        fill: theme.palette.text.secondary,
      },
    },
  },
  openFilters: {
    '& svg path': {
      fill: theme.palette.primary.main,
      '&:hover': {
        fill: theme.palette.primary.main,
      },
    },
  },
  filters: {
    position: 'absolute',
    top: '45px',
    right: '0px',
    zIndex: 999,
    maxWidth: '300px',
    backgroundColor: theme.palette.background.paper,
    padding: '20px 15px',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.15)',
  },
  closeBtn: {
    marginTop: '5px',
    width: '100%',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.common.white,
    border: '1px solid',
    borderColor: theme.palette.info.main,
    borderRadius: '5px',
    textTransform: 'capitalize',
    [theme.breakpoints.up('md')]: {
      '&:hover': {
        color: theme.palette.text.primary,
        borderColor: theme.palette.info.dark,
        backgroundColor: theme.palette.common.white,
      },
      '&:active': {
        borderColor: theme.palette.info.main,
        backgroundColor: theme.palette.common.white,
      },
    },
  },
}));

const SearchInput = ({ filterSearch, handleWriteFilterData, loading }) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [showFilters, setShowFilters] = React.useState(false);
  const debounceSearch = useDebounce(search, 300);
  const container = React.useRef(null);

  const handleChange = (event) => {
    setSearch(event.target.value);
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  useEffect(() => {
    handleWriteFilterData({ search: debounceSearch });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearch]);

  useEffect(() => {
    if (filterSearch !== search) {
      setSearch(filterSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSearch]);

  return (
    <Box mb="10px">
      <Box position="relative">
        <SearchInputField
          // value={filterData.search}
          value={search}
          type="search"
          variant="outlined"
          placeholder="Search by company or keyword"
          size="small"
          onChange={handleChange}
          className="xlink-search-entry-form"
          inputProps={{ maxLength: 60 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {loading && search
                  ? <CircularProgress size={18} />
                  : <SearchIcon />}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="open filters"
                  onClick={toggleFilters}
                  disableRipple
                  className={clsx({
                    [classes.button]: true,
                    [classes.openFilters]: showFilters,
                  })}
                >
                  <FiltersIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {showFilters ? (
          <Portal container={container.current}>
            <Box className={clsx(classes.filters, 'xlink-search-filters-form')}>
              <Filters toggleFilters={toggleFilters} />
              <Button
                onClick={toggleFilters}
                disableRipple
                className={classes.closeBtn}
              >
                Close
              </Button>
            </Box>
          </Portal>
        ) : null}
        <Box ref={container} />
      </Box>
    </Box>
  );
};

SearchInput.defaultProps = {
  filterSearch: '',
};

SearchInput.propTypes = {
  filterSearch: PropTypes.string,
  handleWriteFilterData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { filterState } = state;
  return {
    filterSearch: filterState?.filterData?.search || '',
  };
};

export default connect(
  mapStateToProps,
  {
    handleWriteFilterData: writeFilterData,
  },
)(SearchInput);
