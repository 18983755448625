import React, { Suspense } from 'react';

// project imports
import Loader from './Loader';

// -----------------------|| LOADABLE - LAZY LOADING ||-----------------------//

// eslint-disable-next-line func-names
const Loadable = (Component) => function (props) {
  return (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );
};

export default Loadable;
