import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const userStyles = makeStyles((theme) => ({
  headline: {
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '25px',
    color: theme.palette.text.primary,
    [theme.breakpoints.up('md')]: {
      fontSize: '22px',
      lineHeight: '28px',
    },
  },
}));

const Headline = ({ children }) => {
  const classes = userStyles();

  return (
    <Box mb="8px">
      <Typography
        variant="h3"
        component="h3"
        className={classes.headline}
      >
        {children}
      </Typography>
    </Box>
  );
};

Headline.defaultProps = {
  children: '',
};

Headline.propTypes = {
  children: PropTypes.string,
};

export default Headline;
