import React, { lazy } from 'react';
import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import Loadable from './utils/Loadable';
import { RouteWithLayout } from './components';
import {
  Main as MainLayout,
  MainWhite as MainWhiteLayout,
  MainNoHeader as MainNoHeaderLayout,
  MainSidebar as MainSidebarLayout,
} from './layouts';
import InterviewView from './views/Interview';
import InterviewRouteView from './views/Interview/InterviewRoute';
import paths from './paths';

const Routes = () => {
  const InterviewPublicView = Loadable(lazy(() => import('./views/InterviewPublic')));
  const SubscribeView = Loadable(lazy(() => import('./views/Subscribe')));
  const LogInView = Loadable(lazy(() => import('./views/LogIn')));
  const FirstLoginView = Loadable(lazy(() => import('./views/FirstLogin')));
  const ResetPasswordView = Loadable(lazy(() => import('./views/ResetPassword')));
  const ResetPasswordCompleteView = Loadable(lazy(() => import('./views/ResetPasswordComplete')));
  const SetPasswordView = Loadable(lazy(() => import('./views/SetPassword')));
  const InterviewGuestsView = Loadable(lazy(() => import('./views/InterviewGuests')));
  const TermsAndConditionsView = Loadable(lazy(() => import('./views/TermsAndConditions')));
  const PrivacyPolicyView = Loadable(lazy(() => import('./views/PrivacyPolicy')));
  const NotFoundView = Loadable(lazy(() => import('./views/NotFound')));
  const DiscoveryPageView = Loadable(lazy(() => import('./views/DiscoveryPage')));
  const SavedFiltersView = Loadable(lazy(() => import('./views/SavedFilters')));
  const SidebarTabsInitialView = Loadable(lazy(() => import('./views/SidebarTabsInitial')));
  const ProfileView = Loadable(lazy(() => import('./views/Profile')));
  const InterviewFullscreenView = Loadable(lazy(() => import('./views/InterviewFullscreen')));
  const RedirectToASView = Loadable(lazy(() => import('./views/AlphaSense')));

  return (
    <Switch>
      {/* public */}
      <Route
        exact
        path={paths.createAccountView.pattern}
        render={
        () => window.location.replace('https://stream.alpha-sense.com/enterprise-signup')
      }
      />
      <RouteWithLayout
        component={RedirectToASView}
        exact
        layout={null}
        path={paths.redirectToASView.pattern}
      />
      <RouteWithLayout
        component={SubscribeView}
        exact
        loginRequired={false}
        layout={MainSidebarLayout}
        path={paths.subscribeView.pattern}
      />
      <RouteWithLayout
        component={LogInView}
        exact
        loginRequired={false}
        layout={MainNoHeaderLayout}
        path={paths.logInView.pattern}
      />
      <RouteWithLayout
        component={FirstLoginView}
        exact
        loginRequired={false}
        layout={MainLayout}
        path={paths.firstLoginView.pattern}
      />
      <RouteWithLayout
        component={ResetPasswordView}
        exact
        loginRequired={false}
        layout={MainLayout}
        path={paths.resetPasswordView.pattern}
      />
      <RouteWithLayout
        component={SetPasswordView}
        exact
        loginRequired={false}
        layout={MainLayout}
        path={paths.setPasswordView.pattern}
      />
      <RouteWithLayout
        component={ResetPasswordCompleteView}
        exact
        loginRequired={false}
        layout={MainLayout}
        path={paths.resetPasswordCompleteView.pattern}
      />
      <RouteWithLayout
        component={InterviewPublicView}
        exact
        loginRequired={false}
        layout={MainWhiteLayout}
        path={paths.interviewPublicView2.pattern}
      />
      <RouteWithLayout
        component={InterviewPublicView}
        exact
        loginRequired={false}
        layout={MainWhiteLayout}
        path={paths.interviewPublicView.pattern}
      />
      <RouteWithLayout
        component={InterviewGuestsView}
        exact
        loginRequired={false}
        layout={MainWhiteLayout}
        path={paths.interviewGuestsView.pattern}
      />
      <RouteWithLayout
        component={TermsAndConditionsView}
        exact
        loginRequired={false}
        layout={MainLayout}
        path={paths.termsAndConditionsView.pattern}
      />
      <RouteWithLayout
        component={PrivacyPolicyView}
        exact
        loginRequired={false}
        layout={MainLayout}
        path={paths.privacyPolicyView.pattern}
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        loginRequired={false}
        layout={MainLayout}
        path={paths.notFoundView.pattern}
      />
      <RouteWithLayout
        component={DiscoveryPageView}
        exact
        layout={MainSidebarLayout}
        path={paths.discoveryPageView.pattern}
      />

      {/* private */}
      <RouteWithLayout
        component={SavedFiltersView}
        exact
        layout={MainSidebarLayout}
        path={paths.savedFiltersView.pattern}
      />
      <RouteWithLayout
        component={SidebarTabsInitialView}
        exact
        layout={MainSidebarLayout}
        path={paths.sidebarTabsInitialView.pattern}
      />
      <RouteWithLayout
        component={ProfileView}
        exact
        layout={MainSidebarLayout}
        path={paths.profileView.pattern}
      />
      <RouteWithLayout
        component={InterviewFullscreenView}
        exact
        layout={MainSidebarLayout}
        path={paths.interviewFullscreenView.pattern}
      />
      <RouteWithLayout
        component={InterviewRouteView}
        exact
        loginRequired={false}
        path={paths.interviewRouteView.pattern}
      />
      <RouteWithLayout
        component={InterviewView}
        exact
        layout={MainSidebarLayout}
        path={paths.interviewView.pattern}
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
