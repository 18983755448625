import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Split from 'react-split';
import { makeStyles } from '@material-ui/styles';
import { useLocation } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import { isEmpty } from 'apollo-link-sentry/lib/utils';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import {
  InterviewItem, Ordering, SearchInput, SelectedFilters,
} from './components';
import { PaginationLink } from '../../components';
import { Footer } from '../../layouts/Main/components';
import { searchToObject } from '../../helpers/locationSearchToJson';
import InterviewPreview from './components/InterviewPreview';
import InterviewsNotFound from './components/InterviewsNotFound/InterviewsNotFound';
import InterviewItemSkeleton from './components/InterviewItem/InterviewItemSkeleton';
import PrimaryTickerSearchResultsSection from './components/PrimaryTickerSearchResultsSection';
import { INTERVIEW_PAGINATION_FIRST } from '../../constants/pagination';
import BulkActions from './components/BulkActions/BulkActions';
import SelectAllBtn from './components/SelectAllBtn/SelectAllBtn';
import useMe from '../../hooks/useMe';
import { ReactComponent as CheckboxIcon } from '../../assets/icons/checkboxIcon.svg';
import { ReactComponent as DotsIcon } from '../../assets/icons/dotsIcon.svg';

const useStyles = makeStyles((theme) => ({
  splitWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    paddingLeft: '60px',
  },
  lColumn: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 20px 30px',
    minHeight: 'calc(100vh - 78px)',
  },
  lColumnWrapper: {
    overflowY: 'scroll',
  },
  rColumn: {
    backgroundColor: theme.palette.common.white,
  },
  button: {
    width: '20px',
    height: '20px',
    minWidth: '20px',
    padding: '1px',
    borderRadius: '0',
    backgroundColor: theme.palette.background.default,
    marginLeft: '12px',
    transition: 'all .25s',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      '& svg path': {
        fill: theme.palette.primary.dark,
      },
    },
    '&:active': {
      backgroundColor: theme.palette.background.default,
      '& svg path': {
        fill: theme.palette.primary.light,
      },
    },
    '&.Mui-disabled svg path': {
      fill: theme.palette.info.main,
    },
  },
  previewFooter: {
    padding: '12px 0',
    '& > div': {
      flexDirection: 'column',
      maxWidth: '400px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  showCount: {
    width: '100%',
    height: '30px',
    padding: '6px 10px',
    marginBottom: '10px',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    backgroundColor: theme.palette.info.main,
    color: theme.palette.text.primary,
    borderRadius: '5px',
  },
  divider: {
    marginTop: '16px',
    marginBottom: '16px',
    backgroundColor: '#DFE6F1',
  },
  searchSectionTitle: {
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    textAlign: 'center',
    marginBottom: '16px',
  },
}));

const InterviewDesktop = ({
  allInterviews, loading, interviewId,
  showSearchCounts, selected, isSelected,
  isReadingList, handleWriteFilterData,
  showTrialBanner,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const { trackEvent } = useTracking();
  const { me } = useMe();
  const filterData = useSelector((state) => state.filterState.filterData, isEqual);
  const [newSearch, setNewSearch] = useState();
  const [relatedIdx, setRelatedIdx] = useState();

  const selectAllBtn = document.getElementById('select-all-btn');
  const previewFooter = document.getElementById('preview-footer');

  const query = new URLSearchParams(location.search);
  const intSkip = parseInt(query.get('skip') || 0, 10);

  if (selectAllBtn && previewFooter) {
    const { width } = previewFooter.getBoundingClientRect();
    selectAllBtn.firstChild.style.width = `${width}px`;
  }

  const handleRowWidth = useCallback((ratingBoxShouldSticky) => {
    const actionsBox = document.getElementById('interviewActions');
    const player = document.getElementById('audioPlayer');
    const ratingBox = document.getElementById('transcriptRating');
    const bulkAction = document.getElementById('bulk-actions');

    if (actionsBox) {
      const { width } = actionsBox.getBoundingClientRect();
      actionsBox.firstChild.style.width = `${width}px`;
    }
    if (player) {
      const { width } = player.getBoundingClientRect();
      player.firstChild.style.width = `${width}px`;
    }
    if (ratingBox) {
      const { width } = ratingBox.getBoundingClientRect();
      const intercomWidth = 56;
      if (ratingBoxShouldSticky) {
        ratingBox.firstChild.style.width = `${width - intercomWidth}px`;
      } else {
        ratingBox.firstChild.style.width = `${width}px`;
      }
    }
    if (bulkAction) {
      const { width } = bulkAction.getBoundingClientRect();
      bulkAction.firstChild.style.width = `${width}px`;
    }
  }, []);

  const handleScroll = ({ actionsBoxShouldSticky, playerShouldSticky, ratingBoxShouldSticky }) => {
    if (actionsBoxShouldSticky || playerShouldSticky || ratingBoxShouldSticky) {
      handleRowWidth(ratingBoxShouldSticky);
    }
  };

  const handleBtnSticky = () => {
    let headerHeight = 0;
    if (showTrialBanner) {
      headerHeight = 37;
    }
    const bulkAction = document.getElementById('bulk-actions');
    if (bulkAction) {
      if (bulkAction.getBoundingClientRect().top <= headerHeight) {
        bulkAction.firstChild.style.position = 'fixed';
        bulkAction.firstChild.style.top = `${headerHeight}px`;
        handleRowWidth();
      } else {
        bulkAction.firstChild.style.position = 'static';
      }
    }
    if (selectAllBtn) {
      if (selectAllBtn.getBoundingClientRect().top >= document.documentElement.clientHeight) {
        selectAllBtn.firstChild.style.position = 'fixed';
        selectAllBtn.firstChild.style.bottom = '0';
      } else {
        selectAllBtn.firstChild.style.position = 'static';
      }
    }
  };

  const handlePaginationClick = (page) => {
    const interviewIds = allInterviews.results.map((el) => el.id);
    trackEvent({
      viewName: 'interview:interview_list',
      path: location.pathname,
      getDict: JSON.stringify(searchToObject(location.search)),
      extraData: JSON.stringify({
        page,
        interviewIds,
      }),
    });
  };

  useEffect(() => {
    setNewSearch(filterData.search && intSkip === 0);
    if (!filterData.search || !allInterviews.results) {
      setRelatedIdx(null);
      return;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const [idx] of allInterviews.results.entries()) {
      if (idx + intSkip > allInterviews.primaryCount) {
        setRelatedIdx(null);
        break;
      }

      if (idx + intSkip === allInterviews.primaryCount) {
        setRelatedIdx(idx);
        break;
      }
    }
    // eslint-disable-next-line
  }, [allInterviews]);

  return (
    <Split
      sizes={[40, 60]}
      minSize={435}
      expandToMin={false}
      gutterSize={16}
      gutterAlign="center"
      snapOffset={30}
      dragInterval={1}
      direction="horizontal"
      cursor="col-resize"
      className={classes.splitWrapper}
      onDrag={handleRowWidth}
    >
      <Box className={classes.lColumnWrapper} onScroll={handleBtnSticky}>
        <Box className={classes.lColumn}>
          <SearchInput loading={loading} />
          <SelectedFilters />

          {newSearch && showSearchCounts
          && (
            <Box className={classes.showCount}>
              {!loading
              && (
                <Box component="span">
                  {`${allInterviews.totalCount} interview${allInterviews.totalCount !== 1 ? 's' : ''} found`}
                </Box>
              )}
            </Box>
          )}

          <Box display="flex" justifyContent="space-between" alignItems="center" mb="10px">
            <Ordering />
            <Box display="flex" alignItems="center">
              <Box display="none">
                <IconButton
                  color="inherit"
                  aria-label="checkList"
                  size="small"
                  disableRipple
                  disabled
                  className={classes.button}
                >
                  <CheckboxIcon />
                </IconButton>
              </Box>
              <Box display="none">
                <IconButton
                  color="inherit"
                  aria-label="menu"
                  size="small"
                  disableRipple
                  disabled
                  className={classes.button}
                >
                  <DotsIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>

          <BulkActions selected={selected} isReadingList={isReadingList} />

          {loading
            ? <InterviewItemSkeleton />
            : (
              <Box className="xlink-interview-list-feed">
                {(allInterviews.totalCount > 0)
                && (
                  <>
                    {newSearch
                    && (
                      <PrimaryTickerSearchResultsSection
                        primaryCount={allInterviews.primaryCount}
                      />
                    )}
                    {allInterviews.results.map((interview, index) => (
                      <>
                        {relatedIdx === index
                        && (
                          <>
                            <Divider className={classes.divider} />
                            <Box className={classes.searchSectionTitle}>
                              Related interviews (
                              {allInterviews.totalCount - allInterviews.primaryCount}
                              )
                            </Box>
                          </>
                        )}
                        <InterviewItem
                          key={interview.interviewId}
                          id={interview.id}
                          uuid={interview.uuid}
                          keywords={interview.keywords}
                          headline={interview.headline}
                          callDate={interview.callDate}
                          interviewId={interview.interviewId}
                          tickers={interview.tickers}
                          relevantRole={interview.relevantRole}
                          perspective={interview.perspective}
                          publishedAt={interview.publishedAt}
                          readingTime={interview.readingTime}
                          isActive={`U${me.id}-${interview.interviewId}` === interviewId}
                          isUnread={interview.isUnread}
                          isSeenInRl={interview.isSeenInRl}
                          isSelected={isSelected(interview.interviewId)}
                          accessRequest={interview.accessRequest || {}}
                          accessRequests={interview.accessRequests || {}}
                          readingListItem={interview.readingListItem}
                          userCanViewInterview={interview.userCanViewInterview}
                          transcriptionStatus={interview.transcriptionStatus}
                          percentsRead={interview.percentsRead}
                          userHasComplianceLock={interview.userHasComplianceLock}
                          me={me}
                          handleWriteFilterData={handleWriteFilterData}
                        />
                      </>
                    ))}
                    <PaginationLink
                      totalCount={allInterviews.totalCount}
                      initialFirst={INTERVIEW_PAGINATION_FIRST}
                      onClick={handlePaginationClick}
                    />
                  </>
                )}
              </Box>
            )}
          {filterData.search && allInterviews.totalCount === 0
          && <PrimaryTickerSearchResultsSection primaryCount={0} />}

          {loading && (<InterviewItemSkeleton />)}

          {(allInterviews.totalCount === 0 && !loading)
          && (
            <Box display="flex" justifyContent="center" alignItems="center" flexGrow="1" pr="24px">
              {allInterviews.totalCount === 0 ? <InterviewsNotFound /> : ''}
            </Box>
          )}
        </Box>
        {!isEmpty(allInterviews)
        && (
          <SelectAllBtn
            interviews={allInterviews.results}
            selected={selected}
            isSelected={isSelected}
            isReadingList={isReadingList}
            userHasPaidAccess={me.hasPaidAccess}
          />
        )}
        <Footer className={classes.previewFooter} id="preview-footer" />
      </Box>
      <Box className={classes.rColumn}>
        {interviewId
        && <InterviewPreview interviewId={interviewId} onScroll={handleScroll} />}
      </Box>
    </Split>
  );
};

InterviewDesktop.defaultProps = {
  showSearchCounts: false,
};

InterviewDesktop.propTypes = {
  allInterviews: PropTypes.shape({
    totalCount: PropTypes.number,
    results: PropTypes.arrayOf(PropTypes.object),
    primaryCount: PropTypes.number.isRequired,
    secondaryCount: PropTypes.number.isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  interviewId: PropTypes.string.isRequired,
  showSearchCounts: PropTypes.bool,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  isSelected: PropTypes.func.isRequired,
  isReadingList: PropTypes.bool.isRequired,
  handleWriteFilterData: PropTypes.func.isRequired,
  showTrialBanner: PropTypes.bool.isRequired,
};

export default InterviewDesktop;
