import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Box from '@material-ui/core/Box';
import { formatDate } from '../../helpers/dateHelpers';
import { ReactComponent as ClockCompIcon } from '../../assets/icons/clockCompIcon.svg';
import { ReactComponent as Clock2Icon } from '../../assets/icons/clock2Icon.svg';

const useStyles = makeStyles(() => ({
  statusContent: {
    border: '1px solid #E2E9F1',
    backgroundColor: '#FFFFFF',
    color: '#182341',
    padding: '20px',
    margin: '20px 0',
    textAlign: 'center!important',
  },
  statusLogo: {
    height: '48px',
    width: '48px',
    backgroundColor: '#E6EAF2',
    marginBottom: '10px',
    marginLeft: 'auto!important',
    marginRight: 'auto!important',
    alignItems: 'center!important',
    justifyContent: 'center!important',
    display: 'flex!important',
    borderRadius: '50%!important',
  },
  statusText: {
    fontFamily: 'PT Serif Caption!important',
    fontSize: '18px!important',
    fontWeight: 'bold',
    lineHeight: '24px',
    marginBottom: '.5rem!important',
  },
  statusInfoLine: {
    fontFamily: 'PT Root UI',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    marginBottom: '10px',
    alignItems: 'center!important',
    justifyContent: 'center!important',
    display: 'flex!important',
  },
  mr3: {
    marginRight: '0.5em',
    marginBottom: '-3px',
  },
  redBckgrnd: {
    backgroundColor: '#FFDADA',
  },
  repeatRequestBttn: {
    border: '1px solid #E2E9F1',
    borderRadius: '0px',
    height: '40px',
    color: '#182341',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    padding: '11px 20px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1168A7',
      color: 'white',
    },
  },
}));

const OrderInterview = ({ createdAt }) => {
  const classes = useStyles();

  return (
    <Box className={classes.statusContent}>
      <Box className={classes.statusLogo}>
        <ClockCompIcon />
      </Box>
      <Box className={classes.statusText}>
        Your request has been successfully sent.
      </Box>
      <Box className={classes.statusText}>
        The interview will be available within 24 hrs.
      </Box>
      <Box component="div" className={classes.statusInfoLine}>
        <Box className={classes.mr3}>
          <Clock2Icon />
        </Box>
        {formatDate(createdAt)}
      </Box>
    </Box>
  );
};

OrderInterview.propTypes = {
  createdAt: PropTypes.string.isRequired,
};

export default OrderInterview;
