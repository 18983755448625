import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Fade from '@material-ui/core/Fade';
import { useMutation, useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { useSnackbar } from 'notistack';
import {
  ALL_FOLLOWING_KEYWORDS_QUERY,
  DELETE_KEYWORD_FOLLOWING,
  UPDATE_KEYWORD_MUTATION,
} from '../../../../../../../../../../queries/interview/following';
import FollowingItem from '../FollowingItem';
import ItemsSkeleton from '../../../ItemsSkeleton';
import FollowingPlaceholder from '../FollowingPlaceholder';
import FollowingItemsSection from '../FollowingItemsSection';
import KeywordSelect from '../KeywordSelect';
import useMe from '../../../../../../../../../../hooks/useMe';

const Accordion = withStyles(() => ({
  root: {
    borderBottom: '1px solid #EAEEF4',
    boxShadow: 'none',
    '&:last-child': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles(() => ({
  root: {
    minHeight: '30px',
    padding: '0 20px',
    '&$expanded': {
      minHeight: '30px',
    },
  },
  content: {
    margin: '6px 0',
    '&$expanded': {
      margin: '6px 0',
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    padding: '5px 20px',
    overflow: 'auto',
    height: 'calc(100vh - 180px)',
    '&.showTrialBanner': {
      height: 'calc(100vh - 217px)',
    },
  },
}))(MuiAccordionDetails);

const FollowingKeywords = ({
  filterDataKeywords, handleFilterSelection, handleExpandedAccordion, expandedAccordion,
  ariaControls, accordionId, sectionTitle, trackingClass, showTrialBanner,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { me } = useMe();

  const {
    data: {
      allKeywordWatchList = [],
    } = {},
    loading,
    updateQuery,
  } = useQuery(ALL_FOLLOWING_KEYWORDS_QUERY);

  const [deleteKeyword] = useMutation(DELETE_KEYWORD_FOLLOWING, {
    refetchQueries: [{ query: ALL_FOLLOWING_KEYWORDS_QUERY }],
  });

  const [updateKeyword] = useMutation(UPDATE_KEYWORD_MUTATION, {
    refetchQueries: [{ query: ALL_FOLLOWING_KEYWORDS_QUERY }],
  });

  const handleKeywordDelete = (followingId) => {
    deleteKeyword({ variables: { followingId } });
  };

  const handleKeywordUpdate = (followingId, keywordId) => (params) => {
    updateKeyword({
      variables: {
        id: followingId,
        keyword: keywordId,
        ...params,
      },
    }).then(({ data: { updateKeywordMutation = {} } = {} }) => {
      if (!isEmpty(updateKeywordMutation.errors)) {
        enqueueSnackbar(
          updateKeywordMutation.errors.map((err) => (
            `${err.field}: ${err.messages.join(', ')}`
          )),
          { variant: 'error', autoHideDuration: 4000 },
        );
      } else {
        enqueueSnackbar(
          'Saved.',
          { variant: 'success', autoHideDuration: 4000 },
        );
      }
    });
  };
  const isKeywordActive = (id) => {
    if (filterDataKeywords) {
      const active = filterDataKeywords.filter((el) => el.id === id);
      return active.length > 0;
    }
    return false;
  };

  const handleSelection = (item) => () => {
    handleFilterSelection({
      keywords: [{
        id: item.keyword.id,
        title: item.keyword.title,
        uuid: item.keyword.uuid,
      }],
    });
    updateQuery((previousQueryResult) => {
      const result = previousQueryResult.allKeywordWatchList.map((el) => {
        if (el.id === item.id) {
          return { ...el, notSeenInterviewsCount: 0 };
        }
        return el;
      });
      return {
        allKeywordWatchList: result,
      };
    });
  };

  return (
    <Accordion expanded={expandedAccordion} onChange={handleExpandedAccordion}>
      <AccordionSummary aria-controls={ariaControls} id={accordionId}>
        <FollowingItemsSection
          sectionTitle={sectionTitle}
          expanded={expandedAccordion}
          totalCount={allKeywordWatchList?.length}
          unreadCount={me.keywordWatchlistUnreadCount}
        />
      </AccordionSummary>
      <AccordionDetails className={showTrialBanner && 'showTrialBanner'}>
        <Box className={trackingClass} width="100%">
          <KeywordSelect />
          <Fade in={loading}>
            <Box>
              {loading && <ItemsSkeleton />}
            </Box>
          </Fade>
          <Fade in={!loading}>
            <Box>
              {allKeywordWatchList.length > 0
                ? allKeywordWatchList.map((item) => (
                  <FollowingItem
                    key={item.id}
                    data-xlink-keyword-id={item.keyword.uuid}
                    itemName={item.keyword.title}
                    isItemActive={isKeywordActive(item.keyword.id)}
                    unreadCount={item.notSeenInterviewsCount}
                    onDelete={() => handleKeywordDelete(item.id)}
                    onUpdate={handleKeywordUpdate(item.id, item.keyword.id)}
                    onClick={handleSelection(item)}
                    initials={{
                      notify: item.notify,
                      emailAlerts: item.emailAlerts,
                      onlyPrimaryTickerAlerts: item.onlyPrimaryTickerAlerts,
                      hidePrimaryTickerAlerts: true,
                    }}
                  />
                ))
                : (
                  <FollowingPlaceholder>
                    Add keywords to your watchlist to know first about new interviews
                  </FollowingPlaceholder>
                )}
            </Box>
          </Fade>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

FollowingKeywords.propTypes = {
  filterDataKeywords: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleFilterSelection: PropTypes.func.isRequired,
  handleExpandedAccordion: PropTypes.func.isRequired,
  expandedAccordion: PropTypes.bool.isRequired,
  ariaControls: PropTypes.string.isRequired,
  accordionId: PropTypes.string.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  trackingClass: PropTypes.string.isRequired,
  showTrialBanner: PropTypes.bool.isRequired,
};

export default FollowingKeywords;
