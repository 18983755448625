import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import { ReactComponent as SelectIcon } from '../../../../../../assets/icons/interviewActions/selectIcon.svg';
import {
  AskQuestionsExpertBtn,
  MarkInterviewUnread,
  InviteGuests,
} from '../../../../../../components/InterviewPreviewComponents';

const useStyles = makeStyles((theme) => ({
  actionsBtn: {
    height: '30px',
    padding: '5px 10px',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    border: '1px solid',
    borderRadius: '5px',
    borderColor: theme.palette.info.main,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    textTransform: 'capitalize',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      '& svg path': {
        stroke: theme.palette.common.white,
      },
    },
    '&:active': {
      borderColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.dark,
    },
  },
  menuItem: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    padding: '0px',
    color: theme.palette.text.primary,
    [theme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
    },
    '&:active': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
      [theme.breakpoints.up('md')]: {
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
          color: theme.palette.common.white,
        },
      },
    },
    '&.disabled': {
      backgroundColor: 'transparent',
      cursor: 'default',
    },
  },
  menuItemClick: {
    display: 'flex',
    width: '100%',
    padding: '6px 16px',
  },
  askQuestions: {
    fontWeight: 'bold',
    lineHeight: '18px',
    textTransform: 'inherit',
    justifyContent: 'flex-start',
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  inviteGuests: {
    fontWeight: 'bold',
    lineHeight: '18px',
    textTransform: 'inherit',
    justifyContent: 'flex-start',
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
}));

const InterviewActionsMenu = ({
  interviewPk, interviewId, isMarkUnreadAllowed,
  isInviteGuestsAllowed, companies, expert, perspective,
}) => {
  const [actionsMenu, setActionsMenu] = React.useState(null);
  const classes = useStyles();

  const handleOpenActionsMenu = (event) => {
    setActionsMenu(event.currentTarget);
  };
  const handleCloseActionsMenu = () => {
    setActionsMenu(null);
  };
  return (
    <Box display="flex">
      <Button
        className={classes.actionsBtn}
        aria-controls="actionsMenu"
        aria-haspopup="true"
        onClick={handleOpenActionsMenu}
        disableRipple
        endIcon={<SelectIcon />}
      >
        Actions
      </Button>
      <Menu
        id="actionsMenu"
        anchorEl={actionsMenu}
        keepMounted
        open={Boolean(actionsMenu)}
        onClose={handleCloseActionsMenu}
      >
        {!isEmpty(expert)
        && (
          <MenuItem
            className={classes.menuItem}
            onClick={handleCloseActionsMenu}
            disableRipple
          >
            <AskQuestionsExpertBtn
              companies={companies}
              expert={expert}
              perspective={perspective}
              className={clsx(
                classes.menuItemClick,
                classes.askQuestions,
              )}
            />
          </MenuItem>
        )}

        {isMarkUnreadAllowed
        && (
          <MenuItem
            className={classes.menuItem}
            onClick={handleCloseActionsMenu}
            disableRipple
          >
            <MarkInterviewUnread
              interviewPk={interviewPk}
              interviewId={interviewId}
              className={classes.menuItemClick}
            />
          </MenuItem>
        )}

        {isInviteGuestsAllowed
        && (
          <MenuItem
            className={classes.menuItem}
            onClick={handleCloseActionsMenu}
            disableRipple
          >
            <InviteGuests
              className={clsx(
                classes.menuItemClick,
                classes.inviteGuests,
              )}
              interviewId={interviewId}
            />
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};

InterviewActionsMenu.defaultProps = {
  isMarkUnreadAllowed: false,
  isInviteGuestsAllowed: false,
  perspective: '',
};

InterviewActionsMenu.propTypes = {
  interviewPk: PropTypes.string.isRequired,
  interviewId: PropTypes.string.isRequired,
  isMarkUnreadAllowed: PropTypes.bool,
  isInviteGuestsAllowed: PropTypes.bool,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  expert: PropTypes.arrayOf(PropTypes.object).isRequired,
  perspective: PropTypes.string,
};

export default InterviewActionsMenu;
