import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import { useMutation } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import isEqual from 'lodash/isEqual';
import { connect, useSelector, useDispatch } from 'react-redux';
import ReadingListItem from './components/ReadingListItem';
import {
  SAVE_USER_SESSION_FILTERS,
} from '../../../../../../../../queries/interview/interview';
import { FILTER_INIT_VALUES } from '../../../../../../../../constants/filterInitValues';
import ItemsSkeleton from '../ItemsSkeleton';
import CreateReadingList from './components/CreateReadingList';
import { cleanFilterData, writeFilterData } from '../../../../../../../../actions/filterActions';
import useReadingListTitle from '../../../../../../../../hooks/useReadingListTitle';
import { hideSideBar } from '../../../../../../../../actions/sidebarActions';
import { setLastRL } from '../../../../../../../../actions/readingListActions';
import useReadingList from '../../../../../../../../hooks/useReadingList';

const useStyles = makeStyles((theme) => ({
  readingContainer: {
    height: '100%',
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '25px',
  },
  headingPosition: {
    position: 'relative',
  },
  noLists: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    lineHeight: '18px',
  },
}));

const ReadingList = ({ filterData, handleWriteFilterData }) => {
  const classes = useStyles();
  const lastRL = useSelector((state) => state.readingListState.setLastRL, isEqual);
  const matchInterview = useRouteMatch({ path: '/:interviewId', exact: true });
  const matchHome = useRouteMatch({ path: '/', exact: true });
  const isInterviewPage = !isEmpty(matchInterview) || !isEmpty(matchHome);
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: false,
  });
  const { allReadingList, loading } = useReadingList();
  const [saveUserSessionFiltersCache] = useMutation(SAVE_USER_SESSION_FILTERS);

  const handleFilter = (key, value) => {
    if (!isInterviewPage) {
      history.push('/');
    }
    handleWriteFilterData({ [key]: value });
    if (isMobile) {
      dispatch(hideSideBar());
    }
  };

  // automatically display first reading list
  useEffect(() => {
    if (filterData.readingLists.length === 0 && !loading && !lastRL.id
      && allReadingList.totalCount > 0) {
      handleFilter('readingLists', [{ id: allReadingList.results[0].id, title: allReadingList.results[0].title }]);
    }
  }, [allReadingList, filterData.readingLists]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filterData.readingLists.length === 0 && lastRL.id) {
      handleFilter('readingLists', [lastRL]);
    }
    // TODO clear filters
    return () => {
      saveUserSessionFiltersCache({
        variables: {
          filterData: JSON.stringify(FILTER_INIT_VALUES),
        },
      });
      dispatch(cleanFilterData());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const readingListTitle = useReadingListTitle();

  return (
    <Box>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="10px"
          className={classes.headingPosition}
        >
          <Box className={classes.title}>
            {readingListTitle}
          </Box>
        </Box>
      </Box>
      <Box className={classes.readingContainer}>
        <CreateReadingList placeholder={`New ${readingListTitle} Name`} />
        <Fade in={allReadingList.totalCount > 0 && !loading}>
          <Box>
            {(allReadingList.totalCount > 0 && !loading)
              && (
                <ul>
                  {allReadingList.results.map((readingList) => (
                    <li style={{ listStyle: 'none' }}>
                      <Box key={readingList.id}>
                        <ReadingListItem
                          itemName={readingList.title}
                          itemId={readingList.id}
                          unreadCount={readingList.numOfNewInterviews}
                          onFilterBy={() => {
                            handleFilter('readingLists', [{ id: readingList.id, title: readingList.title }]);
                            dispatch(setLastRL(readingList.id, readingList.title));
                          }}
                        />
                      </Box>
                    </li>
                  ))}
                </ul>
              )}
          </Box>
        </Fade>

        {!loading
          && (
            <Fade in={allReadingList.totalCount === 0 && !loading}>
              <Box className={classes.noLists}>{`No ${readingListTitle}s`}</Box>
            </Fade>
          )}

        {loading
          && (
          <Fade in={loading}>
            <ItemsSkeleton />
          </Fade>
          )}
      </Box>
    </Box>
  );
};

ReadingList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  filterData: PropTypes.object.isRequired,
  handleWriteFilterData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { filterState } = state;
  return {
    filterData: filterState.filterData,
  };
};

const areEqual = (prevProps, nextProps) => isEqual(prevProps.filterData, nextProps.filterData);

export default React.memo(
  connect(
    mapStateToProps,
    {
      handleWriteFilterData: writeFilterData,
    },
  )(ReadingList),
  areEqual,
);
