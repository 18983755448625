import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/styles';
import isEqual from 'lodash/isEqual';
import clsx from 'clsx';
import { formatDate } from '../../../../../../../../../helpers/dateHelpers';
import { ReactComponent as EyePrimaryIcon } from '../../../../../../../../../assets/icons/eyePrimaryIcon.svg';
import { ReactComponent as EyeIcon } from '../../../../../../../../../assets/icons/eyeIcon.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
    marginBottom: '5px',
    borderRadius: '5px',
    boxShadow: 'none',
    border: '1px solid',
    padding: '1px',
    borderColor: theme.palette.info.main,
    '&:hover': {
      borderColor: theme.palette.info.dark,
      [theme.breakpoints.up('md')]: {
        '&> button': {
          display: 'inline-flex',
        },
        '&> span': {
          display: 'none',
        },
      },
    },
    '&.active': {
      border: '2px solid',
      borderColor: theme.palette.primary.main,
      padding: '0',
      '&:hover': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  headingBox: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    width: '100%',
    maxWidth: '145px',
    padding: '4px 4px 4px 6px',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.text.primary,
  },
  btn: {
    padding: '4px',
    minWidth: 'auto',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
  callDate: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    padding: '4px',
    whiteSpace: 'nowrap',
  },
}));

const CompanyItem = ({
  id, description, watchlistId, isItemActive, uuid, lastCallDate,
  handleCompanyClick, handleRemoveFromWatchlist, handleAddToWatchlist,
}) => {
  const classes = useStyles();
  return (
    <Box
      className={clsx(
        classes.root,
        isItemActive && 'active',
      )}
      key={id}
      data-xlink-company-id={uuid}
    >
      <Box
        className={classes.headingBox}
        onClick={handleCompanyClick}
      >
        {description}
      </Box>
      {watchlistId
        ? (
          <>
            <Box component="span" className={classes.callDate}>
              {formatDate(lastCallDate)}
            </Box>
            <Tooltip title="Remove from Watchlist">
              <Button
                onClick={handleRemoveFromWatchlist}
                disableRipple
                className={classes.btn}
              >
                <EyePrimaryIcon />
              </Button>
            </Tooltip>
          </>
        )
        : (
          <>
            <Box component="span" className={classes.callDate}>
              {formatDate(lastCallDate)}
            </Box>
            <Tooltip title="Add to Watchlist">
              <Button
                onClick={handleAddToWatchlist}
                disableRipple
                className={classes.btn}
              >
                <EyeIcon />
              </Button>
            </Tooltip>
          </>
        )}
    </Box>
  );
};

CompanyItem.defaultProps = {
  watchlistId: null,
  lastCallDate: '',
};

CompanyItem.propTypes = {
  id: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  lastCallDate: PropTypes.string,
  description: PropTypes.string.isRequired,
  watchlistId: PropTypes.number,
  isItemActive: PropTypes.bool.isRequired,
  handleCompanyClick: PropTypes.func.isRequired,
  handleRemoveFromWatchlist: PropTypes.func.isRequired,
  handleAddToWatchlist: PropTypes.func.isRequired,
};

const areEqual = (prevProps, nextProps) => {
  const prevObj = {
    uuid: prevProps.uuid,
    watchlistId: prevProps.watchlistId,
    isItemActive: prevProps.isItemActive,
  };
  const nextObj = {
    uuid: nextProps.uuid,
    watchlistId: nextProps.watchlistId,
    isItemActive: nextProps.isItemActive,
  };
  return isEqual(prevObj, nextObj);
};

export default React.memo(CompanyItem, areEqual);
