import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CONTENT_REQUEST_MUTATION = gql`
  mutation contentRequest($input: ContentRequestMutationInput!){
    contentRequest(input: $input){
      errors {
        field
        messages
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const ASK_QUESTIONS_MUTATION = gql`
  mutation askQuestions($input: AskQuestionsMutationInput!){
    askQuestions(input: $input){
      errors {
        field
        messages
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const CONTACT_SALES_MUTATION = gql`
  mutation contactSales($input: ContactSalesMutationInput!){
    contactSales(input: $input){
      errors {
        field
        messages
      }
    }
  }
`;
